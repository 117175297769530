import { useEffect, useState } from 'react';

import CustomCellComponent from 'components/Management/Account/CustomCellComponent';
import { SelectBox } from 'components/common/Filter/SelectBox';
import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';
import { Paging } from 'components/common/Table/Paging';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import useGetApi from 'hooks/apis/useGetApi';

import { ReactComponent as ManagementIcon } from 'assets/svg/ManagementIcon.svg';

import plusCircleIcon from '../../assets/svg/PlusCircleBlack.svg';
import resetIcon from '../../assets/svg/Reset.svg';
import searchIcon from '../../assets/svg/Search.svg';

const columns: LabeledColumnProps[] = [
  {
    key: 'ID',
    label: 'ID',
    flexGrow: 1,
  },
  {
    key: '연락처',
    label: '연락처',
    flexGrow: 2,
  },
  {
    key: '이메일',
    label: '이메일',
    flexGrow: 2.5,
  },
  {
    key: '회원권한',
    label: '회원권한',
    flexGrow: 2,
  },
  {
    key: '수정',
    label: '수정',
    flexGrow: 0.7,
    isCustomCell: true,
  },
  {
    key: '삭제',
    label: '삭제',
    flexGrow: 0.7,
    isCustomCell: true,
  },
];

const Account = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [cp, setCp] = useState<string>('');
  const [input, setInput] = useState<string>('');

  const limit = 10;

  const { data, refetch, isLoading } = useGetApi(
    'account',
    `/member/list?${cp !== '' ? 'cp=' + cp + '&' : ''}page=${currentPage}&pageSize=${limit}`,
  );

  const handleData = () => {
    if (data) {
      const mappedData = data?.content.map((item: any) => ({
        ID: item.memberId,
        연락처: item.phoneNumber,
        이메일: item.email,
        회원권한: item.auth,
      }));

      return mappedData;
    } else {
      return [];
    }
  };

  useEffect(() => {
    refetch().then(() => {
      if (data) {
        setTotalElements(data.totalElements);
      }
      setLoading(isLoading);
    });
  }, [currentPage, data]);

  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="관리센터"
          path2="계정 현황"
          Icon={ManagementIcon}
          title="계정 현황"
        />
      </div>
      <div className="flex flex-col gap-[50px]">
        <div className="flex flex-col w-full items-center justify-center gap-[16px] self-stretch p-[30px] rounded-[14px] border border-[#EAEAEA] bg-white shadow-[0_0_24px_0_rgba(0,0,0,0.10)]">
          <div className="flex items-center gap-[6px] self-stretch">
            <img src={searchIcon} alt="Search Icon" className="w-[24px] h-[24px]" />
            <p className="text-[#222] font-pretendard text-[20px] font-bold leading-[1.5]">검색</p>
          </div>
          <div className="flex items-center w-full gap-[30px] self-stretch">
            <div className="flex items-center flex-grow gap-[40px]">
              <div className="flex items-center gap-[14px]">
                <p className="text-[#222] font-pretendard text-[17px] font-semibold leading-normal w-[40px]">
                  CP사
                </p>
                <div className="flex items-center h-[44px] w-full pl-[14px] py-[16px] justify-between flex-[1_0_0] rounded-[4px] border border-[#EAEAEA] bg-white">
                  <SelectBox
                    title={cp === '' ? '플랫폼 선택' : cp}
                    options={['쇼박스', 'SNS', '커뮤니티', 'NEWS']}
                    handleOptionChange={(target) => {
                      setCp(target);
                    }}
                    value={cp}
                  />
                </div>
              </div>
              <div className="flex items-center gap-[14px] pl-30 border-l-1 border-[#EAEAEA]">
                <p className="text-[#222] font-pretendard text-[17px] font-semibold leading-normal w-[45px]">
                  검색어
                </p>
                <div className="flex items-center h-[44px] w-full px-[14px] py-[16px] justify-between flex-[1_0_0] rounded-[4px] border border-[#EAEAEA] bg-white">
                  <input
                    className="flex w-full min-w-[358px] h-44 px-14 py-16 box-border border-1 rounded-4 border-[#EAEAEA] focus:outline-[#FF9016]"
                    placeholder="검색어 입력"
                    type="text"
                    onChange={(e) => {
                      setInput(e.target.value);
                    }}
                    value={input}
                  />
                </div>
              </div>

              <div className="flex items-start gap-[10px]">
                <button
                  className="flex w-[150px] p-[14px] justify-center items-center gap-[4px] rounded-[4px] bg-[#222] text-white font-pretendard text-[15px] font-bold uppercase"
                  onClick={() => {
                    setCp('');
                    setInput('');
                    refetch();
                  }}
                >
                  <img src={resetIcon} alt="Reset Icon" className="w-[16px] h-[16px]" />
                  초기화
                </button>

                <button
                  className="flex w-[150px] p-[14px] justify-center items-center gap-[10px] rounded-[4px] bg-[#FF9016] text-white font-pretendard text-[15px] font-bold uppercase"
                  onClick={() => {
                    refetch();
                  }}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center gap-[20px] self-stretch">
          <div className="flex items-center self-stretch justify-between">
            <div className="flex items-center gap-[10px]">
              <p className="text-[#222] font-pretendard text-[20px] font-bold leading-[1.5]">
                전체 데이터
              </p>
              <p className="text-[#797372] font-poppins text-[20px] font-bold leading-normal">
                {totalElements}건
              </p>
            </div>
            <button className="hidden h-[44px] px-[14px] py-[9px] justify-center items-center gap-[10px] rounded-[4px] border-1 border-[#222] bg-[#FFF]">
              <img src={plusCircleIcon} alt="Reset Icon" className="w-[16px] h-[16px]" />
              계정 등록
            </button>
          </div>
          <div className="w-full">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <DataTable columns={columns} datas={handleData()} rowKey="ID">
                <CustomCellComponent
                  mode="edit"
                  key="수정"
                  handleClick={() => {
                    if (confirm('수정하시겠습니까?') === true) {
                      alert('수정되었습니다.');
                    }
                  }}
                />
                <CustomCellComponent
                  mode="delete"
                  key="삭제"
                  handleClick={() => {
                    if (confirm('삭제하시겠습니까?') === true) {
                      alert('삭제되었습니다.');
                    }
                  }}
                />
              </DataTable>
            )}
          </div>
          <div>
            <Paging
              total={totalElements}
              limit={limit}
              activePage={currentPage}
              onChangePage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
