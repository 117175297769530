import { Dispatch, SetStateAction } from 'react';

import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';

import { useViralQuoContext } from 'contexts/viral/quotation/ViralQuoContext';

interface Prop {
  handleChange: Dispatch<SetStateAction<{ [key: string]: number }>>;
}

const RangeSlider = ({ handleChange }: Prop) => {
  const MIN_PRICE = 1;
  const MAX_PRICE = 100;
  const PRICE_STEP = 1;
  const sx = {
    color: '#FF7B31',
    marginLeft: '12px',
    width: '12.07vw',
    '& .MuiSlider-thumb': {
      color: 'white',
      boxShadow: '0px 0px 8px rgba(255, 123, 49, 0.5)',
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#E0E0E0',
      width: '12.07vw',
      height: '6px',
    },
    '& .MuiSlider-track': {
      height: '6px',
    },
  };

  const { rangeValue, setRangeValue } = useViralQuoContext();
  const { fromPrice, toPrice } = rangeValue;
  const changePriceRange = (e: Event, v: number | number[]) => {
    if (Array.isArray(v)) {
      v[0] && v[1] && handleChange({ ...rangeValue, fromPrice: v[0], toPrice: v[1] });
    }
  };

  return (
    <div className="mx-28">
      <div className="flex items-center justify-end mb-17 mt-[-32px]">
        <p className="text-main text-14">
          {fromPrice} ~ {toPrice}
          {toPrice !== MAX_PRICE ? <span>만원</span> : <span>만원 이상</span>}
        </p>
      </div>
      <Stack>
        <Slider
          onChange={changePriceRange}
          value={[fromPrice, toPrice]}
          step={PRICE_STEP}
          min={MIN_PRICE}
          max={MAX_PRICE}
          valueLabelDisplay="auto"
          sx={sx}
        />
      </Stack>

      <div className="flex justify-between mb-8 mt-18 text-14 text-dark-gray">
        <p>최소</p>
        <p>50만원</p>
        <p>최대</p>
      </div>
    </div>
  );
};

export default RangeSlider;
