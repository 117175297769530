import { DateMediaList, ReformedDateMediaList } from 'types/Medium/facebook';

export const reformDataForChart = (dateFacebookList: DateMediaList[]) => {
  let newDataList: ReformedDateMediaList[] = [];

  dateFacebookList?.map((dateFacebook) => {
    newDataList.push({
      date: dateFacebook.createdAt,
      viewCount: dateFacebook.viewCount && dateFacebook.viewCount,
      commentCount: dateFacebook.commentCount && dateFacebook.commentCount,
      likeCount: dateFacebook.likeCount && dateFacebook.likeCount,
    });
  });

  return newDataList;
};
