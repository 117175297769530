interface CommentType {
  type: 'positive' | 'neutral' | 'negative' | '';
}

const getCommentHtml = (type: string) => {
  if (type === '') {
    return (
      <div className="w-[418px] h-[104px] rounded-12 flex flex-col items-center bg-date-select justify-center">
        <div className="leading-28">
          <span>데이터가 없습니다.</span>
        </div>
      </div>
    );
  } else if (type === 'positive') {
    return (
      <div className="w-[418px] h-[104px] rounded-12 flex flex-col items-center bg-date-select justify-center">
        <div className="leading-28">
          <span className="text-positive">긍정적인 반응</span>
          <span>이 높은 수치를 보여줍니다. </span>
        </div>
        <div className="leading-28">
          <span className="text-positive">긍정적인 반응</span>
          <span>이 유지되도록 노력하시면 될 것 같습니다.</span>
        </div>
      </div>
    );
  } else if (type === 'neutral') {
    return (
      <div className="w-[418px] h-[104px] rounded-12 flex flex-col items-center bg-date-select justify-center">
        <div className="leading-28">
          <span className="text-main">중립적인 반응</span>
          <span>이 높은 수치를 보여줍니다. </span>
          <span className="text-positive">긍정적 수치가</span>
        </div>
        <div className="leading-28">
          <span>높아지도록 홍보 또는 바이럴 활동이 필요해 보입니다.</span>
        </div>
      </div>
    );
  } else if (type === 'negative') {
    return (
      <div className="w-[418px] h-[104px] rounded-12 flex flex-col items-center bg-date-select justify-center">
        <div className="leading-28">
          <span className="text-alert">부정적인 반응</span>
          <span>이 높은 수치를 보여줍니다. 활발한 홍보와</span>
        </div>
        <div className="leading-28">
          <span>광고로 </span>
          <span className="text-positive">긍정 수치</span>
          <span>를 높일 방법을 찾아야합니다.</span>
        </div>
      </div>
    );
  }
  return;
};

export const Comment = ({ type }: CommentType) => {
  return <>{getCommentHtml(type)}</>;
};
