import { ChangeEvent } from 'react';

import { ModalListType } from 'types/Management/mangementType';

import CalenderIcon from 'assets/svg/Calendar.svg';

interface Prop {
  data: ModalListType;
}

const CalendarInput = ({ data }: Prop) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    data.setState(e.target.value);
  };

  return (
    <div className="flex flex-col gap-16">
      <p className="text-18">{data.title}</p>
      <div className="flex gap-12">
        <div className="flex items-center justify-center w-[38px] h-[38px] rounded-12 bg-date-select">
          <img src={CalenderIcon} alt="" />
        </div>
        <input
          readOnly={data.readonly ? true : false}
          value={data.value && data.value}
          onChange={(e) => handleChange(e)}
          className="w-full px-12 text-center outline-none bg-date-select h-38 rounded-8 placeholder:text-graph-gray placeholder:text-center"
          placeholder={data.placeholder}
          autoSave="on"
        />
      </div>
    </div>
  );
};

export default CalendarInput;
