import { CountPercentage } from 'components/common/Report/CountPercentage';
import { addComma } from 'components/common/addAndRemoveComma';

interface FieldCountListProps {
  name: string;
  count: number;
  percentage: string;
}

export const FieldCountList = ({ name, count, percentage }: FieldCountListProps) => {
  return (
    <div className="flex justify-between w-[277px]">
      <span className="w-[115px] leading-28">{name}</span>
      <div className="flex justify-between basis-[1px] grow items-center">
        <span>{addComma(count)}</span>
        <CountPercentage count={percentage} />
      </div>
    </div>
  );
};
