import { FieldBuzzData, FieldChartData } from 'types/Buzz/BuzzType';

export const useParseCommentPercetage = (list: FieldBuzzData | FieldChartData) => {
  const totalCounts =
    list.comments &&
    list.comments.positiveCounts + list.comments.neutralityCounts + list.comments.negativeCounts;

  const result = {
    positive:
      list.comments && totalCounts && list.comments.positiveCounts
        ? Number(Math.round((list.comments.positiveCounts / totalCounts) * 100).toFixed(1))
        : 0,
    neutral:
      list.comments && totalCounts && list.comments.neutralityCounts
        ? Number(Math.round((list.comments.neutralityCounts / totalCounts) * 100).toFixed(1))
        : 0,
    negative:
      list.comments && totalCounts && list.comments.negativeCounts
        ? Number(Math.round((list.comments.negativeCounts / totalCounts) * 100).toFixed(1))
        : 0,
  };

  return result;
};
