import { DateRange } from 'rsuite/esm/DateRangePicker';

import { convertMonth } from 'hooks/useConvertDate';

interface Props {
  start: DateRange | Date | null;
  end: DateRange | Date | null;
}

export const useConvertDateWithoutHours = ({ start, end }: Props) => {
  const startDate = `${String(start).split(' ')[3]}-${convertMonth(
    String(start).split(' ')[1] || '',
  )}-${String(start).split(' ')[2]}`;

  const endDate = `${String(end).split(' ')[3]}-${convertMonth(String(end).split(' ')[1] || '')}-${
    String(end).split(' ')[2]
  }`;

  return { startDate: startDate, endDate: endDate };
};

export default useConvertDateWithoutHours;
