import { MovieProps } from 'types/Subscription/KMDbMovies';

import defaultPoster from 'assets/image/DefaultPoster.png';

interface SearchResultsProps {
  searchContent: string;
  searchResults: MovieProps[];
}

const SearchResults = ({ searchContent, searchResults }: SearchResultsProps) => {
  return (
    <div className="mt-20">
      <div className="flex font-bold text-20">
        <h2 className="">"{searchContent}"에 대한 검색 결과&nbsp;</h2>
        <p className="text-[#777]">{searchResults.length}건</p>
      </div>

      <div className="p-40 bg-white mt-34 rounded-14 shadow-box">
        <div className="grid grid-cols-6 gap-44">
          {searchResults.map((movie, index) => (
            <div key={index} className="relative">
              <img
                src={movie.posters || defaultPoster}
                className="w-full rounded-10"
                alt={movie.title}
              />
              <div className="absolute bottom-0 text-center bg-black/60 w-full p-13 backdrop-blur-[5px] font-bold rounded-b-lg leading-20">
                <p className="overflow-hidden text-white text-ellipsis whitespace-nowrap text-17">
                  {movie.title}
                </p>
                <p className="mt-1 text-white text-13">
                  개봉일<span className="font-normal">&nbsp;{movie.repRlsDate}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
