import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import Checkbox from 'components/common/Filter/Checkbox';

interface Prop {
  filter: boolean;
  placeholder: string;
  onChange: (option: string) => void;
  setCategory?: Dispatch<SetStateAction<string>>;
}

export const SearchInput = ({ filter, placeholder, onChange, setCategory }: Prop) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target && e.target.value);
  };

  return (
    <div className="flex flex-col items-start">
      {filter && (
        <div className="mb-9 ml-[-27px]">
          {setCategory && <Checkbox options={['제목', '내용']} handleOptionChange={setCategory} />}
        </div>
      )}

      <input
        placeholder={placeholder}
        className="w-[13.2vw] h-38 border-1 border-light-gray2 rounded-8  pl-12 placeholder:text-16 placeholder:text-light-gray2"
        onChange={(e) => handleChange(e)}
      />
    </div>
  );
};

export default SearchInput;
