import { FieldBuzzData } from 'types/Buzz/BuzzType';

export const useAddFieldTotalCount = (
  key: string,
  entries: [string, number][],
  FieldBuzzDatas: FieldBuzzData[],
) => {
  return FieldBuzzDatas.map((data) => {
    if (data.title === key) {
      if (data.totalCount !== 0) {
        entries.map((entry) => {
          if (entry[1] !== 0) {
            data.totalCount && entry.push(((entry[1] / data.totalCount) * 100).toFixed(1));
          } else {
            entry.push('0');
          }
        });
      }
      return { ...data, datas: entries };
    } else {
      return data;
    }
  });
};
