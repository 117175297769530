import { useNavigate } from 'react-router-dom';

import { DashboardCardProps } from 'types/Dashboard/DashboardCardProps';

interface Props {
  data: DashboardCardProps;
  displayGrade?: boolean;
  useOnClick?: boolean;
}

const DashboardCard = ({ data, displayGrade }: Props) => {
  let gradeStyleString =
    data.grade && Number(data.grade) <= 3
      ? ' bg-gradient-to-r from-[#D68F41] to-[#FB6714]'
      : ' bg-[#363636]';

  const navigate = useNavigate();

  return (
    <div
      className="relative justify-center items-center aspect-[292/420] h-full cursor-pointer"
      onClick={() => {
        navigate('/data', { state: { cp: data.cp_id, movie: data?.movieTitle } });
      }}
    >
      <img
        src={
          data?.imgPath?.indexOf('https') !== -1
            ? data?.imgPath
            : 'https://image.web-movieboard.com/movie_poster/' + data?.imgPath
        }
        className="w-full h-full rounded-10"
        draggable={false}
      />
      <div className="flex flex-col absolute justify-center items-center aspect-[292/86] w-full bottom-0 p-20 box-border bg-black/60 backdrop-blur-[5px] font-bold text-white max-h-86">
        <p className="w-full mb-6 overflow-hidden text-center text-20 text-ellipsis whitespace-nowrap">
          {data?.movieTitle}
        </p>
        <span className="flex justify-center w-full gap-6 text-13">
          개봉일
          <span className="font-normal opacity-70">{data?.openDate}</span>
        </span>
      </div>
      {displayGrade && (
        <div
          className={
            'absolute justify-center items-center min-w-60 h-60 top-0 left-0 px-18 py-27 ' +
            gradeStyleString
          }
        >
          <span className="flex items-center justify-center w-full h-full font-bold text-white text-17">
            {data?.grade}위
          </span>
        </div>
      )}
    </div>
  );
};

export default DashboardCard;
