import { ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType;
}

const Cp = ({ data }: Prop) => {
  const { contents } = data;
  const titles = ['배급', 'OSP사', '영화 제목'];

  return (
    <div className="flex items-center w-full gap-12 h-82 bg-light-gray rounded-12 px-26 mobile:bg-white mobile:h-104 mobile:border-b-1 mobile:border-light-gray mobile:rounded-0 mobile:w-[390px] mobile:-ml-24">
      {titles &&
        titles.map((el, idx) => (
          <div key={idx}>
            <div className="flex flex-col gap-4 mobile:hidden">
              <h6 className="text-14 text-dark-gray">{el}</h6>
              <div className="flex items-center w-[308px] h-42 bg-white rounded-8 pl-12">
                {contents && contents[idx]}
              </div>
            </div>
            <div className="hidden w-full h-full mobile:block">
              <div
                className={`flex flex-col items-center h-full gap-24 ${
                  idx === 1 ? 'border-x-1 border-light-gray w-120' : ''
                }`}
              >
                <p className="text-12 text-dark-gray mt-18">{el}</p>
                <p className="font-bold text-center">{contents && contents[idx]}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Cp;
