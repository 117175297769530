interface FieldImageProps {
  style: string;
  src: string | undefined;
}
export const FieldImage = ({ style, src }: FieldImageProps) => {
  return (
    <div className={`flex items-center justify-center rounded-8 ${style}`}>
      <img src={src} />
    </div>
  );
};
