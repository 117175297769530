import React, { Dispatch, SetStateAction, useState } from 'react';

import { ReactComponent as CheckSquare } from 'assets/svg/CheckSquare.svg';

interface Prop {
  options: string[];
  handleOptionChange: Dispatch<SetStateAction<string>>;
  conditionForMovieCompare?: 'empty' | 'fullfill';
}

const Checkbox = ({ options, handleOptionChange, conditionForMovieCompare }: Prop) => {
  const [isClicked, setIsClicked] = useState<string>();

  const handleClick = (clickedItem: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (conditionForMovieCompare && conditionForMovieCompare === 'empty') return;
    handleOptionChange(clickedItem.currentTarget.innerText);
    setIsClicked(clickedItem.currentTarget.innerText);
  };
  const controlAlert = () => {
    if (conditionForMovieCompare && conditionForMovieCompare === 'empty')
      alert('영화를 선택해주세요');
  };

  return (
    <>
      <div className="flex pl-27 mt-[-4px] w-full">
        {options.map((item, idx) => (
          <label
            key={idx}
            onClick={(clickedItem) => handleClick(clickedItem)}
            className="flex items-center cursor-pointer mr-18"
          >
            <CheckSquare
              className={`${
                isClicked !== item ? 'stroke-light-gray2 fill-light-gray2' : 'stroke-logo fill-logo'
              } mr-[0.31vw]`}
            />
            <input className="appearance-none" type="checkbox" onClick={controlAlert} />
            {item}
          </label>
        ))}
      </div>
    </>
  );
};

export default Checkbox;
