import { useLayoutEffect } from 'react';

import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

function NaverChart(props) {
  useLayoutEffect(() => {
    if (props.data) {
      let root = am5.Root.new('chartdiv');

      root.setThemes([am5themes_Animated.new(root)]);

      root.dateFormatter.setAll({
        dateFormat: 'dd',
        dateFields: ['valueX'],
      });

      // Create chart
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          panX: false,
          panY: false,
          paddingLeft: 10,
        }),
      );

      // Create axes
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          startLocation: 0.5,
          endLocation: 0.5,
          baseInterval: {
            timeUnit: 'day',
            count: 1,
          },
          gridIntervals: [{ timeUnit: 'day', count: 1 }],
          dateFormats: 'yyyy-MM-dd HH',
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );

      // Add series
      let series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          connect: false,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'likeCount',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}',
          }),
        }),
      );

      series.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
      });

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      let series2 = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          connect: false,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'netizenScore',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'vertical',
            labelText: '{valueY}',
          }),
        }),
      );

      series2.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
      });

      series2.strokes.template.setAll({
        strokeWidth: 2,
      });

      let series3 = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          connect: false,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'watchedScore',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'vertical',
            labelText: '{valueY}',
          }),
        }),
      );

      series3.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
      });

      series3.strokes.template.setAll({
        strokeWidth: 2,
      });

      // Set up data processor to parse string dates
      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: 'yyyy-MM-dd HH',
        dateFields: ['date'],
      });
      series2.data.processor = am5.DataProcessor.new(root, {
        dateFormat: 'yyyy-MM-dd HH',
        dateFields: ['date'],
      });
      series3.data.processor = am5.DataProcessor.new(root, {
        dateFormat: 'yyyy-MM-dd HH',
        dateFields: ['date'],
      });

      series.data.setAll(props.data);
      series2.data.setAll(props.data);
      series3.data.setAll(props.data);

      series.bullets.push(function () {
        let circle = am5.Circle.new(root, {
          radius: 4,
          fill: root.interfaceColors.get('background'),
          stroke: series.get('fill'),
          strokeWidth: 2,
        });

        return am5.Bullet.new(root, {
          sprite: circle,
        });
      });

      // Add cursor
      let cursor = chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: 'none',
        }),
      );
      cursor.lineY.set('visible', false);

      // Make stuff animate on load
      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }
  }, [props.data]);

  return <div id="chartdiv" style={{ width: '100%', height: '312px' }}></div>;
}
export default NaverChart;
