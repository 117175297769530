import { useQuery } from '@tanstack/react-query';

import { getCookie } from 'hooks/cookie';

import { API_URL } from './apiUrl';

const useGetApi = (key: string, url: string, queryOptions?: Object) => {
  const header = getCookie('loginToken');
  const COMPOUND_URL = `${API_URL}${url}`;

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${header}`,
    },
  };

  const getDatas = async () => {
    try {
      const res = await fetch(COMPOUND_URL, options);
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.message);
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const { refetch, data, isLoading, isError, error } = useQuery({
    queryKey: [key],
    queryFn: getDatas,
    ...queryOptions,
  });

  return { data, isLoading, isError, error, refetch };
};

export default useGetApi;
