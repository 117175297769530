import { Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from 'react';

import LoginInputForm from 'components/common/Login/LoginInputForm';

import usePostApi from 'hooks/apis/usePostApi';
import { setCookie } from 'hooks/cookie';

import { LoginResType } from 'types/Login/login';

import Bg from 'assets/image/LoginBg.png';
import Logo from 'assets/image/Logo.png';
import MockUp from 'assets/image/MockUp.png';

interface Props {
  setForm : Dispatch<SetStateAction<boolean>>
}

const Login = ({ setForm } : Props) => {
  const [userId, setUserId] = useState<string>();
  const [userPw, setUserPw] = useState<string>();
  const { data, isError, error, mutate } = usePostApi('login', '/member/login', {
    id: userId,
    password: userPw,
  });

  const [isMouseOn, setIsMouseOn] = useState<string>('');
  const loginForm = {
    common: {
      state: isMouseOn,
      setState: setIsMouseOn,
    },
    each: [
      {
        type: 'text',
        placeholder: '아이디를 입력해주세요',
        setInput: setUserId,
        label: '아이디',
      },
      {
        type: 'password',
        placeholder: '비밀번호를 입력해주세요',
        setInput: setUserPw,
        label: '비밀번호',
      },
    ],
  };

  const pressEnterKey = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      mutate();
    }
  };

  const getToken = async (res: LoginResType) => {
    const token = res.token;
    const name = res.name;
    const profileImage = res.profileImage;
    if (token) {
      setCookie('loginToken', token, { maxAge: 3600 * 24 });
      setCookie('name', name, { maxAge: 3600 * 24 });
      setCookie('profileImage', profileImage, { maxAge: 3600 * 24 });
      localStorage.setItem('token', token);
      location.href = '/';
    }
    userId && localStorage.setItem('id', userId);
    userPw && localStorage.setItem('pw', userPw);
  };

  useEffect(() => {
    if (data) {
      getToken(data);
    }
  }, [data]);

  useEffect(() => {
    if (isError && error) {
      alert('아이디 또는 비밀번호가 일치하지 않습니다. 다시 시도해주세요');
    }
  }, [isError, error]);

  return (
    <section className="flex w-[100vw] h-[100vh] mobile:justify-center mobile:items-center">
      <div className="flex flex-col items-center justify-center w-1/2">
        <div className="flex justify-center mb-50">
          <img className="w-230" src={Logo} alt="무비보드 로고" />
        </div>
        <div className="flex flex-col justify-center items-center gap-24 text-dark-gray text-22 w-[460px] mobile:max-w-[343px]">
          {loginForm.each.map((el, idx) => (
            <LoginInputForm
              key={idx}
              type={el.type}
              placeholder={el.placeholder}
              label={el.label}
              state={loginForm.common.state}
              setState={loginForm.common.setState}
              setInput={el.setInput}
              keyDown={(e) => pressEnterKey(e)}
            />
          ))}
          <button
            onClick={() => mutate()}
            className="flex items-center justify-center w-full max-w-[460px] mt-24 mb-50 text-white h-50 bg-[#EE8B1D] rounded-4 text-15 mobile:min-w-[342px] mobile:text-14"
          >
            로그인
          </button>
        </div>
        <p className="flex justify-center text-[#6D7683] text-14 font-normal mobile:text-12">
          아직 회원이 아니신가요?
        </p>
        <p 
          className="flex justify-center text-[#6D7683] text-14 mt-10 underline font-bold cursor-pointer mobile:text-12"
          onClick={() => {
            setForm(false);
          }}
        >
          회원가입
        </p>
      </div>
      <div
        className="relative flex items-center justify-center w-1/2 h-full bg-no-repeat bg-cover mobile:hidden"
        style={{ backgroundImage: `url(${Bg})` }}
      >
        <div className="absolute">
          <h1 className="font-bold text-center text-white text-40 mb-30">
            영화의 데이터를 <span className="text-[#FEBA69]">Ai</span>로 담다!
          </h1>
          <img src={MockUp} alt="목업" />
        </div>
      </div>
    </section>
  );
};

export default Login;
