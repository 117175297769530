import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Comparison from 'pages/Comparison/Comparison';
import Data from 'pages/Data/Data';
import Account from 'pages/Management/Account';
import Platform from 'pages/Management/Platform';
import TotalData from 'pages/Management/TotalData';
import Cgv from 'pages/Medium/Cgv';
import Facebook from 'pages/Medium/Facebook';
import Medium from 'pages/Medium/Medium';
import Naver from 'pages/Medium/Naver';
import Report from 'pages/Report/Report';
import ReportDetail from 'pages/Report/ReportDetail';
import Payment from 'pages/Subscription/Payment';
import RegisMovie from 'pages/Subscription/RegisMovie';

import Root from './Root';
import Dashboard from './pages/Dashboard/Dashboard';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: 'Not Found',
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: '/data',
        element: <Data />,
      },
      {
        path: '/comparison',
        element: <Comparison />,
      },
      {
        path: '/medium',
        element: <Medium />,
      },
      {
        path: '/medium/facebook/*',
        element: <Facebook />,
      },
      {
        path: '/medium/cgv/*',
        element: <Cgv />,
      },
      {
        path: '/medium/naver/*',
        element: <Naver />,
      },
      {
        path: '/subscription/regis-movie',
        element: <RegisMovie />,
      },
      {
        path: '/subscription/payment',
        element: <Payment />,
      },
      {
        path: '/report',
        element: <Report />,
      },
      {
        path: '/report/report-detail',
        element: <ReportDetail />,
      },
      {
        path: '/management/platform',
        element: <Platform />,
      },
      {
        path: '/management/total-data',
        element: <TotalData />,
      },
      {
        path: '/management/account',
        element: <Account />,
      }
    ],
  },
]);

function App() {
  return <RouterProvider basename={process.env.PUBLIC_URL} router={router} />;
}

export default App;
