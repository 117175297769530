import { Dispatch, KeyboardEvent, SetStateAction, useEffect } from 'react';

interface Props {
  type: string;
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  placeholder: string;
  setInput: Dispatch<SetStateAction<string>>;
  keyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  label: string;
  isEssential? : boolean;
  value?: string,
  errorMessage?: string,
  isError?: () => boolean
}

const SignInInputForm = ({
    type,
    state,
    setState,
    placeholder,
    setInput,
    keyDown,
    label,
    isEssential,
    value,
    errorMessage,
    isError
}: Props) => {
  const activeString1 = value === "personal" ? ' bg-[#FF8B00] text-white' : ' bg-white text-[#6D7683]';
  const activeString2 = value === "company" ? ' bg-[#FF8B00] text-white' : ' bg-white text-[#6D7683]';

  useEffect(() => {

  }, [value])

  return (
    <div className={ type !== "select" ? "flex flex-col w-full max-w-[460px]" : "flex flex-col w-full max-w-[460px] mt-10 pt-28 border-t-1 border-solid border-[#eaeaea]"}>
      <label className="text-14 text-[#6D7683] mb-10">
        {label}
        {isEssential && <span className='text-[#FF8B00]'> *</span>}
      </label>
      {
        type === "select" ?
        <div className={'flex max-w-[220px] border-1 border-solid border-[#eaeaea] rounded-4 text-14 font-normal overflow-hidden'}>
          <button 
            className={'w-110 h-40' + activeString1}
            onClick={() => {
              setInput('personal');
            }}
          >
            개인회원
          </button>
          <button 
            className={'w-110 h-40 ' + activeString2}
            onClick={() => {
              setInput('company');
            }}
          >
            기업회원
          </button>
        </div>
        :
        <input
          type={type}
          onChange={(target) => setInput(target.currentTarget.value)}
          onFocus={() => setState(type)}
          onBlur={() => setState('')}
          onKeyDown={(e) => keyDown && keyDown(e)}
          placeholder={placeholder}
          className={`text-14 text-[#A4ADBB] focus:outline-[#EE8B1D] h-50 rounded-4 border-1 border-[#EAEAEA] pl-14 font-normal mobile:min-w-[342px] mobile:h-[42px] mobile:text-14 mobile:p-24`}
          autoComplete="off"
        />
      }
      {(isError ? isError() : false) && 
      <span className='mt-5 ml-3 text-[#ff7b00] text-11'>
        {errorMessage}
      </span>
      }
    </div>
  );
};

export default SignInInputForm;