interface Props {
  text: string;
}

const CustomCellComponent = ({ text }: Props) => {
  return (
    <>
      {text === 'POSITIVE' && (
        <div className="flex justify-center items-center w-55 h-30 rounded-4 bg-[rgba(0,193,124,0.10)]">
          <span className="text-[15px] font-semibold text-[#00C17C]">긍정글</span>
        </div>
      )}

      {text === 'NEUTRAL' && (
        <div className="flex justify-center items-center w-55 h-30 rounded-4 bg-[rgba(255,139,0,0.10)]">
          <span className="text-[15px] font-semibold text-[#FF8B00]">중립글</span>
        </div>
      )}

      {text === 'NEGATIVE' && (
        <div className="flex justify-center items-center w-55 h-30 rounded-4 bg-[rgba(255,78,72,0.10)]">
          <span className="text-[15px] font-semibold text-[#FF0000]">부정글</span>
        </div>
      )}

      {text === '-' && <span>-</span>}
    </>
  );
};

export default CustomCellComponent;
