// export interface CustomTableCellProps {
//   text: '긍정글' | '부정글' | '중립글' | '';
// }
export interface CustomTableCellProps {
  text: 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL' | '';
}

const customCellColor = {
  '': '',
  NEGATIVE: 'alert',
  POSITIVE: 'positive',
  NEUTRAL: 'main',
};

const customText = {
  '': '',
  NEGATIVE: '부정글',
  POSITIVE: '긍정글',
  NEUTRAL: '중립글',
};

export const CustomTableCell = ({ text }: CustomTableCellProps) => {
  const bgColor = `bg-${customCellColor[text]}`;
  const textColor = `text-${customCellColor[text]}`;
  const TEXT = customText[text];

  return (
    <div
      key={'manager'}
      className={`flex justify-center items-center text-center mx-auto w-[130px] h-[32px]  bg-opacity-20 rounded-12 top-10 ${bgColor} ${textColor}`}
    >
      {TEXT}
    </div>
  );
};
