import { useEffect, useRef } from 'react';

import * as am5 from '@amcharts/amcharts5';

import makeChart from 'components/common/Report/lineChart';

interface ReportLineChartVer2Props {
  datas: {
    [key: string]: { date: string; value: number }[];
  };
  height?: string;
  movie1?: string;
  movie2?: string;
}

export const ReportLineChartVer2 = ({
  datas,
  height,
  movie1,
  movie2,
}: ReportLineChartVer2Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const root = chartRef.current !== null && am5.Root.new(chartRef.current);
    if (root) {
      makeChart(root, datas, movie1, movie2);
    }

    return () => {
      root !== false && root.dispose();
    };
  }, [datas]);

  return <div ref={chartRef} className={`w-full ${height ? height : 'h-[528px]'} m-10 p-10`}></div>;
};
