import { ResponsiveBar } from '@nivo/bar';

interface BarChartProps {
  data:
    | {
        [key: string]: number | string;
      }[]
    | [];
  keys: string[];
  indexBy: string;
}

const theme = {
  axis: {
    domain: {
      line: {
        stroke: '#CED5E0',
        strokeWidth: 1,
      },
    },
    ticks: {
      text: {
        fontSize: 14,
        fill: '#000000',
        fontWeight: 'bold',
        fontFamily: 'NotoSans',
      },
    },
  },
  grid: {
    line: {
      stroke: '#ced5e079',
      strokeWidth: 1,
      strokeDasharray: '6, 8',
    },
  },
};

export const BarChart = ({ data, keys, indexBy }: BarChartProps) => {
  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 18, right: 18, bottom: 40, left: 40 }}
      padding={0.7}
      axisBottom={{
        tickSize: 0,
        tickPadding: 20,
        tickRotation: 0,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 6,
        tickRotation: 0,
      }}
      enableGridX={true}
      enableLabel={false}
      theme={theme}
      colors={({ id, data }) => String(data[`${id}Color`])}
    />
  );
};
