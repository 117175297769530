import { Comment } from 'components/Data/BuzzReport/Comment';
import { CommentAnalyticsList } from 'components/Data/BuzzReport/CommentAnalyticsList';

import { useParseCommentPercetage } from 'hooks/Buzz/useParseCommentPercetage';

import { FieldChartData } from 'types/Buzz/BuzzType';

interface CommentAnalyticsProps {
  data: FieldChartData;
  type: 'positive' | 'neutral' | 'negative' | '';
}

export const CommentAnalytics = ({ data, type }: CommentAnalyticsProps) => {
  const { positive, neutral, negative } = useParseCommentPercetage(data);

  return (
    <div className="flex flex-col justify-center basis-[417px] shrink-0 ml-79 mr-50">
      <div className="flex justify-between mb-32 ">
        <CommentAnalyticsList
          type="positive"
          count={data.comments.positiveCounts}
          ratio={positive}
        />
        <CommentAnalyticsList
          type="neutral"
          count={data.comments.neutralityCounts}
          ratio={neutral}
        />
        <CommentAnalyticsList
          type="negative"
          count={data.comments.negativeCounts}
          ratio={negative}
        />
      </div>
      <Comment type={type} />
    </div>
  );
};
