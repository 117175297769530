import { addComma } from 'components/common/addAndRemoveComma';

interface CommentCountProps {
  count: number;
  style?: string;
  numberOnly?: boolean;
}

export default function CommentCount({ count, style, numberOnly }: CommentCountProps) {
  return (
    <span className={`font-bold text-17 ${style} mobile:text-17`}>
      {addComma(count) + (numberOnly ? '' : '건')}
    </span>
  );
}
