import { useNavigate } from 'react-router-dom';

import bg from 'assets/image/NonSubscriberBg.png';

const NonSubscriberBg = () => {
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div className="flex gap-10">
        <h1 className="font-black text-20 text-[#FF9016]">MY MOVIE</h1>
        <div className="bg-[#f6ece3] w-86 text-center rounded-46 py-4">
          <p className="text-[#FF9016] text-13">내 영화 10편</p>
        </div>
      </div>
      <img
        className="relative w-full border-3 border-solid border-[#FF9016] rounded-14 mt-14"
        src={bg}
        alt="NonSubscriberBg"
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <p className="font-bold text-center text-white leading-48 text-32">
          내 영화 등록을 통해
          <br /> 영화에 대한 다양한 정보를 받아보세요.
        </p>
        <button
          className="mt-40 text-white border-2 border-white rounded-4 w-150 py-14 hover:bg-[#A6A6A6]"
          onClick={() => navigate('/subscription/payment')}
        >
          구독하기
        </button>
      </div>
    </div>
  );
};

export default NonSubscriberBg;
