import MediaInfoBox from 'components/Medium/MediaInfoBox';
import MoviePosterCardContainer from 'components/Medium/MoviePosterCardContainer';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { MappingContentWithIcon } from 'types/Medium/medium';

import { ReactComponent as Cgv } from 'assets/svg/Cgv.svg';
import { ReactComponent as Fb } from 'assets/svg/Facebook.svg';
import { ReactComponent as Naver } from 'assets/svg/Naver.svg';
import { ReactComponent as MediumIcon } from 'assets/svg/TVIcon.svg';
import { MediumContextProvider } from 'contexts/Medium/MediumContext';

const Medium = () => {
  const mediaContents = ['페이스북', 'CGV', '네이버'];
  const mappingContentsWithIcon: MappingContentWithIcon = {
    페이스북: Fb,
    CGV: Cgv,
    네이버: Naver,
  };

  return (
    <section>
      <MediumContextProvider>
        <div className="hidden mobile:block">
          <MobilePageTitle path1="영화 매체 분석" Icon={MediumIcon} title="영화 매체 분석" />
        </div>
        <div className="flex gap-14 mb-50 mobile:flex-col">
          {mediaContents.map((content) => (
            <MediaInfoBox key={content} Icon={mappingContentsWithIcon[content]!} content={content} />
          ))}
        </div>
        <MoviePosterCardContainer />
      </MediumContextProvider>
    </section>
  );
};

export default Medium;
