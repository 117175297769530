import { DateMediaList, ReformedDateMediaList } from 'types/Medium/naver';

export const reformDataForChart = (dateCgvList: DateMediaList[]) => {
  let newDataList: ReformedDateMediaList[] = [];

  dateCgvList?.map((dateCgv) => {
    newDataList.push({
      date: dateCgv.createdAt,
      watchedScore: dateCgv.watchedScore,
      netizenScore: dateCgv.netizenScore,
      likeCount: dateCgv.likeCount,
    });
  });

  return newDataList;
};
