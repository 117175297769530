import ComparisonTable from './ComparisonTable';
import { ColumnProps } from 'types/Comparison/ChartData';

import dataToEmotionData from 'utilities/Comparison/DataToEmotionData';

import { useMovieDataContext } from 'contexts/comparison/MovieDataContext';
import { useComparisonDataContext } from 'contexts/comparison/ComparisonDataContext';

const EmotionSection = () => {
  const { movieDate1, movieDate2 } = useMovieDataContext();
  const { movieData1, movieData2 } = useComparisonDataContext();

  const columnList : ColumnProps[] = [
    {
      key: "analysis",
      label: "분석"
    },
    {
      key: "firstMovie",
      label: movieDate1.title
    },
    {
      key: "secondMovie",
      label: movieDate2.title
    }
  ];

  return (
    <div className="flex flex-col w-full gap-14">
        <span className='w-full text-[#222] text-20 font-bold leading-[30px]'>
          감정 분석
        </span>

        <div className='flex w-full gap-14 [@media(max-width:850px)]:flex-col'>
          <div className='flex flex-col w-full gap-30 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
            <div className="flex w-full">
              <span className='w-full text-[#222] text-15 font-semibold pb-20 box-border border-b-1 border-solid border-[#EAEAEA]'>SNS</span>
            </div>
                        
            <ComparisonTable columns={columnList} data={dataToEmotionData(movieData1, movieData2, "NEWS")} />
          </div>

          <div className='flex flex-col w-full gap-30 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
            <div className="flex w-full">
              <span className='w-full text-[#222] text-15 font-semibold pb-20 box-border border-b-1 border-solid border-[#EAEAEA]'>포털</span>
            </div>
                        
            <ComparisonTable columns={columnList} data={dataToEmotionData(movieData1, movieData2, "SNS")} />
          </div>
        </div>

        <div className='flex w-full gap-14 [@media(max-width:850px)]:flex-col'>
          <div className='flex flex-col w-full gap-30 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
            <div className="flex w-full">
              <span className='w-full text-[#222] text-15 font-semibold pb-20 box-border border-b-1 border-solid border-[#EAEAEA]'>커뮤니티</span>
            </div>
              
            <ComparisonTable columns={columnList} data={dataToEmotionData(movieData1, movieData2, "커뮤니티")} />
          </div>

          <div className='flex flex-col w-full gap-30 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
            <div className="flex w-full">
              <span className='w-full text-[#222] text-15 font-semibold pb-20 box-border border-b-1 border-solid border-[#EAEAEA]'>뉴스</span>
            </div>
                  
            <ComparisonTable columns={columnList} data={dataToEmotionData(movieData1, movieData2, "포털")} />
          </div>  
        </div>
    </div>
  )
}

export default EmotionSection;