import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';

interface Props {
  data: any;
}

const GraphContainer = ({ data }: Props) => {
  const colums: LabeledColumnProps[] = [
    {
      key: 'idx',
      label: 'no.',
      flexGrow: 1,
    },
    {
      key: 'movie',
      label: '영화제목',
      flexGrow: 1,
    },
    {
      key: 'watchedScore',
      label: '조회수',
      flexGrow: 1,
    },
    {
      key: 'netizenScore',
      label: '댓글수',
      flexGrow: 1,
    },
    {
      key: 'likeCount',
      label: '좋아요',
      flexGrow: 1,
    },
    {
      key: 'date',
      label: '날짜',
      flexGrow: 1,
    },
  ];

  return (
    <div className="w-full h-auto bg-white rounded-14 shadow-medium">
      <DataTable datas={data} columns={colums} rowKey={'idx'} />
    </div>
  );
};

export default GraphContainer;
