import useGetApi from 'hooks/apis/useGetApi';

import { MappingContent } from 'types/Medium/medium';

import './MediaInfoBox.css';

interface Props {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  content: string;
  isTodayData?: 'no';
  mediaData?: number;
}

const mappingContent: MappingContent = {
  페이스북: 'facebook',
  네이버: 'naver',
  CGV: 'cgv',
};

const MediaInfoBox = ({ Icon, content, isTodayData, mediaData }: Props) => {
  const { data, isLoading } = useGetApi(
    `main-${mappingContent[content]}`,
    `/movie-medium-analysis/${mappingContent[content]}-count`,
  );
  const totalData = data && data[0];
  const todayData = data && data[1];

  return (
    <div className="flex flex-col flex-grow w-full h-[166px] p-24 rounded-14 bg-white border-1 border-[#EAEAEA] shadow-medium">
      <Icon />
      <p className="mb-20 font-semibold text-15 mt-19 leading-18">{content} 데이터 현황</p>
      <div className="flex items-center gap-10">
        <p className="font-bold text-24 leading-36">
          {isLoading ? '-' : isTodayData ? mediaData?.toLocaleString() : totalData.toLocaleString()}
        </p>
        {!isTodayData && (
          <div
            className={`flex items-center justify-center gap-3 w-97 h-26 rounded-14 ${mappingContent[content]}`}
          >
            <p className="text-12 leading-20">today</p>
            <p className="font-semibold text-12 leading-20">
              {isLoading ? '-' : todayData.toLocaleString()}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaInfoBox;
