import { ResponsiveRadialBar } from '@nivo/radial-bar';

import { ReactComponent as InnerCircleGray } from 'assets/svg/InnerCircleGray.svg';
import { ReactComponent as InnerCircleWhite } from 'assets/svg/InnerCircleWhite.svg';

interface CircleProgressChartProps {
  data: {
    id: string;
    data: {
      x: string;
      y: number;
    }[];
  }[];
  color: string;
}

export const CircleProgressChart = ({ data, color }: CircleProgressChartProps) => {
  return (
    <div className="w-[148px] h-[148px]">
      <ResponsiveRadialBar
        maxValue={100}
        startAngle={90}
        endAngle={450}
        cornerRadius={100}
        innerRadius={0.75}
        colors={[color]}
        data={data}
        layers={['bars', () => InnerCircle(color, data[0]?.data[0]?.y, data[0]?.id)]}
        isInteractive={false}
        enableTracks={false}
        motionConfig="slow"
        transitionMode="startAngle"
      />
    </div>
  );
};

const InnerCircle = (color: string, value: number | undefined, text: string | undefined) => {
  return (
    <>
      <InnerCircleWhite x={14} y={17} />
      <InnerCircleGray x={29} y={28} />
      <text
        x={74}
        y={62}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 24,
          fill: color,
          fontWeight: 'bold',
        }}
      >
        {value || '-'}%
      </text>
      <text
        x={76}
        y={88}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 16,
          fill: '#828282',
        }}
      >
        {text || ''}
      </text>
    </>
  );
};
