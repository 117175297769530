import { useMutation } from '@tanstack/react-query';

import { API_URL } from 'hooks/apis/apiUrl';
import { getCookie } from 'hooks/cookie';

const useDeleteApi = (key: string, url: string) => {
  const header = getCookie('loginToken');
  const COMPOUND_URL = `${API_URL}${url}`;

  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${header}`,
    },
  };

  const deleteData = async (url: string) => {
    const res = await fetch(COMPOUND_URL, options);

    if (!res.ok) {
      const errorResponse = await res.json();
      throw new Error(errorResponse.message);
    }

    const data = await res.json();

    return data;
  };

  const { mutate, data, isLoading, isError, error } = useMutation({
    mutationKey: [key],
    mutationFn: deleteData,
    onSuccess: () => {
      console.log('삭제 성공');
    },
    onError: (error) => {
      console.log(`요청 실패: ${error}`);
    },
  });

  return { mutate, data, isLoading, isError, error };
};

export default useDeleteApi;
