import { useMutation } from '@tanstack/react-query';

import { API_URL } from 'hooks/apis/apiUrl';
import { getCookie } from 'hooks/cookie';

const usePostApi = (key: string, url: string, body: any) => {
  const header = getCookie('loginToken');
  const COMPOUND_URL = `${API_URL}${url}`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // Authorization: `Bearer ${header}`,
    },
    body: JSON.stringify(body),
  };

  const postData = async () => {
    const res = await fetch(COMPOUND_URL, options);

    if (!res.ok) {
      const errorResponse = await res.json();
      throw new Error(errorResponse.message);
    }

    const data = await res.json();

    return data;
  };

  const { mutate, data, error, isError, isLoading } = useMutation({
    mutationKey: [key],
    mutationFn: postData,
    onSuccess: () => {
      console.log(`요청 성공`);
    },
    onError: (error) => {
      console.log(`요청 실패: ${error}`);
    },
  });

  return { data, isLoading, error, isError, mutate };
};

export default usePostApi;
