import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { FetchData, QuoContextValue, ViralList } from 'types/viral/quotation';

const ViralQuoContext = createContext<QuoContextValue>({
  country: '',
  setCountry: () => {
    return;
  },
  sortUnitPrice: '',
  setSortUnitPrice: () => {
    return;
  },
  quantity: '',
  setQuantity: () => {
    return;
  },
  gender: '',
  setGender: () => {
    return;
  },
  rangeValue: { fromPrice: 0, toPrice: 0 },
  setRangeValue: () => {
    return;
  },
  field: [],
  setField: () => {
    return;
  },
  viral: [],
  setViral: () => {
    return;
  },
  fetchData: [
    {
      list: {
        country: '',
        gender: '',
        startPrice: 0,
        endPrice: 0,
        limit: 0,
        isDescending: true,
        field: [],
        ospType: [],
      },
      totalFollower: 0,
      sumPrice: 0,
    },
  ],
  setFetchData: () => {
    return;
  },
  sumPrice: 0,
  setSumPrice: () => {
    return;
  },
  viralList: [
    {
      follower: 0,
      profile: '',
      fee: 0,
    },
  ],
  setViralList: () => {
    return;
  },
});

export const ViralQuoProvider = ({ children }: PropsWithChildren) => {
  const INITIAL_FROM_PRICE = 1;
  const INITIAL_TO_PRICE = 38;
  const [country, setCountry] = useState<string>();
  const [quantity, setQuantity] = useState<string>();
  const [sortUnitPrice, setSortUnitPrice] = useState<string>();
  const [gender, setGender] = useState<string>();
  const [rangeValue, setRangeValue] = useState({
    fromPrice: INITIAL_FROM_PRICE,
    toPrice: INITIAL_TO_PRICE,
  });
  const [field, setField] = useState<string[]>([]);
  const [viral, setViral] = useState<string[]>([]);
  const [fetchData, setFetchData] = useState<FetchData[]>();
  const [sumPrice, setSumPrice] = useState<number>(0);
  const [viralList, setViralList] = useState<ViralList[]>();

  const values = {
    country,
    setCountry,
    sortUnitPrice,
    setSortUnitPrice,
    quantity,
    setQuantity,
    gender,
    setGender,
    rangeValue,
    setRangeValue,
    field,
    setField,
    viral,
    setViral,
    fetchData,
    setFetchData,
    sumPrice,
    setSumPrice,
    viralList,
    setViralList,
  };

  return <ViralQuoContext.Provider value={values}>{children}</ViralQuoContext.Provider>;
};

export const useViralQuoContext = () => {
  return useContext(ViralQuoContext);
};
