import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { DateRangePicker } from 'rsuite';
import Select, { ActionMeta, SingleValue } from 'react-select';
import isAfter from 'date-fns/isAfter';
import axios from "axios";

import dateFormatter from "utilities/Comparison/DateFormatter";
import { useMovieDataContext } from "contexts/comparison/MovieDataContext";
import { useSelectDataContext } from "contexts/comparison/SelectDataContext";
import { useComparisonDataContext } from "contexts/comparison/ComparisonDataContext";
import { useSearchDataContext } from "contexts/comparison/SearchDataContext";
import { SelectProps } from "types/Comparison/SelectProps";
import dataToSelectProps from "utilities/Comparison/DataToSelectProps";
import { getCookie } from "hooks/cookie";


interface Props {
    setSearched : React.Dispatch<React.SetStateAction<boolean>>
}

const Versus = ({ setSearched } : Props) => {
    const url = useRef<string>('/movie/title-open');
    const dest = useRef<string>("movieSelectList");

    const { movieDate1, movieDate2, setMovieDate1, setMovieDate2 } = useMovieDataContext();
    const { movieSelectList1, movieSelectList2, setMovieSelectList1, setMovieSelectList2} = useSelectDataContext();
    const { setMovieData1, setMovieData2 } = useComparisonDataContext();
    const { startDate, endDate, option, setStartDate, setEndDate, setOption } = useSearchDataContext();

    const cookie = getCookie('loginToken');

    const client = axios.create({ baseURL: 'https://web-movieboard.com/api' });

    const request = ({ ...options }) => {
        client.defaults.headers.common.Authorization = `Bearer ${cookie}`;

        return client(options);
    };

    const getMovieList = () =>
        request({
            url: url.current,
        });

    const getDatas = async () => {
        const res = await getMovieList();

        const data = await res.data;

        return data;
    };

    const { data, refetch } = useQuery({
        queryKey: ['Versus'],
        queryFn: getDatas,
    });

    const handleSelect1 = (
        newValue: SingleValue<SelectProps>, 
        actionMeta: ActionMeta<SelectProps>
      ) => {
        const dateData = movieSelectList1?.filter(
            (item) => {
                return item?.title === newValue?.label;
            }
        )[0]!
        if (dateData) {
            setMovieDate1(dateData);
        }
    }

    const handleSelect2 = (
        newValue: SingleValue<SelectProps>, 
        actionMeta: ActionMeta<SelectProps>
      ) => {
        const dateData = movieSelectList2?.filter(
            (item) => {
                return item?.title === newValue?.label;
            }
        )[0]!
        if (dateData) {
            setMovieDate2(dateData);
        }
    }

    const clearBuf = () => {
        setMovieDate1({
            title: "",
            openDate: "",
            startDate: "",
            endDate: ""
        });

        setMovieDate2({
            title: "",
            openDate: "",
            startDate: "",
            endDate: ""
        });

        setMovieData1(
            {
                dateWatchedCountsMap : {},
                dateViralTotalsMap : {},
                dateBuzzCountsMap: {},
                platformBuzzCountsMap: {
                    NEWS: {},
                    SNS: {},
                    커뮤니티: {},
                    포털: {}
                }
            }
        );
        setMovieData2(
            {
                dateWatchedCountsMap : {},
                dateViralTotalsMap : {},
                dateBuzzCountsMap: {},
                platformBuzzCountsMap: {
                    NEWS: {},
                    SNS: {},
                    커뮤니티: {},
                    포털: {}
                }
            }
        );
    }

    const handleDateCalendar = (date : Date) => {
        if (isAfter(date, new Date())) {
            return true;
        }

        if  (isAfter(new Date(movieDate1.endDate), new Date(movieDate2.startDate))) {
            if (isAfter(date, new Date(movieDate2.startDate)) && isAfter(new Date(movieDate1.endDate), date)) {
                return false;
            }
        }
        else if (isAfter(new Date(movieDate2.endDate), new Date(movieDate1.startDate))) {
            if (isAfter(date, new Date(movieDate1.startDate)) && isAfter(new Date(movieDate2.endDate), date)) {
                return false;
            }
        }

        return true;
    }

    const optionLabel = ({ label } : SelectProps) => (
        <div style={{ flex: "10" }}>
            <span>{label!}</span>
        </div>
    );

    useEffect(() => {
        if (dest.current === "movieSelectList") {
            clearBuf();
            if (!movieSelectList1 || movieSelectList1?.length === 0 && Array.isArray(data)){
                setMovieSelectList1(data);
            }
            else {
                refetch();
            }
            if (!movieSelectList2 || movieSelectList2?.length === 0 && Array.isArray(data)){
                setMovieSelectList2(data);
            }
            else {
                refetch();
            }
        }
        else if (dest.current === "movieData1") {
            setMovieData1(data);

            if(option === "openDate") {
                const openDate2 = new Date(movieDate2.openDate);

                const dayBefore28 = new Date(openDate2);
                const dayAfter28 = new Date(openDate2.setDate(openDate2.getDate() + 28));

                dest.current = "movieData2";
                url.current = `/movie-compare/data?movieTitle=${movieDate2.title}&startDate=${dateFormatter(dayBefore28)}&endDate=${dateFormatter(dayAfter28)}`;
                refetch();
            }
            else {
                dest.current = "movieData2";
                url.current = `/movie-compare/data?movieTitle=${movieDate2.title}&startDate=${dateFormatter(startDate)}&endDate=${dateFormatter(endDate)}`;
                refetch();
            }
        }
        else if (dest.current === "movieData2") {
            setMovieData2(data);
            setSearched(true);
        }

    }, [data, option, movieSelectList1, movieSelectList2]);

    return (
        <div className="flex flex-col justify-center h-[258px] gap-24 px-[300px] py-50 rounded-14 bg-[#ffffff] shadow-[0px_0px_24px_0px_rgba(0,0,0,0.1)] [@media(max-width:1400px)]:h-auto [@media(max-width:1400px)]:overflow-visible [@media(max-width:1400px)]:px-20 [@media(max-width:1400px)]:py-30">
        <span className="flex justify-center w-full min-w-500 text-20 font-bold [@media(max-width:850px)]:min-w-300">
            비교하실 영화를 선택해주세요
        </span>

        <div className="flex justify-start items-center min-w-[300px] gap-16 [@media(max-width:1400px)]:flex-col [@media(max-width:1400px)]:w-full">
            <div className="flex w-full gap-16">
                <div className="flex items-center gap-4">
                    <input 
                            type="radio" 
                            name="options" 
                            className="w-24 h-24 border-1 border-solid border-[#B6B6B6] rounded-[60px] bg-[#ffffff] appearance-none checked:border-[#EAEAEA] checked:bg-[url('assets/svg/checkComparison.svg')] checked:bg-no-repeat checked:bg-center"
                            onClick={() => {
                                setOption("openDate");
                            }}
                            defaultChecked
                    />
                    <span 
                        className="text-14 text-[#222]"
                    >
                        개봉일 기준
                    </span>
                </div>
                {/* <div className="flex items-center gap-4">
                    <input 
                        type="radio" 
                        name="options" 
                        className="w-24 h-24 border-1 border-solid border-[#B6B6B6] rounded-[60px] bg-[#ffffff] appearance-none checked:border-[#EAEAEA] checked:bg-[url('assets/svg/checkComparison.svg')] checked:bg-no-repeat checked:bg-center"
                        onClick={() => {
                            setOption("useCalendar");
                        }}
                        disabled
                    />
                    <span 
                        className="text-14 text-[#222]"
                    >
                        캘린더
                    </span>
                </div> */}
            </div>

            { option === "useCalendar" && 
                <>
                    <DateRangePicker 
                        defaultValue={[startDate, endDate]}
                        className="min-w-[300px] h-full ml-5 mobile:justify-start mobile:w-full"
                        onChange={(range) => {
                            setStartDate(range?.[0]!);
                            setEndDate(range?.[1]!);
                        }}
                        shouldDisableDate={date => {
                            return handleDateCalendar(date);
                        }}
                    />
                </>
            }
        </div>
        <div className="flex justify-center items-center min-w-[300px] gap-10 [@media(max-width:1400px)]:flex-col [@media(max-width:1400px)]:min-w-0">
            <Select
                options={Array.isArray(movieSelectList1) ? dataToSelectProps(movieSelectList1) : []}
                className= "w-full min-w-200"
                menuPortalTarget={document.getElementById("test")}
                autoFocus={false}
                blurInputOnSelect={false}
                onChange={handleSelect1}
                isSearchable={true}
                value={Array.isArray(movieSelectList1) ? dataToSelectProps(movieSelectList1)?.filter(
                    (item) => {
                        return item?.label === movieDate1?.title;
                })! : []}
                formatOptionLabel={optionLabel}
                placeholder={"선택"}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        height: '44px',
                        border: '1px solid #EAEAEA',
                        backgroundColor: "#fff",
                        fontSize: '14px',
                        boxShadow: "none"
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? "#DAEFF7" : "transparent",
                        color: state.isSelected ? "#111111" : "#A4ADBB",
                        fontSize: '14px'
                    }),
                    menuList: (baseStyles) => ({
                        ...baseStyles,
                        maxHeight: '150px',
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontSize: '14px'
                    }),
                    menuPortal: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 9999
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#6D7683"
                    })
                }}
                theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                            ...theme.colors,
                            primary: '#22b6e9',
                        },
                })}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />

            <span className="text-[#222] text-20 font-semibold">
            vs
            </span>

            <Select
                options={Array.isArray(movieSelectList2) ? dataToSelectProps(movieSelectList2)?.filter(
                    (item) => {
                        return item?.label !== movieDate1?.title;
                    })! : []
                }
                className="w-full min-w-200"
                menuPortalTarget={document.body}
                autoFocus={false}
                blurInputOnSelect={false}
                onChange={handleSelect2}
                value={Array.isArray(movieSelectList2) ? dataToSelectProps(movieSelectList2)?.filter(
                    (item) => {
                        return item?.label === movieDate2?.title;
                    })! : []
                }
                isDisabled={movieDate1?.title === ""}
                isSearchable
                formatOptionLabel={optionLabel}
                placeholder={"선택"}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        height: '44px',
                        border: '1px solid #EAEAEA',
                        backgroundColor: "#fff",
                        fontSize: '14px',
                        boxShadow: "none"
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? "#DAEFF7" : "transparent",
                        color: state.isSelected ? "#111111" : "#A4ADBB",
                        fontSize: '14px'
                    }),
                    menuList: (baseStyles) => ({
                        ...baseStyles,
                        maxHeight: '150px',
                        paddingTop: 0,
                        paddingBottom: 0,
                        fontSize: '14px'
                    }),
                    menuPortal: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 9999
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#6D7683"
                    })
                }}
                theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                            ...theme.colors,
                            primary: '#22b6e9',
                        },
                })}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />

            <div
                onClick={() => {
                    if(movieDate1.title === "" || movieDate2.title === "") {
                        alert('영화를 두 개 모두 선택해주세요.');
                    }
                    else {
                        if (option === 'openDate') {
                            const openDate1 = new Date(movieDate1.openDate);

                            dest.current = "movieData1"
                            url.current = `/movie-compare/data?movieTitle=${movieDate1.title}&startDate=${dateFormatter(new Date(openDate1))}&endDate=${dateFormatter(new Date(openDate1.setDate(openDate1.getDate() + 28)))}`
                            refetch();
                        }
                        else {
                            if (isAfter(new Date(movieDate2.startDate), new Date(movieDate1.endDate))
                                || isAfter(new Date(movieDate1.startDate), new Date(movieDate2.endDate))
                            ) {
                                alert("영화를 다시 선택해주세요!");
                                clearBuf();
                                return;
                            }

                            dest.current = "movieData1"
                            url.current = `/movie-compare/data?movieTitle=${movieDate1.title}&startDate=${dateFormatter(startDate)}&endDate=${dateFormatter(endDate)}`
                            refetch();
                        }
                    }
                }}
                className="flex justify-center items-center min-w-150 h-44 p-14 box-border rounded-4 bg-[#FF9016] text-15 text-[#ffffff] font-bold cursor-pointer mobile:w-full"
            >
                검색
            </div>
        </div>
        </div>
    )
};

export default Versus;