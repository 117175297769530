import { MovieDateProps } from "types/Comparison/MovieDateProps";
import { SelectProps } from "types/Comparison/SelectProps";

const dataToSelectProps = (selectList : MovieDateProps[]) => {
    const optionList : SelectProps[] = [];
    const len = selectList.length;

    for (let i = 0; i < len; i++) {
        optionList.push({
            label: selectList[i]?.title!,
            value: selectList[i]?.title!
        })
    }

    return optionList;
}

export default dataToSelectProps;