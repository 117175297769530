import SearchInput from 'components/Management/TotalDataManage/SearchInput';
import Button from 'components/common/Filter/Button';
import { Calender } from 'components/common/Filter/Calender';
import CategoryBtn from 'components/common/Filter/CategoryBtn';
import Checkbox from 'components/common/Filter/Checkbox';
import RangeSlider from 'components/common/Filter/RangeSlider';
import { SelectBox } from 'components/common/Filter/SelectBox';
import Several from 'components/common/Filter/Several';

import { FilterList } from 'types/Management/mangementType';

interface FiltersProps {
  filterList: FilterList;
  styles: { [key: string]: string | string[] };
}

const Filters = ({ filterList, styles }: FiltersProps) => {
  const { SECTION_WIDTH, FONTSIZE } = styles;
  const keyOfList = Object.keys(filterList);

  return (
    <div className="flex items-center justify-center w-full mobile:flex-col">
      {keyOfList.map((lists, idx) => (
        <div
          key={idx}
          className={`${SECTION_WIDTH && SECTION_WIDTH[idx]} ${
            idx !== keyOfList.length - 1 ? 'border-r-1 border-light-gray mobile:border-none' : ''
          } w-full flex flex-col flex-shrink items-center gap-16`}
        >
          {filterList[lists]?.map((el) => (
            <div
              key={el.selectTitle}
              className={`${FONTSIZE} flex flex-col items-center gap-16 text-very-dark-gray w-full mobile:py-20`}
            >
              <div className="flex mb-16 font-bold text-left mt-25 text-17 pl-30 w-[505px] mobile:justify-center mobile:p-0">
                <p>{el.selectTitle}</p>
              </div>
              {el.type === 'select' && (
                <div className="w-full px-28">
                  <SelectBox
                    title={el.placeholder || el.selectTitle}
                    options={el.options || []}
                    handleOptionChange={(target) => el.setState(target)}
                    value={el.value || ''}
                  />
                </div>
              )}
              {el.type === 'calendar' && (
                <div className="w-full px-32">
                  <Calender
                    handleChange={(target) => el.setState(target)}
                    large={el.size || false}
                  />
                </div>
              )}
              {el.type === 'several' && <Several several={el.several || []} />}
              {el.type === 'checkbox' && (
                <Checkbox options={el.options || []} handleOptionChange={el.setState} />
              )}
              {el.type === 'rangebar' && <RangeSlider handleChange={el.setState} />}
              {el.type === 'buttons' && (
                <CategoryBtn
                  options={el.options || []}
                  handleChange={el.setState}
                  state={el.state || []}
                />
              )}
              {el.type === 'input' && (
                <SearchInput
                  filter={el.filter || false}
                  placeholder={el.placeholder || ''}
                  onChange={(target) => el.setState(target)}
                  setCategory={el.setCategory}
                />
              )}
              {el.type === 'button' && (
                <Button options={el.options || []} handleChange={el.setState} />
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Filters;
