import { ParsingForm, PlatformDateCountsMap } from 'types/Buzz/BuzzType';

export const useAddCountData = (datas: PlatformDateCountsMap, result: ParsingForm): ParsingForm => {
  for (const [key, value] of Object.entries(datas)) {
    for (const [keyOfValue, valueOfValue] of Object.entries(value)) {
      Object.keys(result).forEach((keyOfResult) => {
        keyOfValue === keyOfResult &&
          (result[keyOfResult] = { ...result[keyOfResult], [key]: valueOfValue.totalCounts });
      });
    }
  }

  return result;
};
