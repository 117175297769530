import PillBtn from 'components/common/Button/PillBtn';

import { customedAxios } from 'hooks/axiosApis/customedAxios';

import { ReactComponent as Pdf } from 'assets/svg/Pdf.svg';

interface PdfDownloadBtnProps {
  text: string;
  page: string;
}

export const PdfDownloadBtn = ({ text, page }: PdfDownloadBtnProps) => {
  const pdfBtnProps = {
    width: 'w-85',
    height: 'h-30',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: 'PDF',
    color: {
      text: 'text-white',
      bg: 'bg-pdf',
    },
    svg: <Pdf />,
    handleClick: () => {
      if (confirm('pdf 파일을 다운받으시겠습니까?')) {
        const downloadUrl =
          `https://web-movieboard.com/api/s3/download?folder=${page}_report&fileKey=` + text;

        customedAxios
          .get(downloadUrl)
          .then(() => (location.href = downloadUrl))
          .catch(() => alert('pdf 파일을 다운받을 수 없습니다.'));
      }
    },
  };

  return (
    <div>
      <PillBtn pillBtnProps={pdfBtnProps} />
    </div>
  );
};
