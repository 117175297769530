import 'swiper/css';

import AIPrediction from 'components/Dashboard/AIPrediction';
import MyMovie from 'components/Dashboard/MyMovie';
import SubscriptionManage from 'components/Dashboard/SubscriptionManage';
import Top10Section from 'components/Dashboard/Top10Section';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { MovieDetailProvider } from 'contexts/dashboard/MovieDetailContext';
import { MovieIdxProvider } from 'contexts/dashboard/MovieIdxContext';
import { MovieListProvider } from 'contexts/dashboard/MovieListContext';

import { ReactComponent as DashboardIcon } from 'assets/svg/DashboardIcon.svg';

const Dashboard = () => {
  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle path1="대시보드" Icon={DashboardIcon} title="대시보드" />
      </div>
      <div className="flex flex-col gap-50 mb-30">
        <MovieDetailProvider>
          <MovieIdxProvider>
            <MovieListProvider>
              <Top10Section />
              <div className="flex items-stretch min-h-[172px] gap-50 [@media(max-width:1300px)]:flex-col">
                <AIPrediction />
                <SubscriptionManage />
              </div>
              <MyMovie />
            </MovieListProvider>
          </MovieIdxProvider>
        </MovieDetailProvider>
      </div>
    </>
  );
};

export default Dashboard;
