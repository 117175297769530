import { PlatformOspCountsMap } from 'types/Buzz/BuzzType';

export const useAddTotalCount = (
  datas: PlatformOspCountsMap,
  inputKeys: string[],
  totalCount: number,
) => {
  inputKeys.forEach((key) => {
    totalCount += Object.values(datas?.[key] || {}).reduce((prev, cur) => prev + cur, 0);
  });

  return totalCount;
};
