import { Dispatch, SetStateAction } from 'react';

import useGetApi from 'hooks/axiosApis/useGetApi';

interface Prop {
  setState: Dispatch<SetStateAction<string[]>>;
  withCompare?: boolean;
}

const useCpDropdown = ({ setState, withCompare }: Prop) => {
  const URL = '/member/title';
  const cpMenu = useGetApi(URL).then((data) =>
    setState(
      withCompare || data.length > 1 ? [...new Set(['CP사'].concat('경쟁작').concat(data))] : data,
    ),
  );

  return cpMenu;
};

export default useCpDropdown;
