import { ReactComponent as CloseSharp } from "../../../assets/svg/closeSharp.svg"
import { forwardRef, useState } from "react"
import ModalPlanCard from "./ModalPlanCard";
import { ReactComponent as FreeModalStar } from '../../../assets/svg/FreeModalStar.svg';
import { ReactComponent as MonthModalStar } from '../../../assets/svg/MonthModalStar.svg';
import { ReactComponent as YearModalStar } from '../../../assets/svg/YearModalStar.svg';

import { PlanData } from "types/Subscription/planData";

interface PaymentModalProps {
    close: () => void
}

const PaymentModal = forwardRef<HTMLDialogElement, PaymentModalProps>(({ close }, ref) => {
    const [selectedPlan, setSelectedPlan] = useState<string>("무료 회원");
    
    const planList : PlanData[] = [
        {
            svg : <FreeModalStar/>,
            description : "회원 가입", 
            planName: "무료 회원",
            price : "무료 이용",
            payEveryMonth : false,
            options : {
                Mailing : true,
                MovieZine : true,
                MovieTop10 : false,
                AnalyzeBookmarkData : false
            }
        },
        {
            svg : <MonthModalStar/>,
            description : "1개월 이용료", 
            planName: "1개월 구독",
            movieNum: 5,
            price : "₩9,000",
            payEveryMonth : true,
            options : {
                Mailing : true,
                MovieZine : true,
                MovieTop10 : true,
                AnalyzeBookmarkData : false
            }
        },
        {
            svg : <YearModalStar/>,
            description : "1년 이용료", 
            planName: "1년 구독",
            movieNum: 10,
            price : "₩99,000",
            payEveryMonth : true,
            options : {
                Mailing : true,
                MovieZine : true,
                MovieTop10 : true,
                AnalyzeBookmarkData : true
            }
        }
    ]
    
    return (
        <dialog
            className="w-[1409px] px-0 py-30 rounded-14"
            ref={ref}
        >   
        <div className="flex flex-col">
            <div className="flex justify-between items-center mx-30 mb-30 pb-20 box-border border-b-1 border-solid border-[#D9D9D9]">
                <span className="text-20 font-bold">구독 결제</span>
                <CloseSharp 
                    className="cursor-pointer"
                    onClick={() => close()}
                />
            </div>

            <div className="flex flex-col justify-center items-center gap-60">
                <div className="flex gap-24">
                    {planList.map((data, index) => {
                        return <ModalPlanCard key={index} data={data} selected={selectedPlan} setSelected={setSelectedPlan}/>
                    })
                    }
                </div>

                <button className='w-150 h-46 p-14 rounded-4 bg-[#FF9016] text-[#FFF] text-15 font-bold'>
                    결제하기
                </button>
            </div>
        </div>
        </dialog>
    )
})

export default PaymentModal;