import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import moment from 'moment';
import { DateRange } from 'rsuite/esm/DateRangePicker';

import PillBtn from 'components/common/Button/PillBtn';
import { Calender } from 'components/common/Filter/Calender';
import { FilterTitle } from 'components/common/Filter/FilterTitle';
import { SelectBox } from 'components/common/Filter/SelectBox';
import { SelectTitle } from 'components/common/Filter/SelectTitle';
import { VerticalLine } from 'components/common/VerticalLine';

import { customedAxios } from 'hooks/axiosApis/customedAxios';
import { useGetIsAdmin } from 'hooks/axiosApis/useGetIsAdmin';
import useCpDropdown from 'hooks/dropdown/useCpDropdown';

interface SearchFilterContainerProps {
  setStateActions: {
    setSelectedCp: Dispatch<SetStateAction<string>>;
    setSelectedMovie: Dispatch<SetStateAction<string>>;
    setDateRange: Dispatch<SetStateAction<{ startDate: string; endDate: string } | undefined>>;
  };
  onClickSearchBtn: (cp?: string) => void;
}

export const SearchFilterContainer = ({
  setStateActions,
  onClickSearchBtn,
}: SearchFilterContainerProps) => {
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [cpValue, setCpValue] = useState<string>('');
  const [movieValue, setMovieValue] = useState<string>('');
  const [cpMenus, setCpMenus] = useState<string[]>([]);
  const [movieOptions, setMovieOptions] = useState<string[]>([]);

  const handleCpOptionChange = async (cp: string) => {
    const { data } = await customedAxios.get('/movie/title?cp=' + cp);
    setMovieOptions(data ? [...data] : []);
    setStateActions.setSelectedCp(cp);
    cp !== 'CP사' ? setCpValue(cp) : setCpValue('');
  };

  const handleDaterangeChange = (value: DateRange | null) => {
    if (value) {
      const startDate = moment(value[0]).format('YYYY-MM-DD');
      const endDate = moment(value[1]).format('YYYY-MM-DD');

      setStateActions.setDateRange({ startDate, endDate });
    }
  };

  const redSearchBtn = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: true,
      color: 'drop-shadow-pink',
    },
    content: '검색',
    color: {
      text: 'text-white',
      bg: 'bg-[#FF9016]',
    },
    handleClick: () => onClickSearchBtn(),
  };

  useEffect(() => {
    useCpDropdown({ setState: setCpMenus });
    useGetIsAdmin().then((data) => setIsAdmin(data));
    onClickSearchBtn();
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      setCpValue(cpMenus[0] ? cpMenus[0] : '');
      setStateActions.setSelectedCp(cpMenus[0] ? cpMenus[0] : '');
      onClickSearchBtn(cpMenus[0]);
    }
  }, [isAdmin]);

  return (
    <div className="w-full bg-white rounded-24 py-30 shadow-box min-w-[1600px] mobile:flex-col mobile:min-w-[343px]">
      <FilterTitle title="검색" />

      <div className="flex justify-between w-full px-28 pb-28 mt-30 mobile:items-center mobile:flex-col mobile:gap-30">
        <div className="flex items-center justify-center w-1/3 gap-14 mobile:min-w-[303px]">
          <SelectTitle title="CP사" />
          <SelectBox
            title="CP사"
            options={cpMenus}
            handleOptionChange={handleCpOptionChange}
            value={cpValue}
          />
        </div>
        <div className="mobile:hidden">
          <VerticalLine height={72} />
        </div>
        <div className="flex items-center justify-center w-1/3 gap-14 mobile:min-w-[303px]">
          <SelectTitle title="영화" />
          <SelectBox
            title="영화"
            options={movieOptions}
            handleOptionChange={(movie) => {
              setMovieValue(movie);
              setStateActions.setSelectedMovie(movie);
            }}
            value={movieValue}
          />
        </div>
        <div className="mobile:hidden">
          <VerticalLine height={72} />
        </div>
        <div className="flex items-center justify-center w-1/3 gap-14 mobile:min-w-[303px]">
          <p className="text-very-dark-gray text-18 mobile:text-14">날짜</p>
          <div>
            <Calender handleChange={handleDaterangeChange} />
          </div>
        </div>
        <div className="flex items-center justify-center mobile:border-t-1 mobile:border-[#EAEAEA] mobile:pt-20 mobile:w-[303px]">
          <PillBtn pillBtnProps={redSearchBtn} />
        </div>
      </div>
    </div>
  );
};
