import { ReactComponent as NextArrow } from 'assets/svg/pathNextArrow.svg';

interface Props {
  path1?: string;
  path2?: string;
  title: string;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const MobilePageTitle = ({ path1, path2, title, Icon }: Props) => {
  return (
    <section className="block mt-[8px] mb-[20px] ml-[0px]">
      {path1 && (
        <div className="flex mb-[10px] ml-[5px]">
          <span className="text-[12px] text-[#A4ADBB]">{path1}</span>
          {path2 && (
            <>
              <span className="ml-[6px] mr-[6px]">
                <NextArrow />
              </span>
              <span className="text-[12px] text-[#A4ADBB]">{path2}</span>
            </>
          )}
        </div>
      )}
      <div className="flex items-center ">
        {Icon && (
          <span className="flex items-center justify-center w-[30px] h-[30px] mr-[2px]">
            <Icon />
          </span>
        )}
        <p className="text-[20px] text-[#323842] font-bold">{title}</p>
      </div>
    </section>
  );
};

export default MobilePageTitle;
