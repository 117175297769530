import moment from 'moment';

import { MovieInfo } from 'types/Buzz/BuzzType';

export interface MovieInfoProps {
  data: MovieInfo | undefined;
  inputRef: {
    title: React.MutableRefObject<HTMLInputElement | null>;
    info: React.MutableRefObject<HTMLInputElement | null>;
  };
}

export const MovieInfoContainer = ({ data, inputRef }: MovieInfoProps) => {
  let startDate, endDate;
  if (data) {
    if (inputRef.title.current && inputRef.title.current.value === '') {
      inputRef.title.current.value = data.reportTitle;
    }
    if (inputRef.info.current && inputRef.info.current.value === '') {
      inputRef.info.current.value = data.info;
    }
    startDate = moment(data.startDate).format('YYYY-MM-DD');
    endDate = moment(data.endDate).format('YYYY-MM-DD');
  }

  return (
    <div className="font-noto">
      <div className="flex gap-12">
        <input
          ref={inputRef.title}
          className="w-[1654px] h-[52px] pl-12 outline-none  border-1 border-light-gray2 leading-52 rounded-8 shrink-0"
          placeholder="신규 보고서 제목 입력"
        ></input>
        <div className="h-[52px] text-center rounded-8 basis-full shrink bg-date-select leading-52">
          {data ? `${startDate} ~ ${endDate}` : '-'}
        </div>
      </div>
      <div className="flex gap-24 mt-24">
        <div className="w-[179px] h-[254px] rounded-12">
          {data && data.image ? (
            <img
              src={`https://d11ghuz6ytmmsy.cloudfront.net/movie_poster/${data.image}`}
              className="rounded-12"
            ></img>
          ) : (
            <div className="w-full h-full bg-nullImg rounded-12"></div>
          )}
        </div>

        <div className="flex flex-col w-full gap-12 pt-5">
          <div className="h-[52px] pl-12 bg-date-select rounded-8 leading-52">
            {data ? data.title : '영화 제목'}
          </div>
          <div className="h-[52px] pl-12 bg-date-select rounded-8 leading-52">
            {data ? data.company : '감독/제작사'}
          </div>
          <div className="h-[52px] pl-12 bg-date-select rounded-8 leading-52">
            {data ? data.description : '장르/개봉일/러닝타임/관람연령'}{' '}
          </div>
          <input
            ref={inputRef.info}
            className="h-[52px] pl-12 outline-none  border-1 border-light-gray2 rounded-8 leading-52"
            placeholder="기타정보 입력"
          ></input>
        </div>
      </div>
    </div>
  );
};
