import { Dispatch, SetStateAction, useState } from 'react';

interface Props {
  options: string[];
  handleChange: Dispatch<SetStateAction<string>>;
}

const Button = ({ options, handleChange }: Props) => {
  const [whichClicked, setWhichClicked] = useState<string>();
  const handleClick = (target: string) => {
    handleChange(target);
    setWhichClicked(target);
  };

  return (
    <div className="flex justify-center gap-12 px-32">
      {options.map((el) => (
        <button
          onClick={() => handleClick(el)}
          className={`${
            whichClicked === el
              ? 'border-0 bg-main text-white'
              : 'border-1 border-graph-gray text-gray2'
          } flex items-center justify-center w-[4.02vw] h-38  rounded-8 text-[0.54vw] `}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export default Button;
