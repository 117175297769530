interface CancelCellProps {
    text?: string,
    id?: string
}

const CancelCell = ({ text, id} : CancelCellProps) => {

    return (
        <div className={id === "취소" ? "text-[#A4ADBB]" : ""}>
            {text!}
        </div>
    )
}

export default CancelCell