import { useParseCommentCount } from 'hooks/Buzz/useParseCommentCount';
import { useParseFieldCount } from 'hooks/Buzz/useParseFieldCount';

import {
  FieldBuzzData,
  FieldChartData,
  PlatformDateCountsMap,
  PlatformOspCountsMap,
} from 'types/Buzz/BuzzType';

export const useParseFieldCountData = (
  ospDatas: PlatformOspCountsMap,
  dateDatas: PlatformDateCountsMap,
  fieldBuzzDatas: FieldBuzzData[],
  fieldChartDatas: FieldChartData[],
) => {
  fieldBuzzDatas = useParseFieldCount(ospDatas, fieldBuzzDatas);
  fieldBuzzDatas = useParseCommentCount(dateDatas, fieldBuzzDatas, fieldChartDatas);

  return fieldBuzzDatas;
};
