import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

import { DateRange } from 'rsuite/esm/DateRangePicker';

interface FilterContext {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  cp: string | undefined;
  setCp: Dispatch<SetStateAction<string | undefined>>;
  movie: string | undefined;
  setMovie: Dispatch<SetStateAction<string | undefined>>;
  keyword: string | undefined;
  setKeyword: Dispatch<SetStateAction<string | undefined>>;
  platform: string | undefined;
  setPlatform: Dispatch<SetStateAction<string | undefined>>;
  osp: string | undefined;
  setOsp: Dispatch<SetStateAction<string | undefined>>;
  date: DateRange | undefined | null;
  setDate: Dispatch<SetStateAction<DateRange | undefined | null>>;
  sentiment: string | undefined;
  setSentiment: Dispatch<SetStateAction<string | undefined>>;
}

const FilterContext = createContext<FilterContext>({
  page: 1,
  setPage: () => {
    return;
  },

  cp: '',
  setCp: () => {
    return;
  },
  movie: '',
  setMovie: () => {
    return;
  },
  keyword: '',
  setKeyword: () => {
    return;
  },
  platform: '',
  setPlatform: () => {
    return;
  },
  osp: '',
  setOsp: () => {
    return;
  },
  sentiment: '',
  setSentiment: () => {
    return;
  },
  date: [new Date(), new Date()],
  setDate: () => {
    return;
  },
});

export function TotalAmountContextProvider({ children }: PropsWithChildren) {
  const [page, setPage] = useState<number>(1);
  const [cp, setCp] = useState<string>();
  const [movie, setMovie] = useState<string>();
  const [keyword, setKeyword] = useState<string>();
  const [platform, setPlatform] = useState<string>();
  const [osp, setOsp] = useState<string>();
  const [sentiment, setSentiment] = useState<string>();
  const [date, setDate] = useState<DateRange | null>();

  const value = {
    page,
    setPage,
    cp,
    setCp,
    movie,
    setMovie,
    keyword,
    setKeyword,
    platform,
    setPlatform,
    osp,
    setOsp,
    sentiment,
    setSentiment,
    date,
    setDate,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
}

export function useTotalAmountFilterContext() {
  return useContext(FilterContext);
}
