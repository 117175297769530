import CommentCount from 'components/common/CommentAnalytics/CommentCount';
import CommentCountRatio from 'components/common/CommentAnalytics/CommentCountRatio';
import EmotionImage from 'components/common/CommentAnalytics/EmotionImage';
import EmotionText from 'components/common/CommentAnalytics/EmotionText';

interface CommentAnalyticsListProps {
  type: 'positive' | 'neutral' | 'negative';
  count: number;
  ratio: number;
}

export const CommentAnalyticsList = ({ type, count, ratio }: CommentAnalyticsListProps) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <EmotionImage type={type} style="mb-13" />
      <EmotionText type={type} style="mb-28" />
      <CommentCount count={count} style="mb-12" />
      <CommentCountRatio count={ratio} />
    </div>
  );
};
