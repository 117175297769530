import {
  FieldBuzzData,
  PlatformOspCountsMap,
  TotalAnalyticsData,
} from './../../types/Buzz/BuzzType.d';

export const useParseDatas = (
  datas: PlatformOspCountsMap,
  inputKeys: string[],
  totalCount: number,
  fieldBuzzDatas: FieldBuzzData[],
  totalAnalyticsDatas: TotalAnalyticsData[],
) => {
  inputKeys.map((key) => {
    const count = Object.values(datas[key] || {}).reduce((prev, cur) => prev + cur, 0);
    const percentage = ((count / totalCount) * 100).toFixed(1);

    totalAnalyticsDatas = totalAnalyticsDatas.map((data) => {
      return data.title === key ? { ...data, count, percentage } : data;
    });
    fieldBuzzDatas = fieldBuzzDatas.map((data) => {
      return data.title === key ? { ...data, totalCount: count } : data;
    });
  });

  return { fieldBuzzDatas, totalAnalyticsDatas };
};
