import { Dispatch, SetStateAction, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { menu2icon } from 'utilities/Nav/menu2icon';

import MenuButton from 'components/common/Layout/Navbar/MenuButton';

import { PageStateContext } from 'contexts/NavbarContext';

import { removeCookie } from 'hooks/cookie';

import { PageState, SubPageState } from 'types/Nav/menu';

import logo from 'assets/image/Logo.png';
import { ReactComponent as Logout } from 'assets/svg/Logout.svg';
import { ReactComponent as X } from 'assets/svg/X_wthie.svg';

import './Animation.css';

interface Props {
  name: string;
  profileImage: string;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

const Navbar = ({ name, profileImage, openMenu, setOpenMenu }: Props) => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState<PageState>('dashboard');
  const [subPageState, setSubPageState] = useState<SubPageState | undefined>();

  const rmCookie = () => {
    removeCookie('loginToken');
    removeCookie('name');
    removeCookie('profileImage');

    window.location.href = '/';
  };

  const menuContentArr: string[] = [
    '대시보드',
    '전체 데이터 통계',
    '영화 비교 분석',
    '영화 매체 분석',
    '정기 구독 관리',
    '리포트 센터',
    '관리 센터',
  ];

  return (
    <nav
      className={`fixed top-0 flex flex-col items-center px-30 bg-[#000C1B] w-[260px] h-screen z-[999] mobile:${
        openMenu ? 'left-auto right-0' : 'hidden'
      }`}
    >
      <div className="flex justify-between w-full my-40">
        <img
          src={logo}
          alt="logo"
          className="cursor-pointer w-106 h-55"
          onClick={() => navigate('/')}
        />
        {openMenu && (
          <X
            className="cursor-pointer fill-white stroke-white"
            onClick={() => setOpenMenu(false)}
          />
        )}
      </div>
      <div className="flex flex-col w-full gap-6">
        <PageStateContext.Provider
          value={{ pageState, setPageState, subPageState, setSubPageState }}
        >
          {menuContentArr.map((content) => {
            const menuIcon = menu2icon(content);

            return <MenuButton key={content} content={content} Icon={menuIcon!} />;
          })}
        </PageStateContext.Provider>
      </div>
      <div className="items-center hidden mobile:flex mobile:absolute mobile:bottom-30">
        <button
          onClick={() => rmCookie()}
          className="flex items-center justify-center gap-2 w-78 h-30 border-1 text-black bg-[#E2E2E2] rounded-4 text-12 font-[500] leading-14"
        >
          로그아웃
          <Logout />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
