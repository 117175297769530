import { FieldImage } from 'components/common/Report/FieldImage';
import { addComma } from 'components/common/addAndRemoveComma';

interface FieldImgAndTotalProps {
  byField: {
    style: string;
    src: string | undefined;
    totalValue: number | undefined;
    title: string;
  };
}

const FieldImgAndTotal = ({ byField }: FieldImgAndTotalProps) => {
  const { style, src, totalValue, title } = byField;
  return (
    <>
      <FieldImage style={style} src={src} />
      <div className="flex flex-col gap-12 ml-24 w-100">
        <p>{title}</p>
        <p className="pt-1 font-bold truncate text-20">{totalValue ? addComma(totalValue) : '-'}</p>
      </div>
    </>
  );
};

export default FieldImgAndTotal;
