import { useEffect, useState } from 'react';

import PillBtn from 'components/common/Button/PillBtn';
import Filters from 'components/common/Filter/Filters';

import { useTotalAmountFilterContext } from 'contexts/Management/TotalAmountContext';

import useCpDropdown from 'hooks/dropdown/useCpDropdown';
import useKeywordDropdown from 'hooks/dropdown/useKeywordDropdown';
import useMovieDropdown from 'hooks/dropdown/useMovieDropdown';
import useOspDropdown from 'hooks/dropdown/useOspDropdown';
import useConvertSentiment from 'hooks/useConvertSentiment';

import { FilterList } from 'types/Management/mangementType';

import { ReactComponent as TotalSearch } from 'assets/svg/totalSearch.svg';

interface Props {
  refetch: () => void;
}

const FilterContainer = ({ refetch }: Props) => {
  const {
    cp,
    setCp,
    movie,
    setMovie,
    keyword,
    setKeyword,
    platform,
    setPlatform,
    osp,
    setOsp,
    sentiment,
    setSentiment,
    date,
    setDate,
  } = useTotalAmountFilterContext();
  const [cpMenus, setCpMenus] = useState<string[]>([]);
  const [movieMenus, setMovieMenus] = useState<string[]>([]);
  const [keywordMenus, setKeywordMenus] = useState<string[]>([]);
  const [typeMenus, setTypeMenus] = useState<string[]>([]);

  const filterList: FilterList = {
    1: [
      {
        selectTitle: '저작권사',
        type: 'several',
        several: [
          {
            severalType: 'select',
            placeholder: 'CP사',
            options: cpMenus,
            value: cp || '',
            label: 'CP사',
            setState: (target: string) => {
              if (target === 'CP사') {
                setCp('');
                setMovie('');
                setKeyword('');
                setMovieMenus([]);
                setKeywordMenus([]);
                return;
              }
              setCp(target);
              setMovie('');
              setKeyword('');
              setMovieMenus([]);
              setKeywordMenus([]);
              useMovieDropdown({ target: target, setState: setMovieMenus });
            },
          },
          {
            severalType: 'select',
            placeholder: '영화 제목',
            options: movieMenus,
            value: movie || '',
            label: '영화 제목',
            setState: (target: string) => {
              if (target === '영화 제목') {
                setMovie('');
                setKeyword('');
                setKeywordMenus([]);
                return;
              }
              setMovie(target);
              setKeyword('');
              setKeywordMenus([]);
              useKeywordDropdown({ target: target, setState: setKeywordMenus });
            },
          },
          {
            severalType: 'select',
            placeholder: '필수 키워드',
            label: '필수 키워드',
            options: keywordMenus,
            value: keyword || '',
            setState: (target: string) => {
              if (target === '필수 키워드') {
                setKeyword('');
                return;
              }
              setKeyword(target);
            },
          },
        ],
      },
    ],
    2: [
      {
        selectTitle: '플랫폼',
        type: 'several',
        several: [
          {
            severalType: 'select',
            placeholder: '플랫폼',
            options: ['플랫폼', '포털', 'SNS', '커뮤니티', 'NEWS'],
            value: platform || '',
            label: '플랫폼',
            setState: (target: string) => {
              if (target === '플랫폼') {
                setPlatform('');
                setOsp('');
                setTypeMenus([]);
                return;
              }
              setPlatform(target);
              setTypeMenus([]);
              useOspDropdown({ target: target, setState: setTypeMenus });
            },
          },
          {
            severalType: 'select',
            placeholder: '종류',
            options: typeMenus,
            value: osp || '',
            label: '종류',
            setState: (target: string) => {
              if (target === 'OSP선택') {
                setOsp('');
                return;
              }
              setOsp(target);
            },
          },
        ],
      },
    ],
    3: [
      {
        selectTitle: '필터링',
        type: 'several',
        label: '날짜선택',
        several: [
          {
            severalType: 'calendar',
            setState: setDate,
            label: '분류',
          },
          {
            severalType: 'select',
            placeholder: '분류',
            options: ['분류', '긍정글', '중립글', '부정글'],
            value: sentiment || '',
            label: '분류',
            setState: (target: string) => {
              if (target === '분류') {
                setSentiment('');
                return;
              }
              useConvertSentiment({ target, setSentiment });
            },
          },
        ],
      },
    ],
  };

  const searchBtn = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: 'drop-shadow-pink',
    },
    content: '검색',
    color: {
      text: 'text-white',
      bg: 'bg-[#FF9016]',
    },
    handleClick: () => handleSearch(),
  };

  const handleSearch = () => {
    console.log('clicked');
    refetch();
  };

  const styles = {
    SECTION_WIDTH: ['w-30%', 'w-30%', 'w-30%'],
    FONTSIZE: 'text-18',
  };

  useEffect(() => {
    useCpDropdown({ setState: setCpMenus });
  }, []);

  return (
    <div className="bg-[white] w-full min-w-[1600px] rounded-14 p-30 shadow-box">
      <p className="flex items-center gap-6 text-20 font-bold">
        <TotalSearch />
        검색
      </p>

      <div className="flex mt-25">
        <Filters filterList={filterList} styles={styles} />
      </div>

      {/* <PillBtn pillBtnProps={resetBtn} /> */}
      <div className="w-full flex justify-center mt-35">
        <PillBtn pillBtnProps={searchBtn} />
      </div>
    </div>
  );
};

export default FilterContainer;
