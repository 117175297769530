import { useEffect, useState } from 'react';

import { reformDataForChart } from 'utilities/Medium/Facebook/reformDataForChart';
import { reformDataForGraph } from 'utilities/Medium/Facebook/reformDataForGraph';
import { decodeKor } from 'utilities/Medium/decodeKor';

import FacebookChart from 'components/Medium/Facebook/FacebookChart';
import GraphContainer from 'components/Medium/Facebook/GraphContainer';
import { MediaCount } from 'components/Medium/MediaCount';
import MediaInfoBox from 'components/Medium/MediaInfoBox';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import useGetApi from 'hooks/apis/useGetApi';

import { FacebookCount } from 'types/Medium/facebook';

import { ReactComponent as Comments } from 'assets/svg/Comments.svg';
import { ReactComponent as FacebookSVG } from 'assets/svg/Facebook.svg';
import { ReactComponent as Hearts } from 'assets/svg/Hearts.svg';
import { ReactComponent as MediumIcon } from 'assets/svg/TVIcon.svg';
import { ReactComponent as Views } from 'assets/svg/Views.svg';

const Facebook = () => {
  const [count, setCount] = useState<FacebookCount>();
  const movie = decodeKor();
  const { refetch, data } = useGetApi('facebook', `/movie-medium-analysis/facebook/${movie}`);
  const dateFacebookList = data && data.dateFacebookList;
  let mediaData = data && data.totalCount;
  const chartData = reformDataForChart(dateFacebookList);
  const graphData = reformDataForGraph(dateFacebookList);

  useEffect(() => {
    refetch();

    if (data && dateFacebookList) {
      setCount({
        viewCount:
          dateFacebookList[0] && dateFacebookList[0].viewCount
            ? dateFacebookList[0].viewCount.toLocaleString()
            : '-',
        commentCount: dateFacebookList[0] ? dateFacebookList[0].commentCount.toLocaleString() : '-',
        likeCount: dateFacebookList[0] ? dateFacebookList[0].likeCount.toLocaleString() : '-',
      });
    }
  }, [movie, data]);

  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="영화 매체 분석"
          path2="CGV"
          Icon={MediumIcon}
          title="영화 매체 분석"
        />
      </div>
      <section className="flex flex-col gap-50">
        <div className="flex gap-14 mobile:flex-col">
          <MediaInfoBox
            Icon={FacebookSVG}
            content="페이스북"
            isTodayData="no"
            mediaData={mediaData}
          />
          <div className="flex items-center w-full max-w-[460px] h-[166px] bg-white rounded-14 shadow-medium">
            <MediaCount content="조회수" value={count?.viewCount!} Icon={Views} />
            <MediaCount content="댓글" value={count?.commentCount!} Icon={Comments} />
            <MediaCount content="좋아요" value={count?.likeCount!} Icon={Hearts} />
          </div>
        </div>
        <div className="flex flex-col gap-20 w-full h-[400px] bg-white rounded-14 shadow-medium p-24">
          <p className="text-15 text-[#222222] font-semibold leading-18">시간별 접속자 수</p>
          <FacebookChart data={chartData} />
        </div>
        <GraphContainer data={graphData} />
      </section>
    </>
  );
};

export default Facebook;
