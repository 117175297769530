import { useState } from 'react';

import { ModalListSeveral, ModalListType } from 'types/Management/mangementType';

import { ReactComponent as CheckSquare } from 'assets/svg/CheckSquare.svg';

interface Prop {
  data: ModalListType | ModalListSeveral;
}

const Checkbox = ({ data }: Prop) => {
  const [isClicked, setIsClicked] = useState<string>();
  const handleClick = (clickedItem: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    setIsClicked(clickedItem.currentTarget.innerText);
    data.setState(clickedItem.currentTarget.innerText);
  };

  return (
    <div className="flex flex-col gap-16">
      <p className="text-18">{data.title}</p>
      <div className="flex mt-[-4px] w-full">
        {data.options?.map((item, idx) => (
          <label
            key={idx}
            onClick={(e) => handleClick(e)}
            className="flex items-center cursor-pointer mr-18"
          >
            <CheckSquare
              className={`${
                isClicked !== item ? 'stroke-light-gray2 fill-light-gray2' : 'stroke-logo fill-logo'
              } mr-[0.31vw]`}
            />
            <input className="appearance-none" type="checkbox" />
            {item}
          </label>
        ))}
      </div>
    </div>
  );
};

export default Checkbox;
