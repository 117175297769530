import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"

interface MovieIdx {
    movieIdx: number | undefined,
    setMovieIdx: Dispatch<SetStateAction<number | undefined>>
};

const MovieIdxContext = createContext<MovieIdx>({
    movieIdx: 0,
    setMovieIdx: () => {
        return;
    }
});

export const MovieIdxProvider = ({ children } : PropsWithChildren)  => {
    const [movieIdx, setMovieIdx] = useState<number>();

    const values = {
        movieIdx,
        setMovieIdx
    }

    return <MovieIdxContext.Provider value={values}>{children}</MovieIdxContext.Provider>
}

export const useMovieIdxContext = () => {
    return useContext(MovieIdxContext);
}