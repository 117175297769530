import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Bg from 'assets/image/LoginBg.png';
import MockUp from 'assets/image/MockUp.png';
import { ReactComponent as RegisterPrev } from '../../assets/svg/RegisterPrev.svg'
import SignInInputForm from 'components/common/SignUp/SignUpInputForm';
import usePostApi from 'hooks/apis/usePostApi';

interface Props {
    setForm : Dispatch<SetStateAction<boolean>>
}

const SignUp = ({ setForm } : Props) => {
    const [userId, setUserId] = useState<string>('');
    const [userPw, setUserPw] = useState<string>('');
    const [userPwConfirm, setUserPwConfirm] = useState<string>('');
    const [type, setType] = useState<string>('personal');
    const [company, setCompany] = useState<string>('');
    const [contact, setContact] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isMouseOn, setIsMouseOn] = useState<string>('');

    const [invalid, setInvalid] = useState<boolean>(false);

    const { mutate, data, isLoading, isError, error } = usePostApi('signup', '/member/signup', {
        id: userId,
        password: userPw,
        phoneNumber: contact,
        email: email,
        name: company,
        type: type
    }); 

    const clearBuf = () => {
        setUserId('');
        setUserPw('');
        setUserPwConfirm('');
        setType('personal');
        setCompany('');
        setContact('');
        setEmail('');
        setInvalid(false);
    }

    const isIdError = () => {
        if (userId.length === 0) {
            return false;
        }
        return 3 > userId.length || userId.length >= 10 || userId.includes(' ');
    }

    const isPwError = () => {
        if (userPw.length === 0) {
            return false;
        }
        return 1 > userPw.length || userPw.length >= 50;
    }

    const isPwConfError = () => {
        if (userPw.length === 0) {
            return false;
        }
        return userPw !== userPwConfirm;
    }

    const isContactError = () => {
        if (contact.length === 0) {
            return false;
        }
        return 1 > contact.length || contact.length > 20;
    }

    const isEmailError = () => {
        if (email.length === 0) {
            return false;
        }
        return email.includes(' ');
    }

    const registerForm = {
        common: {
          state: isMouseOn,
          setState: setIsMouseOn,
        },
        each: [
            {
              type: 'text',
              placeholder: '사용하실 이메일주소를 입력해주세요',
              setInput: setUserId,
              value: userId,
              label: '아이디',
              isEssential: true,
              errorMessage: "빈 값과 공백이 입력될 수 없고, 3글자 이상 10글자 미만이어야 합니다.",
              isError : () => {
                return isIdError();
              }
            },
            {
              type: 'password',
              placeholder: '10자 이상, 특수문자 또는 숫자 포함',
              setInput: setUserPw,
              value: userPw,
              label: '비밀번호',
              isEssential: true,
              errorMessage: "비밀번호는 최소 1글자 이상 50자 미만이어야 합니다",
              isError : () => {
                return isPwError();
              }
            },
            {
              type: 'password',
              placeholder: '비밀번호를 재입력해주세요',
              setInput: setUserPwConfirm,
              value: userPwConfirm,
              label: '비밀번호 확인',
              isEssential: true,
              errorMessage: "비밀번호가 일치하지 않습니다.",
              isError : () => {
                return isPwConfError();
              }
            },
            {
                type: 'select',
                placeholder: '',
                setInput: setType,
                value: type,
                label: '회원 유형',
                isEssential: true,
                errorMessage: "",
                isError : () => {
                    return false;
                }
            },
            {
                type: 'text',
                placeholder: '기업명 또는 성명을 입력해주세요',
                setInput: setCompany,
                value: company,
                label: '기업명 / 성명',
                isEssential: true,
                errorMessage: "",
                isError : () => {
                    return false;
                }
            },
            {
                type: 'text',
                placeholder: '연락처를 입력해주세요',
                setInput: setContact,
                value: contact,
                label: '연락처',
                isEssential: false,
                errorMessage: "글자 수가 1에서 20 사이여야 합니다.",
                isError : () => {
                    return isContactError();
                }
            },
            {
                type: 'text',
                placeholder: '이메일을 입력해주세요',
                setInput: setEmail,
                value: email,
                label: '이메일',
                isEssential: false,
                errorMessage: "빈 값과 공백이 들어올 수 없습니다.",
                isError : () => {
                    return isEmailError();
                }
            }
        ],
    };
    
    const checksum = () => {
        if (
            userId === "" || isIdError() 
            || userPw === "" || isPwError() || isPwConfError()
            || type === ""
            || company === ""
            || isContactError() 
            || isEmailError()
        ) {
            setInvalid(true);
            return false;
        }

        setInvalid(false);
        return true;
    }

    useEffect(() => {

    }, [isLoading, data, error]);    

    return (
        <section className="flex w-[100vw] h-[100vh] mobile:justify-center mobile:items-center">
            <div className="flex flex-col items-center w-1/2 py-50 box-border overflow-scroll mobile:w-full mobile:h-full">
                <div className='flex flex-col gap-28 mb-18'>
                    <div className='flex relative justify-center items-center w-[460px] mobile:w-[342px]'>
                        <div className='flex absolute justify-center items-center left-0 gap-10'>
                            <button 
                                className='flex justify-center items-center w-34 h-34 border-1 border-solid border-[#eaeaea] rounded-[90px] bg-[#fff] shadow-lg'
                                onClick={() => {
                                    clearBuf();
                                    setForm(true);
                                }}
                            >
                                <RegisterPrev/>
                            </button>
                            <span className='text-[#4F4F4F] text-13 font-bold'>이전페이지</span>
                        </div>
                        <span className='text-[#4F4F4F] text-32 font-bold'>회원가입</span>
                    </div>

                    <span className='text-[#4F4F4F] text-13 font-bold text-right mb-10 pb-25 border-b-1 border-[#EAEAEA]'>
                        <span className='text-[#FF8B00]'>* </span>
                        필수 기재사항입니다
                    </span>
                </div>

                <div className="flex flex-col justify-center items-center gap-20 text-dark-gray text-22 w-[460px] mobile:max-w-[343px]">
                    {registerForm.each.map((el, idx) => (

                        <SignInInputForm
                            key={idx}
                            type={el.type}
                            placeholder={el.placeholder}
                            label={el.label}
                            value={el.value}
                            state={registerForm.common.state}
                            setState={registerForm.common.setState}
                            setInput={el.setInput}
                            keyDown={(e) => {}}
                            isEssential={el.isEssential}
                            errorMessage={el.errorMessage}
                            isError={el.isError}
                        />
                    ))}
                    <button
                        className='flex justify-center items-center w-full h-50 p-14 mt-20 mb-28 bg-[#FF8B00] rounded-4 text-[#fff] text-15 font-bold'
                        onClick={() => {
                            if (!checksum()) {
                                alert("입력 사항을 다시 한 번 확인해주세요.");
                                return;
                            }
                            else {
                                if(!invalid) {
                                    mutate();
                                    if (!isError) {
                                        alert("회원가입 신청이 완료되었습니다.");
                                        clearBuf();
                                        setForm(true);
                                    }
                                    else {
                                        alert("오류가 발생했습니다. 다시 시도해주세요");
                                    }
                                }
                            }
                        }}
                    >
                        회원가입
                    </button>
                </div>

                <span className='text-[#4F4F4F] text-14 font-bold'>
                    접수가 되면 담당자가 빠르게 연락 드리겠습니다.
                </span>
            </div>

            <div
                className="relative flex items-center justify-center w-1/2 h-full bg-no-repeat bg-cover mobile:hidden"
                style={{ backgroundImage: `url(${Bg})` }}
            >
                <div className="absolute">
                <h1 className="font-bold text-center text-white text-40 mb-30">
                    영화의 데이터를 <span className="text-[#FEBA69]">Ai</span>로 담다!
                </h1>
                <img src={MockUp} alt="목업" />
                </div>
            </div>
        </section>
    )
}

export default SignUp;