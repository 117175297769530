import { Dispatch, SetStateAction, createContext } from 'react';

import { PageState, SubPageState } from 'types/Nav/menu';

interface PageStateContextType {
  pageState: PageState;
  setPageState: Dispatch<SetStateAction<PageState>>;
  subPageState: SubPageState | undefined;
  setSubPageState: Dispatch<SetStateAction<SubPageState | undefined>>;
}

export const PageStateContext = createContext<PageStateContextType>({
  pageState: 'dashboard',
  setPageState: () => {
    return;
  },
  subPageState: undefined,
  setSubPageState: () => {
    return;
  },
});
