import { ParsingForm } from 'types/Buzz/BuzzType';

export const useAddDateAndColor = (dayOfDates: string[], result: ParsingForm): ParsingForm => {
  dayOfDates.map((date) => {
    result = {
      ...result,
      [date]: {
        date: `${date.split('-')[1]}.${date.split('-')[2]}`,
        NEWSColor: '#9747FF',
        커뮤니티Color: '#F28C2E',
        SNSColor: '#FFC700',
        포털Color: '#00BC86',
      },
    };
  });

  return result;
};
