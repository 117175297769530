import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import CustomCellComponent from 'components/Management/Platform/CustomCellComponent';
import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';
import { Paging } from 'components/common/Table/Paging';

import useGetApi from 'hooks/apis/useGetApi';

interface TableContainerProps {
  currentPage: number,
  onChangePage: Dispatch<SetStateAction<number>>,
  isLoading: boolean,
  data? : any
}

const TableContainer = ({ onChangePage, currentPage, data, isLoading } : TableContainerProps) => {
  const limit = 10;

  // const { data, isLoading, refetch } = useGetApi(
  //   'platform-for-table-data',
  //   `/osp-list?${platformVal !== "" ? "platfrom=" + platformVal : ""}&page=${currentPage}&pageSize=${limit}`,
  // );

  const columns: LabeledColumnProps[] = [
    { 
      key: 'no', 
      label: 'no.', 
      flexGrow: 1 
    },
    { 
      key: '플랫폼', 
      label: '플랫폼', 
      flexGrow: 1 
    },
    { 
      key: '분류', 
      label: '분류', 
      flexGrow: 1 
    },
    { 
      key: 'URL', 
      label: 'URL', 
      flexGrow: 2.5 
    },
    { 
      key: '최종 수집 날짜', 
      label: '최종 수집 날짜', 
      flexGrow: 2 
    },
    { 
      key: '상태', 
      label: '상태', 
      flexGrow: 1, 
      isCustomCell: true 
    }
  ];

  const mappedData = data?.content.map((item: any) => ({
    no: item.id,
    플랫폼: item.osp,
    분류: item.platform,
    URL: item.ospUrl,
    '최종 수집 날짜': item.ospEndDate.replace('T', ' '),
    상태: item.ospState === '1' ? '정상' : '미작동',
  }));

  useEffect(() => {

  }, [data, currentPage])

  return (
    <>
      <div className="flex flex-col items-center gap-[20px] self-stretch">
        <div className="flex items-center self-stretch">
          <div className="flex items-center gap-[10px]">
            <p className="text-[#222] font-pretendard text-[20px] font-bold leading-[1.5]">
              전체 데이터
            </p>
            <p className="text-[#797372] font-poppins text-[20px] font-bold leading-normal">
              {data && data.totalElements}건
            </p>
          </div>
        </div>
        <>
          <div className="w-full">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <DataTable columns={columns} datas={mappedData} rowKey="no">
                <CustomCellComponent key="상태" />
              </DataTable>
            )}
          </div>
          <div>
            <Paging
              total={data?.totalElements}
              limit={limit}
              activePage={currentPage}
              onChangePage={onChangePage}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default TableContainer;
