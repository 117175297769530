interface CountPercentageProps {
  count: string | undefined;
  height?: string;
}

export const CountPercentage = ({ count, height = 'h-[28px]' }: CountPercentageProps) => {
  return (
    <div className={`flex justify-center items-center w-[89px] ${height} bg-light-gray rounded-12`}>
      <span className="text-main">
        {count && count !== '0.0' && count !== '0' ? `${count}%` : '-'}
      </span>
    </div>
  );
};
