import { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { CompareChartData } from "types/Comparison/ChartData";

import { useComparisonDataContext } from "contexts/comparison/ComparisonDataContext";

interface Props {
    firstMovieData : CompareChartData,
    secondMovieData : CompareChartData,
    rootName: string
}

const CompareGraph = ({ firstMovieData, secondMovieData, rootName } : Props) => {
    const { movieData1, movieData2 } = useComparisonDataContext();

    const movieDataList = [firstMovieData, secondMovieData];
    const colorList = [am5.color('#77D6DB'), am5.color('#1674FF')];

    useLayoutEffect(() => {
      let root = am5.Root.new(rootName);
  
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"]
      });

      root.interfaceColors.set("text", am5.color(0x6d7683));
  
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          wheelX: "panX",
          wheelY: "zoomX"
        })
      );
  
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            renderer: am5xy.AxisRendererY.new(root, {})
        })
      );

      yAxis.get('renderer').labels.template.setAll({
        forceHidden: true, //Hide labels
      });

      var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        forceHidden: true,
      });

      // Hide X-axis grid lines
      xRenderer.grid.template.setAll({
        strokeOpacity: 0,
        disabled: true,
      });
  
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: 'movieName',
          renderer: xRenderer,
        }),
      );
  
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Series 1',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          sequencedInterpolation: true,
          categoryXField: 'movieName',
        }),
      );

      series.columns.template.setup = function (target) {
        var tooltip = am5.Tooltip.new(root, {

        });
    
        tooltip.get('background')?.set('strokeOpacity', 0);
    
        target.set('tooltip', tooltip);
      };

      series.columns.template.adapters.add('fill', function (fill, target) {
        return colorList[series.columns.indexOf(target) % colorList.length];
      });

      series.columns.template.setAll({
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        maxWidth: 25,
        strokeOpacity: 0,
        width: am5.percent(30),
        tooltipY: 0,
        tooltipText: '{movieName}\n{value}',
        showTooltipOn: 'always',
      });

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
            locationY: 1,
            sprite: am5.Label.new(root, {
                centerY: am5.p50,
                text: "{valueX}",
                populateText: true
            })
        })
      });

      chart.set("cursor", am5xy.XYCursor.new(root, {}));

      xAxis.data.setAll(movieDataList);
      series.data.setAll(movieDataList);
      
      series.appear(1000, 100);
      chart.appear(1000, 100);
  
      return () => {
        root.dispose();
      };
    }, [movieData1, movieData2]);
  
    return (
      <div id={rootName} style={{ width: "250px", height: "183px" }}></div>
    );
}

export default CompareGraph;