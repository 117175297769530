import { ChangeEvent } from 'react';

import { ModalListSeveral, ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType | ModalListSeveral;
}

const Input = ({ data }: Prop) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    data.setState(e.target.value);
  };

  return (
    <div className="flex flex-col gap-16">
      <p className="text-18">{data.title}</p>
      <input
        readOnly={data.readonly && true}
        value={data.value && data.value}
        onChange={(e) => handleChange(e)}
        className="w-full px-12 outline-none border-1 border-light-gray2 h-38 rounded-8 placeholder:text-light-gray2"
        placeholder={data.placeholder}
      />
    </div>
  );
};

export default Input;
