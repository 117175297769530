import { TopPicksCardProps } from 'types/Subscription/TopPicksCard';

interface Props {
  data: TopPicksCardProps | null;
}

const TopPicksCard = ({ data }: Props) => {
  const getGradeClassName = () => {
    if (data?.grade && Number(data?.grade) <= 3) {
      return 'bg-gradient-to-r from-[#D68F41] to-[#FB6714]';
    }
    return 'bg-black';
  };

  return (
    <div className="relative">
      <img src={data?.imageUrl} alt="포스터" />
      <div
        className={`absolute top-0 left-0 flex items-center justify-center w-40 h-40 text-center text-white text-11 bg-black ${getGradeClassName()}`}
      >
        {data?.grade}위
      </div>
      <div className="absolute bottom-0 text-center bg-black/60 w-full py-13 backdrop-blur-[5px] font-bold">
        <p className="overflow-hidden text-white text-ellipsis whitespace-nowrap text-13">
          {data?.title}
        </p>
        <p className="mt-4 text-white text-10">
          개봉일<span className="font-normal">&nbsp;{data?.openDate}</span>
        </p>
      </div>
    </div>
  );
};

export default TopPicksCard;
