import PillBtn from 'components/common/Button/PillBtn';
import { addComma } from 'components/common/addAndRemoveComma';

import { useBuzzAmountFilterContext } from 'contexts/buzzAmount/BuzzAmountContext';

import { convertMonth } from 'hooks/useConvertDate';
import useConvertDateWithoutHours from 'hooks/useConvertDateWithoutHours';
import useDownloadExcel from 'hooks/useDownloadExcel';

import { ReactComponent as Excel } from 'assets/svg/Excel.svg';

const ExcelContainer = () => {
  const { totalElements, cp, movie, keyword, platform, osp, sentiment, date } =
    useBuzzAmountFilterContext();

  const downloadExcel = async () => {
    const startDate =
      date && useConvertDateWithoutHours({ start: date[0], end: date[1] }).startDate;
    const endDate = date && useConvertDateWithoutHours({ start: date[0], end: date[1] }).endDate;
    const TODAY = `${String(new Date()).split(' ')[3]}-${convertMonth(
      String(new Date()).split(' ')[1] || '',
    )}-${String(new Date()).split(' ')[2]}`;

    const url = `/movie-buzz-total-data/download?${
      date ? `startDate=${startDate}` : 'startDate=2000-01-01'
    }${date ? `&endDate=${endDate}` : `&endDate=${TODAY}`}${cp ? `&cp=${cp}` : ''}${
      movie ? `&movie=${movie}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}${platform ? `&platform=${platform}` : ''}${
      osp ? `&osp=${osp}` : ''
    }${sentiment ? `&sentiment=${sentiment}` : ''}`;

    try {
      useDownloadExcel({ url });
    } catch (e) {}
  };

  const excelBtn = {
    width: 'w-136',
    height: 'h-44 ',
    shadow: {
      isShadow: false,
      color: 'drop-shadow-excel',
    },
    content: '엑셀 다운로드',
    color: {
      text: 'text-black',
      bg: 'bg-white',
    },
    svg: <Excel />,
    border: 'border-1',
    handleClick: () => {
      window.confirm('엑셀을 다운로드 하시겠습니까?') && downloadExcel();
    },
  };

  return (
    <div className="flex items-end justify-between w-full mb-16 mt-50 mobile:hidden">
      <div className="font-bold text-center text-20">
        전체 데이터 <span className="text-[#797372]"> &nbsp;{addComma(totalElements)}건</span>
      </div>
      <PillBtn pillBtnProps={excelBtn} />
    </div>
  );
};

export default ExcelContainer;
