import { useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { PageStateContext } from 'contexts/NavbarContext';

import { Kor2eng } from 'types/Nav/menu';

import './Animation.css';

interface Props {
  page: Kor2eng;
  hasClickMenu: boolean;
}

const SubMenu = ({ page, hasClickMenu }: Props) => {
  const { subPageState, setSubPageState } = useContext(PageStateContext);
  const navigate = useNavigate();
  const { content, subMenu } = page;

  return (
    <div
      className={`overflow-hidden px-22 h-auto text-gray2 text-14 ${
        hasClickMenu ? 'max-h-auto fade-in' : 'max-h-0 fade-out'
      }`}
    >
      <div className={`flex flex-col gap-4 fade-in`}>
        {subMenu &&
          subMenu.map((submenu, idx) => (
            <button
              onClick={() => {
                setSubPageState(submenu.path);
                navigate(`/${content}/${submenu.path}`);
              }}
              key={idx}
              className={`flex leading-22 ${subPageState === submenu.path && 'text-logo'}`}
            >
              <span className={`mr-8 ${subPageState === submenu.path && 'text-logo'}`}>･</span>
              {submenu.title}
            </button>
          ))}
      </div>
    </div>
  );
};

export default SubMenu;
