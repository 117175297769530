import { ForwardedRef, forwardRef } from 'react';

import ModalOspDetail from 'components/common/Modal/ModalOspDetail';
import ModalTitle from 'components/common/Modal/ModalTitle';

import { ModalListType } from 'types/Management/mangementType';

import arrowLeft from 'assets/svg/CaretLeft.svg';
import X from 'assets/svg/X.svg';

interface ModalProps {
  data: { [key: string]: string };
  close: () => void;
}

const Modal = forwardRef(({ data, close }: ModalProps, ref: ForwardedRef<HTMLDialogElement>) => {
  const modalList: ModalListType[] = [
    {
      type: 'cp',
      contents: [data['cpId'], data['ospType'], data['movieTitle']],
    },
    {
      type: 'keyword',
      content: data['keyword'],
      sentiments: data['sentimentType'],
    },
    {
      type: 'normal',
      title: '작성자',
      content: data['writer'],
      isDate: 'not',
    },
    {
      type: 'normal',
      title: '작성일자',
      content: data['writeDate'],
      isDate: 'date',
    },
    {
      type: 'normal',
      title: '수집날짜',
      content: data['createdAt'],
      isDate: 'date',
    },
    {
      type: 'normal',
      title: '게시글 제목',
      content: data['contentsTitle'],
      isDate: 'not',
    },
    {
      type: 'normal',
      title: '게시글 URL',
      content: data['url'],
      isDate: 'not',
    },
    {
      type: 'textarea',
      title: '게시글 내용',
      content: data['contents'],
    },
  ];

  return (
    <dialog
      ref={ref}
      className={`w-[1058px] px-0 pt-12 pb-30 rounded-12 outline-none mobile:w-[390px] mobile:h-full mobile:mt-0 mobile:rounded-0`}
    >
      <div className="mobile:hidden">
        <div className="flex justify-end w-full pr-12">
          <img onClick={close} src={X} className="cursor-pointer" />
        </div>
        <ModalTitle title="상세 페이지" />
        <div className="flex flex-col justify-between">
          <div className="px-24 pb-10">
            {modalList.map((el: ModalListType, idx: number) => (
              <ModalOspDetail key={idx} data={el} />
            ))}
          </div>
        </div>
      </div>
      <div className={`hidden mobile:flex mobile:flex-col`}>
        <div className="relative flex items-center justify-center gap-0 h-52 border-b-1 border-light-gray">
          <img
            onClick={close}
            className="absolute rotate-90 cursor-pointer left-24"
            src={arrowLeft}
            alt="arrowLeft"
          />
          <ModalTitle title="상세 페이지" />
        </div>
        <div className="px-24">
          {modalList.map((el: ModalListType, idx: number) => (
            <ModalOspDetail key={idx} data={el} />
          ))}
        </div>
      </div>
    </dialog>
  );
});

export default Modal;
