import { useEffect, useState } from 'react';

import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { SearchFilterContainer } from 'components/Data/BuzzReport/SearchFilterContainer';
import PillBtn from 'components/common/Button/PillBtn';
import { PdfDownloadBtn } from 'components/common/Report/PdfDownloadBtn';
import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';
import { Paging } from 'components/common/Table/Paging';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { customedAxios } from 'hooks/axiosApis/customedAxios';
import { useGetIsAdmin } from 'hooks/axiosApis/useGetIsAdmin';

import { ReactComponent as Globe } from 'assets/svg/Globe.svg';
import { ReactComponent as Pencil } from 'assets/svg/Pencil.svg';
import { ReactComponent as Plus } from 'assets/svg/PlusCircleBlack.svg';
import { ReactComponent as ReportIcon } from 'assets/svg/ReportIcon.svg';
import { ReactComponent as Trash } from 'assets/svg/Trash.svg';

interface TableData {
  reportIdx: number;
  createdAt: string;
  cpId: string;
  movieTitle: string;
  reportTitle: string;
  reportManager: string;
  reportPdfName: string;
  [key: string]: string | number | undefined;
}

const btnProps = {
  web: {
    width: 'w-85',
    height: 'h-30',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: 'WEB',
    color: {
      text: 'text-white',
      bg: 'bg-positive',
    },
    svg: <Globe />,
  },
  modify: {
    width: 'w-85',
    height: 'h-30',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '수정',
    color: {
      text: 'text-white',
      bg: 'bg-main',
    },
    svg: <Pencil />,
  },
  delete: {
    width: 'w-85',
    height: 'h-30',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '삭제',
    color: {
      text: 'text-white',
      bg: 'bg-alert',
    },
    svg: <Trash />,
  },
};

export const Report = () => {
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState<TableData[] | undefined>();
  const [selectedCP, setSelectedCp] = useState('');
  const [selectedMovie, setSelectedMovie] = useState('');
  const [dateRange, setDateRange] = useState<{ startDate: string; endDate: string } | undefined>(
    undefined,
  );

  const setStateActions = { setSelectedCp, setSelectedMovie, setDateRange };

  const columns: LabeledColumnProps[] = [
    {
      key: 'createdAt',
      label: '날짜',
      flexGrow: 0.5,
    },
    {
      key: 'cpId',
      label: 'CP사',
      flexGrow: 0.3,
      align: 'left',
    },
    {
      key: 'movieTitle',
      label: '영화',
      flexGrow: 0.7,
      align: 'left',
    },
    {
      key: 'reportTitle',
      label: '제목',
      flexGrow: 1.2,
      align: 'left',
      handleClick: (id) => navigate('/report/report-detail?id=' + id),
    },
    {
      key: 'reportManager',
      label: '담당자',
      flexGrow: 0.6,
    },
    {
      key: 'reportPdfName',
      label: ' ',
      flexGrow: 0.25,
      isCustomCell: true,
    },
    {
      key: 'webBtn',
      label: ' ',
      flexGrow: 0.25,
      isCustomCell: true,
      handleClick: (id) => navigate('/report/report-detail?id=' + id),
    },
    {
      key: 'modifyBtn',
      label: ' ',
      flexGrow: 0.25,
      isCustomCell: true,
      handleClick: (id) => navigate('/report/report-detail?id=' + id),
    },
    {
      key: 'deleteBtn',
      label: ' ',
      flexGrow: 0.25,
      isCustomCell: true,
      handleClick: (id) => {
        confirm('보고서를 삭제하시겠습니까?') && deleteReport(id);
      },
    },
  ];

  const deleteReport = async (id: string) => {
    try {
      await customedAxios.patch('/report/' + id);

      alert('보고서를 삭제했습니다.');
      fetchReportList();
    } catch (e) {
      alert('보고서 삭제에 실패했습니다.');
      // console.error(e);
    }
  };

  const fetchReportList = async (cp?: string) => {
    if (selectedCP || cp || isAdmin) {
      try {
        const params = {
          reportType: 'buzz_report',
          cpId: cp ? cp : selectedCP,
          movieTitle: selectedMovie,
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
          page: page,
          pageSize: 10,
        };

        const queryString = Object.entries(params)
          .filter((param) => param[1] !== undefined && param[1] !== '')
          .map((param) => `${param[0]}=${param[1]}`)
          .join('&');

        const { data } = await customedAxios.get('/report/list?' + queryString);

        setTableData(data.content ? cloneDeep(data.content) : []);
        setTotalCount(data.totalElements ? data.totalElements : 0);
      } catch (e) {
        alert('데이터를 불러오는 데에 실패했습니다.');
        // console.error(e);
      }
    }
  };

  const yellowReportBtn = {
    width: 'w-125',
    height: 'h-44',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '신규 보고서',
    color: {
      text: 'text-black',
      bg: 'bg-white',
    },
    svg: <Plus />,
    border: 'border-1',
    handleClick: () => navigate('/report/report-detail'),
  };

  useEffect(() => {
    useGetIsAdmin().then((data) => {
      setIsAdmin(data);
    });
  }, []);

  useEffect(() => {
    fetchReportList();
  }, [page, isAdmin]);

  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle path1="리포트 센터" Icon={ReportIcon} title="리포트 센터" />
      </div>
      <div className="overflow-x-auto">
        <div className="flex">
          <SearchFilterContainer
            setStateActions={setStateActions}
            onClickSearchBtn={fetchReportList}
          />
        </div>

        <div className="flex justify-end mb-20 mt-50">
          <PillBtn pillBtnProps={yellowReportBtn} />
        </div>

        <div className="min-w-[1600px]">
          <DataTable datas={tableData ?? []} columns={columns} rowKey="reportIdx">
            <PdfDownloadBtn key={'reportPdfName'} page="buzz" text="" />
            <div key={'webBtn'}>
              <PillBtn pillBtnProps={btnProps.web}></PillBtn>
            </div>
            <div key={'modifyBtn'}>
              <PillBtn pillBtnProps={btnProps.modify}></PillBtn>
            </div>
            <div key={'deleteBtn'}>
              <PillBtn pillBtnProps={btnProps.delete}></PillBtn>
            </div>
          </DataTable>
        </div>

        <Paging total={totalCount} limit={10} activePage={page} onChangePage={setPage}></Paging>
      </div>
    </>
  );
};

export default Report;
