import { Dispatch, KeyboardEvent, SetStateAction } from 'react';

interface Props {
  type: string;
  state: string;
  setState: Dispatch<SetStateAction<string>>;
  placeholder: string;
  setInput: Dispatch<SetStateAction<string | undefined>>;
  keyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  label: string;
}

const LoginInputForm = ({
  type,
  state,
  setState,
  placeholder,
  setInput,
  keyDown,
  label,
}: Props) => {
  return (
    <div className="flex flex-col w-full max-w-[460px]">
      <label className="text-14 text-[#6D7683] mb-10">{label}</label>
      <input
        type={type}
        onChange={(target) => setInput(target.currentTarget.value)}
        onFocus={() => setState(type)}
        onBlur={() => setState('')}
        onKeyDown={(e) => keyDown && keyDown(e)}
        placeholder={placeholder}
        className={`text-14 text-[#A4ADBB] focus:outline-[#EE8B1D] h-50 rounded-4 border-1 border-[#EAEAEA] pl-14 font-normal mobile:min-w-[342px] mobile:h-[42px] mobile:text-14 mobile:p-24`}
        autoComplete="off"
      />
    </div>
  );
};

export default LoginInputForm;
