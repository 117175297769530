import { Dispatch, SetStateAction } from 'react';

import { PageState, SubPageState } from 'types/Nav/menu';

type SetPage = Dispatch<SetStateAction<PageState>>;
type SetSubPage = Dispatch<SetStateAction<SubPageState | undefined>>;

export const initializeNavBarState = (setPageState: SetPage, setSubPageState: SetSubPage) => {
  const [, base, sub] = document.location.pathname.split('/');

  if (!base) {
    setPageState('dashboard');
  } else if (base) {
    setPageState(base as PageState);

    if (sub) {
      setSubPageState(sub as SubPageState);
    }
  }
};
