import Smiley from 'assets/svg/Smiley.svg';
import SmileyMeh from 'assets/svg/SmileyMeh.svg';
import SmileySad from 'assets/svg/SmileySad.svg';

const emotionColor = {
  positive: 'bg-positive',
  negative: 'bg-alert',
  neutral: 'bg-main',
};

export interface EmotionImageProps {
  type: keyof typeof emotionColor;
  style?: string;
}

export default function EmotionImage({ type, style }: EmotionImageProps) {
  return (
    <div
      className={`mobile:w-24 mobile:h-24 flex items-center justify-center w-[48px] h-[48px] ${style} rounded-18 ${emotionColor[type]}`}
    >
      <img
        src={type === 'positive' ? Smiley : type === 'neutral' ? SmileyMeh : SmileySad}
        alt="Emotion"
        className="mobile:w-18 mobile:h-18"
      ></img>
    </div>
  );
}
