import FilterIcon from 'assets/svg/Funnel.svg';

interface FilterTitleProps {
  title: string;
}

export const FilterTitle = ({ title }: FilterTitleProps) => {
  return (
    <div className="flex items-center pl-30 mobile:mt-31 mobile:mb-0 ">
      <img className="relative mr-6" src={FilterIcon} alt="filter" />
      <span className="font-bold text-20 mobile:text-20">{title}</span>
    </div>
  );
};
