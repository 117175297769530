interface StatusTagProps {
    text?: "결제완료" | "취소" | ""
}

const StatusTag = ({ text } : StatusTagProps) => {
    const handleStyle = () => {
        switch (text) {
            case "결제완료":
                return " bg-[rgba(0,193,124,0.10)] text-[#00C17C]";

            case "취소":
                return " bg-[rgba(255,78,72,0.10)] text-[#FF4E48]";
                
            default:
                return "";
        }
    }

    return (
        <div className={"flex justify-center items-center max-w-68 h-30 px-8 py-6 rounded-4 font-semibold" + handleStyle()}>
            {text}
        </div>
    )
}

export default StatusTag