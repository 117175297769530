import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import MyMovieToCard from 'utilities/Dashboard/MyMovieToCard';

import DashboardCard from 'components/Dashboard/DashboardCard';

import { getCookie } from 'hooks/cookie';

import { MyMovieProps } from 'types/Dashboard/MyMovieProps';
import { useNavigate } from 'react-router-dom';

const MyMovie = () => {
  const [width, setWidth] = useState<number>(0);
  const [slides, setSlides] = useState<number>(1.5);
  const navigate = useNavigate();

  const cookie = getCookie('loginToken');

  const client = axios.create({ baseURL: 'https://web-movieboard.com/api' });

  const request = ({ ...options }) => {
    client.defaults.headers.common.Authorization = `Bearer ${cookie}`;

    return client(options);
  };

  const getMyMovies = () =>
    request({
      url: '/movie/dashboard',
    });

  const getDatas = async () => {
    const res = await getMyMovies();

    const data = await res.data;

    return data;
  };

  const { data } = useQuery({
    queryKey: ['MyMovie'],
    queryFn: getDatas,
  });

  const handleResize = () => {
    setWidth(window.innerWidth);

    switch(true) {
      case width >= 1400 :
        setSlides(5);
        break;
      case width >= 1200 :
        setSlides(4);
        break;
      case width >= 1000 :
        setSlides(3);
        break;
      case width >= 850 :
        setSlides(2);
        break;
      default:
        setSlides(2);
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {window.removeEventListener('resize', handleResize)}
  }, [data, width]);

  return (
    <div className="flex flex-col w-full h-full gap-14">
      <span className="text-[#323842] text-[20px] font-bold">내 영화관리</span>
      <div className="flex flex-col w-full h-full gap-16 px-30 py-40 box-border border-1 border-[#EAEAEA] rounded-14 bg-white shadow-lg">
        {width >= 768 ?
          <div>
            <Swiper spaceBetween={20} slidesPerView={slides}>
              {data?.posterList.map((item: MyMovieProps, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <DashboardCard data={MyMovieToCard(item)} useOnClick={false}/>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
         :
            <div className='grid grid-cols-2 gap-x-10 gap-y-20 overflow-auto mobile:max-h-[550px]'>
              { data?.posterList.map((item: MyMovieProps, index: number) => {
                return (
                  <div 
                    key={index}
                    className='flex flex-col gap-10 cursor-pointer'
                    onClick={
                      () => {
                        navigate('/data', {state : {cp : item.cpId, movie: MyMovieToCard(item)?.movieTitle}});
                      }
                    }
                  >
                    <img 
                      src={(MyMovieToCard(item)?.imgPath?.indexOf("https") !== -1) ? MyMovieToCard(item)?.imgPath : "https://image.web-movieboard.com/movie_poster/" + MyMovieToCard(item)?.imgPath} 
                      alt="imgposter"
                      className='w-full aspect-[147/220]'
                    />
                    <span
                      className='text-[#222] text-15 font-bold hover:underline'
                    >
                      {MyMovieToCard(item)?.movieTitle}
                    </span>
                    <span
                      className='text-[#6D7683] text-13'
                    >
                      <span
                        className='text-13 font-bold'
                      >
                        개봉일 </span>{MyMovieToCard(item)?.openDate}
                    </span>
                  </div>
                );
              })}
            </div>
        }
      </div>
    </div>
  );
};

export default MyMovie;
