interface ModalTitleProp {
  title: string;
}

const ModalTitle = ({ title }: ModalTitleProp) => {
  return (
    <span className="flex justify-center font-bold text-28 mb-28 mobile:text-16 mobile:mb-0">
      {title}
    </span>
  );
};

export default ModalTitle;
