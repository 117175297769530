import { Tooltip } from 'react-tooltip';

interface Props {
    children : React.ReactNode;
    toolTipId : string;
    message : string;
    toolTipPosition: "right" | "right-start";
    arrowPosition?: boolean;
}

const CustomToolTip = ( { children, toolTipId, message, toolTipPosition, arrowPosition = false } : Props ) => {

    return (
        <div className={arrowPosition ? 
            `[&>.tooltip-content>.react-tooltip-arrow]:!w-20 [&>.tooltip-content>.react-tooltip-arrow]:!h-20 [&>.tooltip-content>.react-tooltip-arrow]:!left-[-5px] [&>.tooltip-content>.react-tooltip-arrow]:!top-[20px] [&>.tooltip-content]:rounded-15 [&>.tooltip-content]:text-14 [&>.tooltip-content]:font-normal [&>.tooltip-content>b]:font-extrabold [&>.tooltip-content]:translate-y-6` 
        : `[&>.tooltip-content>.react-tooltip-arrow]:!w-20 [&>.tooltip-content>.react-tooltip-arrow]:!h-20 [&>.tooltip-content>.react-tooltip-arrow]:!left-[-5px] [&>.tooltip-content>.react-tooltip-arrow]:!top-[20px] [&>.tooltip-content]:rounded-15 [&>.tooltip-content]:text-14 [&>.tooltip-content]:font-normal [&>.tooltip-content>b]:font-extrabold [&>.tooltip-content]:translate-y-14`}>
            <div data-tooltip-id={toolTipId} data-tooltip-html={message} data-tooltip-place={toolTipPosition}>
                {children}
            </div>
            <Tooltip 
                className='tooltip-content' 
                id={toolTipId} 
                offset={12} 
                opacity={0.75} 
                style={{
                    paddingRight: 25,
                    paddingLeft: 25,
                    paddingTop: 20,
                    paddingBottom: 20,
                    lineHeight: 1.2
                }}
            />
        </div>
    )
}

export default CustomToolTip;