import ComparisonTable from "./ComparisonTable";
import { ColumnProps } from "types/Comparison/ChartData";

import dateFormatter from "utilities/Comparison/DateFormatter";
import dataToCompareData from "utilities/Comparison/DataToCompareData";
import dataToCompareChartData from "utilities/Comparison/DataToCompareChartData";
import CompareGraph from "./CompareGraph";
import { MovieComparisonData } from "types/Comparison/Comparison";
import { useMovieDataContext } from "contexts/comparison/MovieDataContext";
import { useComparisonDataContext } from "contexts/comparison/ComparisonDataContext";
import { useSearchDataContext } from "contexts/comparison/SearchDataContext";

const MovieCompareSection = () => {
    const { movieDate1, movieDate2 } = useMovieDataContext();
    const { movieData1, movieData2 } = useComparisonDataContext();
    const { option, startDate, endDate } = useSearchDataContext();

    const { movieCompareChartData1 : news1, movieCompareChartData2 : news2 } = dataToCompareChartData({movieDate1, movieDate2, movieData1, movieData2}, "NEWS");
    const { movieCompareChartData1 : sns1, movieCompareChartData2 : sns2 } = dataToCompareChartData({movieDate1, movieDate2, movieData1, movieData2}, "SNS");
    const { movieCompareChartData1 : portal1, movieCompareChartData2 : portal2 } = dataToCompareChartData({movieDate1, movieDate2, movieData1, movieData2}, "포털");
    const { movieCompareChartData1 : comm1, movieCompareChartData2 : comm2 } = dataToCompareChartData({movieDate1, movieDate2, movieData1, movieData2}, "커뮤니티");

    const calcTotalBuzz = (data : MovieComparisonData) => {
        let totalBuzz = 0;
        const keyList = Object.keys(data.dateBuzzCountsMap);
        const len = keyList.length;

        for (let i = 0; i < len; i++) {
            totalBuzz += data.dateBuzzCountsMap[keyList[i]!]!;
        }

        return totalBuzz;
    }

    const calcTotalWatched = (data : MovieComparisonData) => {
        let max = 0;
        const keyList = Object.keys(data.dateWatchedCountsMap);
        const len = keyList.length;

        for (let i = 0; i < len; i++) {
            const val = data.dateWatchedCountsMap[keyList[i]!]!
            if (max < val) {
                max = val;
            }
        }

        return max;
    }

    const columnList : ColumnProps[] = [
        {
            key: "date",
            label: "날짜"
        },
        {
            key: "firstMovie",
            label: movieDate1.title,
            showPercent: true
        },
        {
            key: "secondMovie",
            label: movieDate2.title,
            showPercent: true
        }
    ];

    return (
        <div className="flex flex-col w-full gap-14">
            <span className='w-full text-[#222] text-20 font-bold leading-[30px]'>
            영화 비교
            </span>

            <div className="flex w-full gap-20 [@media(max-width:1200px)]:flex-col">
                <div className='flex flex-col w-full p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
                    <div className="flex justify-between w-full mb-60 pb-20 box-border border-b-1 border-solid border-[#EAEAEA]">
                        <span className='text-[#222] text-15 font-semibold'>관객수 비교</span>
                        <span className="text-[#797372] text-12">영화진흥위원회제공: {dateFormatter(new Date)}</span>
                    </div>

                    <div className="flex flex-col gap-50">
                        <div className="flex justify-center items-center w-full gap-30 mobile:flex-col">
                            <div className="flex justify-center items-center flex-col gap-10">
                                <span className="px-25 py-30 box-border rounded-5 bg-[rgba(119,214,219,0.10)] text-[#77D6DB] text-24 font-extrabold">
                                    {calcTotalWatched(movieData1).toLocaleString()}
                                </span>
                                <span className="text-[#6D7683] text-15 font-medium">{movieDate1.title}</span>
                            </div>
                            <span className="text-[#6D7683] text-15 font-medium">
                                VS
                            </span>
                            <div className="flex justify-center items-center flex-col gap-10">
                                <span className="px-25 py-30 box-border rounded-5 bg-[rgba(22,116,255,0.10)] text-[#1674FF] text-24 font-extrabold">
                                {calcTotalWatched(movieData2).toLocaleString()}
                                </span>
                                <span className="text-[#6D7683] text-15 font-medium">{movieDate2.title}</span>
                            </div>
                        </div>

                        <ComparisonTable columns={columnList} data={dataToCompareData({data1 : movieData1, data2 : movieData2, movieDate1 : movieDate1, movieDate2 : movieDate2, option: option, startDate: startDate, endDate: endDate}, "관객수")}/>
                    </div>
                </div>

                <div className='flex flex-col w-full p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
                    <div className="flex justify-between w-full mb-25 pb-20 box-border border-b-1 border-solid border-[#EAEAEA]">
                        <span className='text-[#222] text-15 font-semibold'>전체 버즈량 비교</span>
                        <span className="text-[#797372] text-12">무비보드 자체 수집량 비교</span>
                    </div>

                    <div>
                        <div className="flex justify-center items-center mb-20">
                            <CompareGraph 
                                firstMovieData={{
                                    movieName: movieDate1.title,
                                    value : calcTotalBuzz(movieData1)
                                }} 
                                secondMovieData={{
                                    movieName: movieDate2.title,
                                    value : calcTotalBuzz(movieData2)
                                }} 
                                rootName="comparisonchartdiv1"
                            />
                        </div>

                        <ComparisonTable columns={columnList} data={dataToCompareData({data1 : movieData1, data2 : movieData2, movieDate1 : movieDate1, movieDate2 : movieDate2, option: option, startDate: startDate, endDate: endDate}, "버즈량")}/>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full gap-20">
                <div className="flex w-full gap-20 mobile:flex-col [@media(max-width:1600px)]:flex-col">
                    <div className='flex flex-col w-full gap-40 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
                        <div className="flex justify-between w-full pb-20 box-border border-b-1 border-solid border-[#EAEAEA]">
                            <span className='text-[#222] text-15 font-semibold'>SNS</span>
                            <span className="text-[#797372] text-12">LG, YT, TW</span>
                        </div>

                        <div className="flex justify-center items-center">
                            <CompareGraph firstMovieData={news1} secondMovieData={news2} rootName="comparisonchartdiv2"/>
                        </div>
                    </div>

                    <div className='flex flex-col w-full gap-40 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
                        <div className="flex justify-between w-full pb-20 box-border border-b-1 border-solid border-[#EAEAEA]">
                            <span className='text-[#222] text-15 font-semibold'>포털</span>
                            <span className="text-[#797372] text-12">NAVER, DAUM</span>
                        </div>
                        
                        <div className="flex justify-center items-center">
                            <CompareGraph firstMovieData={sns1} secondMovieData={sns2} rootName="comparisonchartdiv3"/>
                        </div>
                    </div>

                    <div className='flex flex-col w-full gap-40 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
                        <div className="flex w-full">
                            <span className='w-full text-[#222] text-15 font-semibold pb-20 box-border border-b-1 border-solid border-[#EAEAEA]'>커뮤니티</span>
                        </div>
                        
                        <div className="flex justify-center items-center">
                            <CompareGraph firstMovieData={comm1} secondMovieData={comm2} rootName="comparisonchartdiv4"/>
                        </div>
                    </div>

                    <div className='flex flex-col w-full gap-40 p-24 box-border border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff]'>
                        <div className="flex justify-between w-full pb-20 box-border border-b-1 border-solid border-[#EAEAEA]">
                            <span className='text-[#222] text-15 font-semibold'>뉴스</span>
                            <span className="text-[#797372] text-12">포털NEWS</span>
                        </div>
                        
                        <div className="flex justify-center items-center">
                            <CompareGraph firstMovieData={portal1} secondMovieData={portal2} rootName="comparisonchartdiv5"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MovieCompareSection;