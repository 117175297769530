import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { MovieComparisonData } from "types/Comparison/Comparison";

interface MovieCompareDatas {
    movieData1 : MovieComparisonData,
    movieData2: MovieComparisonData,
    setMovieData1: Dispatch<SetStateAction<MovieComparisonData>>,
    setMovieData2: Dispatch<SetStateAction<MovieComparisonData>>
}

const ComparisonDataContext = createContext<MovieCompareDatas>({
    movieData1: {
        dateWatchedCountsMap: {},
        dateViralTotalsMap: {},
        dateBuzzCountsMap: {},
        platformBuzzCountsMap: {}
    },
    movieData2: {
        dateWatchedCountsMap: {},
        dateViralTotalsMap: {},
        dateBuzzCountsMap: {},
        platformBuzzCountsMap: {}
    },
    setMovieData1: () => {
        return;
    },
    setMovieData2: () => {
        return;
    }
});

export const ComparisonDataProvider = ({ children } : PropsWithChildren) => {
    const [movieData1, setMovieData1] = useState<MovieComparisonData>({
        dateWatchedCountsMap: {},
        dateViralTotalsMap: {},
        dateBuzzCountsMap: {},
        platformBuzzCountsMap: {}
    });
    const [movieData2, setMovieData2] = useState<MovieComparisonData>({
        dateWatchedCountsMap: {},
        dateViralTotalsMap: {},
        dateBuzzCountsMap: {},
        platformBuzzCountsMap: {}
    });

    const values = {
        movieData1,
        setMovieData1,
        movieData2,
        setMovieData2
    }

    return <ComparisonDataContext.Provider value={values}>{children}</ComparisonDataContext.Provider>;
}

export const useComparisonDataContext = () => {
    return useContext(ComparisonDataContext);
}