import { DashboardCardProps } from 'types/Dashboard/DashboardCardProps';
import { MovieDetailProps } from 'types/Dashboard/MovieDetailProps';

const MovieTop10ToCard = ( movieTop10 : MovieDetailProps ) => {
    let cardData : DashboardCardProps = {
        grade: 0,
        cp_id: "-",
        imgPath: "-",
        movieTitle: "-",
        openDate: "-"
    }

    cardData.grade = Number(movieTop10?.grade);
    cardData.cp_id = movieTop10?.cp_id;
    cardData.imgPath = movieTop10?.imageUrl;
    cardData.movieTitle = movieTop10?.title;
    cardData.openDate = movieTop10?.openDate;

    return cardData;
}

export default MovieTop10ToCard;