import { Dispatch, SetStateAction } from 'react';

import useGetApi from 'hooks/axiosApis/useGetApi';
import { useAddTableNum } from 'hooks/useAddTableNum';
import { useRemoveTFromDate } from 'hooks/useRemoveTFromDate';

import { GetDatas } from 'types/Management/mangementType';

interface Props {
  url: string;
  page: number;
  setParsedDatas: Dispatch<SetStateAction<GetDatas[]>>;
  setTotalElements: Dispatch<SetStateAction<number>>;
  setTotalData: Dispatch<SetStateAction<number>>;
  setPositive: Dispatch<SetStateAction<number>>;
  setNeutral: Dispatch<SetStateAction<number>>;
  setNegative: Dispatch<SetStateAction<number>>;
}

const useGetData = ({
  url,
  page,
  setParsedDatas,
  setTotalElements,
  setTotalData,
  setPositive,
  setNeutral,
  setNegative,
}: Props) => {
  try {
    const data = useGetApi(url);
    data.then((data) => {
      setParsedDatas([]);
      data.filteredList.content.map((el: GetDatas, idx: number) => {
        setParsedDatas((prev: GetDatas[]) => [
          ...prev,
          {
            ...el,
            no: useAddTableNum(data.filteredList.totalElements, page, idx),
            createdAt: useRemoveTFromDate(el['createdAt']),
          },
        ]);
      });
      setTotalElements(data.filteredList.totalElements);
      setPositive(data.sentimentCounts.positive);
      setNeutral(data.sentimentCounts.neutrality);
      setNegative(data.sentimentCounts.negative);
      setTotalData(data.totalCount);
    });
  } catch (error) {
    alert('필터 선택이 잘못되었습니다');
  }
};

export default useGetData;
