import React from 'react';

import Pagination from 'rsuite/Pagination';

import './Paging.less';

interface PagingProps {
  total: number;
  limit: number;
  activePage: number;
  onChangePage: React.Dispatch<React.SetStateAction<number>>;
}

const MAX_PAGE_BUTTONS = 10;
const PAGING_BUTTON_SIZE = 'sm' as const;

export const Paging = ({ total, limit, activePage, onChangePage }: PagingProps) => {
  const settings = {
    size: PAGING_BUTTON_SIZE,
    maxButtons: MAX_PAGE_BUTTONS,
    prev: true,
    next: true,
    last: true,
    first: true,
    total,
    limit,
    activePage,
    onChangePage,
  };

  return <Pagination {...settings}></Pagination>;
};
