import { MovieComparisonData } from "types/Comparison/Comparison";
import { EmotionData } from "types/Comparison/EmotionData";

const dataToEmotionData = (data1 : MovieComparisonData, data2 : MovieComparisonData, option : "NEWS" | "SNS" | "커뮤니티" | "포털") => {
    let dataArray : EmotionData[] = [];
    
    if (data1?.platformBuzzCountsMap[option] && data2?.platformBuzzCountsMap[option]) {
        let emotionData1 = data1?.platformBuzzCountsMap[option]!;
        let emotionData2 = data2?.platformBuzzCountsMap[option]!;

        dataArray.push({
            analysis : "긍정글",
            firstMovie : emotionData1?.positiveCounts?.toLocaleString()!,
            secondMovie : emotionData2?.positiveCounts?.toLocaleString()!
        })
    
        dataArray.push({
            analysis : "부정글",
            firstMovie : emotionData1?.negativeCounts?.toLocaleString()!,
            secondMovie : emotionData2?.negativeCounts?.toLocaleString()!
        })
    
        dataArray.push({
            analysis : "중립글",
            firstMovie : emotionData1?.neutralityCounts?.toLocaleString()!,
            secondMovie : emotionData2?.neutralityCounts?.toLocaleString()!
        })
    }

    return dataArray;
}

export default dataToEmotionData;