import { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import MovieTop10ToCard from 'utilities/Dashboard/MovieTop10ToCard';
import DashboardCard from 'components/Dashboard/DashboardCard';
import { ReactComponent as SwiperNext } from '../../assets/svg/swiperNext.svg';
import { ReactComponent as SwiperPrev } from '../../assets/svg/swiperPrev.svg';
import { BuzzAmountFilterProvider } from 'contexts/buzzAmount/BuzzAmountContext';
import { useMovieIdxContext } from 'contexts/dashboard/MovieIdxContext';
import { useMovieListContext } from 'contexts/dashboard/MovieListContext';

import 'swiper/css';
import Top10Table from './Top10Table';

const Top10Section = () => {
  const url = useRef<string>("https://web-movieboard.com/api/movie-top-ten/detail");
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [slides, setSlides] = useState<number>(5);

  const { setMovieIdx } = useMovieIdxContext();
  const { movieTop10List, setMovieTop10List } = useMovieListContext();

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  const getDatas = async () => {
    try {
      const res = await fetch(url.current, options);
      if (!res.ok) {
        const errorResponse = await res.json();
        throw new Error(errorResponse.message);
      }

      const data = await res.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const { data } = useQuery({
    queryKey: ['Top10Section'],
    queryFn: getDatas,
  });

  const handleResize = () => {
    const width = window.innerWidth;

    switch(true) {
      case width >= 1400 :
        setSlides(5);
        break;
      case width >= 1200 :
        setSlides(4);
        break;
      case width >= 1000 :
        setSlides(3);
        break;
      case width >= 850 :
        setSlides(2);
        break;
      default:
        setSlides(1.5);
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    setMovieTop10List(data);

    if (data && data[0]) {
      setMovieIdx(data[0].idx);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [data]);

  return (
    <div className="flex flex-col w-full gap-14">
      <BuzzAmountFilterProvider>
      <span className="text-[#323842] text-20 font-bold">최신영화 top10 분석</span>

      <div className="flex flex-col w-full gap-16 px-30 py-40 box-border border-1 border-[#EAEAEA] rounded-14 bg-white shadow-lg">
        <div className="flex w-full gap-6 [@media(max-width:1024px)]:justify-center">
          <button
            className="flex justify-center items-center w-44 h-44 border-1 border-[#DBDBDB] rounded-[56px]"
            onClick={() => swiper?.slidePrev()}
          >
            <SwiperPrev />
          </button>
          <button
            className="flex justify-center items-center w-44 h-44 border-1 border-[#DBDBDB] rounded-[56px]"
            onClick={() => swiper?.slideNext()}
          >
            <SwiperNext />
          </button>
        </div>
        <div className="w-full">
          <Swiper spaceBetween={20} slidesPerView={slides} onSwiper={setSwiper}>
            {movieTop10List?.map((item) => {
              return (
                <SwiperSlide key={item?.grade}>
                  <DashboardCard data={MovieTop10ToCard(item)} displayGrade={true}/>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      
      <Top10Table/>
      
      </BuzzAmountFilterProvider>
    </div>
  );
};

export default Top10Section;
