import { DateMediaList } from 'types/Medium/cgv';

export const reformDataForGraph = (dateCgvList: DateMediaList[]) => {
  let newDataList: any = [];

  dateCgvList?.map((facebook) => {
    newDataList.push({
      idx: facebook.idx,
      movie: facebook.movie,
      date: facebook.createdAt.replace('T', ' '),
      ticketingPercent: facebook.ticketingPercent.toLocaleString(),
      watchedCount: facebook.watchedCount.toLocaleString(),
      eggPercent: facebook.eggPercent.toLocaleString(),
    });
  });

  return newDataList;
};
