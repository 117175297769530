import { SelectBox } from 'components/common/Filter/SelectBox';
import Input from 'components/common/Modal/Input';

import { ModalListSeveral, ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType;
}

const Severals = ({ data }: Prop) => {
  return (
    <div className="flex w-full gap-32">
      {data.severals?.map((el: ModalListSeveral, idx: number) => (
        <div key={idx} className="flex flex-col w-full gap-12">
          {el['severalType'] === 'select' && (
            <div className="flex flex-col h-full gap-12 pr-32 border-r-1 border-light-gray">
              <p className="text-18">{el['title']}</p>
              <SelectBox
                value={el['value']}
                title={el['title'] || ''}
                options={el['options'] || ['']}
                handleOptionChange={el['setState']}
              />
            </div>
          )}

          {el['severalType'] === 'input' && <Input data={el} />}
        </div>
      ))}
    </div>
  );
};

export default Severals;
