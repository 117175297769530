import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { MovieDateProps } from "types/Comparison/MovieDateProps";

interface SelectMovieData {
    movieSelectList1 : MovieDateProps[],
    movieSelectList2 : MovieDateProps[],
    setMovieSelectList1 : Dispatch<SetStateAction<MovieDateProps[]>>,
    setMovieSelectList2 : Dispatch<SetStateAction<MovieDateProps[]>>
}

const SelectDataContext = createContext<SelectMovieData>({
    movieSelectList1 : [],
    movieSelectList2 : [],
    setMovieSelectList1: () => {
        return;
    },
    setMovieSelectList2: () => {
        return;
    },
});

export const SelectDataProvider = ({ children } : PropsWithChildren) => {
    const [movieSelectList1, setMovieSelectList1] = useState<MovieDateProps[]>([]);
    const [movieSelectList2, setMovieSelectList2] = useState<MovieDateProps[]>([]);

    const values = {
        movieSelectList1,
        setMovieSelectList1,
        movieSelectList2,
        setMovieSelectList2
    }

    return <SelectDataContext.Provider value={values}>{children}</SelectDataContext.Provider>
}

export const useSelectDataContext = () => {
    return useContext(SelectDataContext);
}