interface SelectTitleProps {
  title: string;
}

export const SelectTitle = ({ title }: SelectTitleProps) => {
  return (
    <div className=" text-very-dark-gray text-18 leading-18 mobile:flex mobile:text-14">
      {title}
    </div>
  );
};
