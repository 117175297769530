import { LabeledColumnProps } from 'components/common/Table/DataTable';

import { FieldBuzzData, FieldChartData, TotalAnalyticsData } from 'types/Buzz/BuzzType';

import Aperture from 'assets/svg/Aperture.svg';
import Calculator from 'assets/svg/Calculator.svg';
import Chats from 'assets/svg/Chats.svg';
import { ReactComponent as Globe } from 'assets/svg/Globe.svg';
import Note from 'assets/svg/Note.svg';
import { ReactComponent as Pdf } from 'assets/svg/Pdf.svg';
import { ReactComponent as Pencil } from 'assets/svg/Pencil.svg';
import { ReactComponent as Trash } from 'assets/svg/Trash.svg';
import Users from 'assets/svg/Users.svg';

const initTotalAnalyticsDatas: TotalAnalyticsData[] = [
  {
    background: 'bg-total/10',
    svg: Calculator,
    title: 'Total Count',
    count: 0,
    percentage: '0',
  },
  {
    background: 'bg-portal/10',
    svg: Aperture,
    title: '포털',
    count: 0,
    percentage: '0',
  },
  {
    background: 'bg-sns/10',
    svg: Chats,
    title: 'SNS',
    count: 0,
    percentage: '0',
  },
  {
    background: 'bg-community/10',
    svg: Users,
    title: '커뮤니티',
    count: 0,
    percentage: '0',
  },
  {
    background: 'bg-news/10',
    svg: Note,
    title: 'NEWS',
    count: 0,
    percentage: '0',
  },
];

const initFieldBuzzDatas: FieldBuzzData[] = [
  {
    background: 'bg-portal/10',
    src: Aperture,
    title: '포털',
    totalCount: 0,
    datas: [],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
  {
    background: 'bg-sns/10',
    src: Chats,
    title: 'SNS',
    totalCount: 0,
    datas: [],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
  {
    background: 'bg-community/10',
    src: Users,
    title: '커뮤니티',
    totalCount: 0,
    datas: [],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
  {
    background: 'bg-news/10',
    src: Note,
    title: 'NEWS',
    totalCount: 0,
    datas: [],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
];

const initFieldChartDatas: FieldChartData[] = [
  {
    background: 'bg-portal/10',
    src: Aperture,
    title: '포털',
    values: [
      { id: 'positive', color: '#25BB3D', data: [] },
      { id: 'negative', color: '#FF464B', data: [] },
      { id: 'neutral', color: '#FF7B31', data: [] },
    ],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
  {
    background: 'bg-sns/10',
    src: Chats,
    title: 'SNS',
    values: [
      { id: 'positive', color: '#25BB3D', data: [] },
      { id: 'negative', color: '#FF464B', data: [] },
      { id: 'neutral', color: '#FF7B31', data: [] },
    ],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
  {
    background: 'bg-community/10',
    src: Users,
    title: '커뮤니티',
    values: [
      { id: 'positive', color: '#25BB3D', data: [] },
      { id: 'negative', color: '#FF464B', data: [] },
      { id: 'neutral', color: '#FF7B31', data: [] },
    ],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
  {
    background: 'bg-news/10',
    src: Note,
    title: 'NEWS',
    values: [
      { id: 'positive', color: '#25BB3D', data: [] },
      { id: 'negative', color: '#FF464B', data: [] },
      { id: 'neutral', color: '#FF7B31', data: [] },
    ],
    comments: { positiveCounts: 0, negativeCounts: 0, neutralityCounts: 0 },
  },
];

const columns: LabeledColumnProps[] = [
  {
    key: 'date',
    label: '날짜',
    flexGrow: 0.5,
  },
  {
    key: 'cpMovie',
    label: 'CP사/영화',
    flexGrow: 1,
    align: 'left',
  },
  {
    key: 'title',
    label: '제목',
    flexGrow: 1.2,
    align: 'left',
  },
  {
    key: 'manager',
    label: '담당자',
    flexGrow: 0.6,
  },
  {
    key: 'pdfBtn',
    label: ' ',
    flexGrow: 0.4,
    isCustomCell: true,
  },
  {
    key: 'webBtn',
    label: ' ',
    flexGrow: 0.4,
    isCustomCell: true,
  },
  {
    key: 'modifyBtn',
    label: ' ',
    flexGrow: 0.4,
    isCustomCell: true,
  },
  {
    key: 'deleteBtn',
    label: ' ',
    flexGrow: 0.4,
    isCustomCell: true,
  },
];

const btnProps = {
  pdf: {
    shadow: {
      isShadow: false,
      color: '',
    },
    content: 'PDF',
    color: {
      text: 'text-pdf',
      bg: 'bg-pdf',
    },
    svg: <Pdf />,
  },
  web: {
    shadow: {
      isShadow: false,
      color: '',
    },
    content: 'WEB',
    color: {
      text: 'text-positive',
      bg: 'bg-positive',
    },
    svg: <Globe />,
  },
  modify: {
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '수정',
    color: {
      text: 'text-main',
      bg: 'bg-main',
    },
    svg: <Pencil />,
  },
  delete: {
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '삭제',
    color: {
      text: 'text-alert',
      bg: 'bg-alert',
    },
    svg: <Trash />,
  },
};

export { btnProps, columns, initFieldBuzzDatas, initFieldChartDatas, initTotalAnalyticsDatas };
