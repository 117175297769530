import { FieldCount } from 'components/Data/BuzzReport/FieldCount';

import { FieldBuzzData } from 'types/Buzz/BuzzType';

interface FieldCountContainerProps {
  datas: FieldBuzzData[];
}

export const FieldCountContainer = ({ datas }: FieldCountContainerProps) => {
  return (
    <>
      {datas.map((data, index) => (
        <div
          key={index}
          className={`${index % 2 === 0 && 'mr-22 pr-22 border-r-light-gray border-r-1'} ${
            index === 1 && 'mb-90'
          }`}
        >
          <FieldCount data={data} />
        </div>
      ))}
    </>
  );
};
