import { FieldBuzzData, FieldChartData, PlatformDateCountsMap } from 'types/Buzz/BuzzType';

export const useParseCommentCount = (
  datas: PlatformDateCountsMap,
  fieldBuzzDatas: FieldBuzzData[],
  fieldChartDatas: FieldChartData[],
) => {
  fieldChartDatas.map((item) => {
    item.comments.positiveCounts = 0;
    item.comments.neutralityCounts = 0;
    item.comments.negativeCounts = 0;
  });
  for (const [key, value] of Object.entries(datas)) {
    for (const [keyOfValue, valueOfValue] of Object.entries(value)) {
      fieldBuzzDatas.map((data) => {
        if (data.title === key && data.comments) {
          data.comments.positiveCounts += valueOfValue.positiveCounts;
          data.comments.negativeCounts += valueOfValue.negativeCounts;
          data.comments.neutralityCounts += valueOfValue.neutralityCounts;
        }
      });
      fieldChartDatas.map((data) => {
        if (data.title === key) {
          data.comments.positiveCounts += valueOfValue.positiveCounts;
          data.comments.negativeCounts += valueOfValue.negativeCounts;
          data.comments.neutralityCounts += valueOfValue.neutralityCounts;
        }
      });
    }
  }

  return fieldBuzzDatas;
};
