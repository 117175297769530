/* Imports */
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';

const makeChart = (root, datas, movieTitle1 = '', movieTitle2 = '') => {
  const chartColor = {
    네이버: 0x00d9a6,
    인스타그램: 0xcc62f5,
    유튜브: 0xf5627d,
    커뮤니티: 0xfed51f,
    트위터: 0x7790fa,
    Total: 0xb2e446,
    긍정글: 0x25bb3d,
    부정글: 0xff464b,
    중립글: 0xff7b31,
    movie1: 0xfc696d,
    movie2: 0xfeba69,
  };

  const myTheme = am5themes_Animated.new(root);

  myTheme.rule('Grid').setAll({
    stroke: am5.color(0x000000),
    strokeWidth: 1,
    strokeOpacity: 0.05,
  });

  root.setThemes([myTheme]);

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none',
      wheelY: 'none',
      layout: root.verticalLayout,
    }),
  );

  const tooltipX = am5.Tooltip.new(root, {
    labelText: '{date}',
  });

  tooltipX.get('background').setAll({
    fill: am5.color(0xcc0000),
  });

  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      categoryField: 'date',
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: tooltipX,
    }),
  );

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      min: 0,
    }),
  );

  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      xAxis: xAxis,
      behavior: 'zoomX',
    }),
  );

  cursor.lineY.set('visible', false);

  cursor.lineX.setAll({
    stroke: am5.color(0xcc0000),
    strokeWidth: 1.4,
    strokeDasharray: [],
  });

  cursor.selection.setAll({
    fill: am5.color(0xcc0000),
    fillOpacity: 0.2,
  });

  for (const key in datas) {
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: key,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: 'date',
        valueYField: 'value',
        sequencedInterpolation: true,
        stroke: am5.color(chartColor[key]),
        fill: am5.color(chartColor[key]),
      }),
    );

    const tooltipY = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      pointerOrientation: 'horizontal',
    });

    if (movieTitle1 !== '' && key === 'movie1') {
      tooltipY.label.set('text', `${movieTitle1}: {valueY}`);
    } else if (movieTitle2 !== '' && key === 'movie2') {
      tooltipY.label.set('text', `${movieTitle2}: {valueY}`);
    } else {
      tooltipY.label.set('text', '{name}: {valueY}');
    }

    tooltipY.get('background').setAll({
      fill: am5.color(0xffffff),
      stroke: am5.color(chartColor[key]),
      strokeWidth: 3,
    });

    tooltipY.label.set('fill', am5.color(0x000000));

    series.strokes.template.set('strokeWidth', 4);

    series.set('tooltip', tooltipY);

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 5,
          fill: am5.color(0xffffff),
          stroke: am5.color(chartColor[key]),
          strokeWidth: 3,
        }),
      });
    });

    series.data.setAll(datas[key]);
    xAxis.data.setAll(datas[key]);

    series.appear(500);
  }

  chart.appear(1000, 100);

  return root;
};

export default makeChart;
