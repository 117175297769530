import { CommentAnalytics } from 'components/Data/BuzzReport/CommentAnalytics';
import { FieldImage } from 'components/common/Report/FieldImage';
import { ReportLineChartVer2 } from 'components/common/Report/ReportLineChartVer2';

import { FieldChartData } from 'types/Buzz/BuzzType';

interface FieldChartProps {
  data: FieldChartData;
}

const getType = (data: FieldChartData) => {
  const max = Math.max(
    data.comments.positiveCounts,
    data.comments.neutralityCounts,
    data.comments.negativeCounts,
  );

  if (max === 0) {
    return '';
  } else if (max === data.comments.positiveCounts) {
    return 'positive';
  } else if (max === data.comments.neutralityCounts) {
    return 'neutral';
  } else {
    return 'negative';
  }
};

export const FieldChart = ({ data }: FieldChartProps) => {
  let chartDatas = {};

  if (data.values[0] && data.values[1] && data.values[2]) {
    chartDatas = {
      긍정글: data.values[0]?.data,
      부정글: data.values[1]?.data,
      중립글: data.values[2]?.data,
    };
  }

  const type = getType(data);

  return (
    <div className="mt-150">
      <div className="flex items-center gap-24">
        <FieldImage style={`h-78 w-78 ${data?.background}`} src={data.src} />
        <span>{data.title}</span>
      </div>
      <div>
        <h4 className="mt-52">일별 그래프</h4>
        <div className="flex w-full h-[500px]">
          <ReportLineChartVer2 datas={chartDatas} height="h-[500px]" />
          <CommentAnalytics data={data} type={type} />
        </div>
      </div>
    </div>
  );
};
