import { customedAxios } from 'hooks/axiosApis/customedAxios';
import { getCookie } from 'hooks/cookie';

interface Props {
  url: string;
}

export const useDownloadExcel = async ({ url }: Props) => {
  const header = getCookie('loginToken');

  try {
    const response = await customedAxios.get(url, {
      headers: {
        Authorization: `Bearer ${header}`,
      },
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const createdUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = createdUrl;
    link.download = 'ContentAll.xlsx';
    link.click();

    URL.revokeObjectURL(createdUrl);
  } catch (error) {
    console.error('Error downloading the Excel file:', error);
  }
};

export default useDownloadExcel;
