interface DataItem {
  num: string;
  ospType: string;
  keyword: string;
  movieTitle: string;
  contentTitle: string;
  sentimentType: string;
  createdAt: string;
}

export const parseTotalData = (data: any, page: number) => {
  let parsedData: any = [];

  data?.filteredList.content.map((item: DataItem, index: number) => {
    const each = {
      no: (page - 1) * 10 + index + 1,
      platform: item.ospType,
      keyword: item.keyword,
      title: item.movieTitle,
      content: item.contentTitle,
      type: item.sentimentType === '' ? '-' : item.sentimentType,
      date: item.createdAt.replace('T', ' '),
      delete: item.num,
    };

    parsedData.push(each);
  });

  return parsedData;
};
