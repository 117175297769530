import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"
import { MovieDetailProps } from "types/Dashboard/MovieDetailProps";
import { MovieTop10Props } from "types/Dashboard/MovieTop10Props"

interface MovieTop10List {
    movieTop10List: MovieDetailProps[],
    setMovieTop10List: Dispatch<SetStateAction<MovieDetailProps[]>>
};

const MovieListContext = createContext<MovieTop10List>({
    movieTop10List: [],
    setMovieTop10List: () => {
        return;
    }
});

export const MovieListProvider = ({ children } : PropsWithChildren) => {
    const [movieTop10List, setMovieTop10List] = useState<MovieDetailProps[]>([]);
    
    const values = {
        movieTop10List,
        setMovieTop10List
    }

    return <MovieListContext.Provider value={values}>{children}</MovieListContext.Provider>
}

export const useMovieListContext = () => {
    return useContext(MovieListContext);
}