import useDeleteApi from 'hooks/apis/useDeleteApi';

interface Props {
  text: string;
}

const CustomDeleteCell = ({ text }: Props) => {
  const handleClick = () => {
    useDeleteApi('delete-total-data', `/movie-buzz-total-data/${text}`);
    alert('삭제가 완료되었습니다.');
  };

  return (
    <button
      onClick={() => handleClick()}
      className="flex p-[6px_8px] justify-center items-center gap-[15px] flex-[1_0_0] rounded-[4px] bg-[rgba(255,78,72,0.10)] h-[30px] w-[55px]"
    >
      <p className="text-[#FF4E48] text-center font-pretendard text-[15px] font-semibold leading-normal">
        삭제
      </p>
    </button>
  );
};

export default CustomDeleteCell;
