import { ForwardedRef, forwardRef } from 'react';

interface ModalProps {
  close: () => void;
  open: () => void;
  postFunc: () => void;
}

export const ConfirmModal = forwardRef(
  ({ close, open, postFunc }: ModalProps, ref: ForwardedRef<HTMLDialogElement>) => {
    const handleComplete = () => {
      close();
      open();
      postFunc();
    };

    return (
      <dialog
        ref={ref}
        className="w-[1058px] h-[330px] p-0 rounded-12 backdrop:bg-black backdrop:bg-opacity-60"
      >
        <div className="w-full h-[232px] bg-white flex items-center justify-center">
          <span className="font-bold text-28 mobile:text-20">해당 보고서를 수정 하시겠습니까?</span>
        </div>
        <div className="w-full h-[98px] bg-light-gray flex items-center justify-center gap-8 mobile:px-15">
          <button
            className="w-[140px] h-[58px] text-centerm leading-58 bg-light-gray text-dark-gray shadow-button rounded-12"
            onClick={() => close()}
          >
            취소
          </button>
          <button
            className="w-[320px] h-[58px] text-centerm leading-58 bg-main text-white shadow-button rounded-12"
            onClick={() => handleComplete()}
          >
            확인
          </button>
        </div>
      </dialog>
    );
  },
);
