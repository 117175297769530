import isAfter from 'date-fns/isAfter';
import { BuzzChartData } from "types/Comparison/ChartData";
import dateFormatter from "./DateFormatter";

const dataToBuzzChartData = (startDate : Date, endDate : Date, data : any, option : "openDate" | "useCalendar") => {
    const keyList = Object.keys(data);
    let valueList : BuzzChartData[] = [];
    const len = keyList?.length;

    if (option === "openDate") {
        if (len > 0) {
            for (let i = 0; i < len; i++) {
                const dataBuf = data[keyList[i]!];
                
                valueList.push({
                    date: i === 0 ? "D-Day" : `D+${i}`,
                    value: dataBuf ? dataBuf : 0
                });
            }
        }
        else {
            for (let i = 0; i <= 28; i++) {
                
                valueList.push({
                    date: i === 0 ? "D-Day" : `D+${i}`,
                    value: 0
                });    
            }
        }
    }
    else  {
        const startDateBuf = new Date(startDate);

        for (let i = startDateBuf; !isAfter(startDateBuf, endDate); startDateBuf.setDate(startDateBuf.getDate() + 1)) {
            const dataBuf = data[dateFormatter(startDateBuf)];
            
            valueList.push({
                date: startDateBuf.getTime(),
                value: dataBuf ? dataBuf : 0
            });
        }
    }

    return valueList;
}

export default dataToBuzzChartData;