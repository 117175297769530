import { HeaderState, MenuMapping } from 'types/Header/header';

const menuMapping: MenuMapping = {
  '/': '대시보드',
  '/data': '전체 데이터 통계',
  '/comparison': '영화 비교 분석',
  '/medium': '영화 매체 분석',
  '/subscription/regis-movie': '내 영화 등록',
  '/subscription/payment': '결제관리',
  '/report': '리포트 센터',
  '/report/report-detail': '리포트 센터',
  '/management/platform': '플랫폼 현황',
  '/management/total-data': '전체 데이터 현황',
  '/management/account': '계정 현황',
};

export const eng2kor = (content: string): HeaderState | undefined => {
  return menuMapping[content];
};
