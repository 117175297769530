import { useRef, useState } from 'react';

import CustomContentTitle from 'components/Data/CustomContentTitle';
import { CustomTableCell } from 'components/Data/CustomTableCell';
import Modal from 'components/Data/Modal';
import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';
import { Paging } from 'components/common/Table/Paging';
import { addComma } from 'components/common/addAndRemoveComma';

import { useBuzzAmountFilterContext } from 'contexts/buzzAmount/BuzzAmountContext';

import useGetApi from 'hooks/axiosApis/useGetApi';

import CustomSentiment from '../mobile/CustomSentiment';

interface ModalDataProps {
  [key: string]: string;
}

const BuzzTableContainer = () => {
  const [modalData, setModalData] = useState<ModalDataProps>({
    id: '',
    cpId: '',
    contents: '',
    platformType: '',
    ospType: '',
    movieTitle: '',
    keyword: '',
    url: '',
    contentsTitle: '',
    writer: '',
    sentimentType: '',
    writeDate: '',
    createdAt: '',
  });
  const { page, totalElements, parsedDatas, setPage } = useBuzzAmountFilterContext();
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const columns: LabeledColumnProps[] = [
    {
      key: 'no',
      label: 'no.',
      flexGrow: 0.64,
    },
    {
      key: 'ospType',
      label: '플랫폼',
      flexGrow: 1,
    },
    {
      key: 'movieTitle',
      label: '영화제목',
      flexGrow: 1,
    },
    {
      key: 'keyword',
      label: '필수 키워드',
      flexGrow: 0.93,
    },
    {
      key: 'contentTitle',
      label: '제목',
      flexGrow: 2.38,
      isCustomCell: true,
      handleClick: (num) => {
        fetchModalData(num);
      },
    },
    {
      key: 'sentimentType',
      label: '분류',
      flexGrow: 1,
      isCustomCell: true,
    },
    {
      key: 'createdAt',
      label: '날짜',
      flexGrow: 1,
    },
  ];
  const columnsMobile: LabeledColumnProps[] = [
    {
      key: 'sentimentType',
      label: '분류',
      flexGrow: 0.2,
      isCustomCell: true,
    },
    {
      key: 'contentTitle',
      label: `ㅤ제목`,
      flexGrow: 0.8,
      isCustomCell: true,
      handleClick: (num) => {
        fetchModalData(num);
      },
    },
  ];
  const closeDialog = () => {
    dialogRef.current && dialogRef.current.close();
  };
  const fetchModalData = (id: string) => {
    dialogRef.current && dialogRef.current.showModal();
    const URL = `/movie-buzz-total-data/modal?id=${id}`;

    useGetApi(URL).then((data) => setModalData(data));
  };

  return (
    <div className="">
      <div className="hidden">전체 {addComma(totalElements)}건</div>
      {parsedDatas && (
        <>
          <div className="mobile:hidden">
            <DataTable columns={columns} datas={parsedDatas} rowKey="num">
              <CustomTableCell key={'sentimentType'} text=""></CustomTableCell>
              <CustomContentTitle key={'contentTitle'} text=""></CustomContentTitle>
            </DataTable>
          </div>
          <div className="hidden mobile:flex mobile:overflow-scroll">
            <DataTable columns={columnsMobile} datas={parsedDatas} rowKey="num">
              <CustomSentiment key={'sentimentType'} text=""></CustomSentiment>
              <CustomContentTitle key={'contentTitle'} text=""></CustomContentTitle>
            </DataTable>
          </div>
        </>
      )}

      {parsedDatas && (
        <Paging total={totalElements} limit={10} activePage={page} onChangePage={setPage}></Paging>
      )}
      {modalData && <Modal data={modalData} close={() => closeDialog()} ref={dialogRef} />}
    </div>
  );
};

export default BuzzTableContainer;
