import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"
import { MovieDetailProps } from "types/Dashboard/MovieDetailProps"

interface MovieDetailList {
    movieDetailList: MovieDetailProps[],
    setMovieDetailList: Dispatch<SetStateAction<MovieDetailProps[]>>
}

const MovieDetailContext = createContext<MovieDetailList>({
    movieDetailList: [],
    setMovieDetailList: () => {
        return;
    }
});

export const MovieDetailProvider = ({ children } : PropsWithChildren) => {
    const [movieDetailList, setMovieDetailList] = useState<MovieDetailProps[]>([]);

    const values = {
        movieDetailList,
        setMovieDetailList
    }

    return <MovieDetailContext.Provider value={values}>{children}</MovieDetailContext.Provider>
}

export const useMovieDetailContext = () => {
    return useContext(MovieDetailContext);
}