import { DateMediaList, ReformedDateMediaList } from 'types/Medium/cgv';

export const reformDataForChart = (dateCgvList: DateMediaList[]) => {
  let newDataList: ReformedDateMediaList[] = [];

  dateCgvList?.map((dateCgv) => {
    newDataList.push({
      date: dateCgv.createdAt,
      watchedCount: dateCgv.watchedCount,
      eggPercent: dateCgv.eggPercent,
      ticketingPercent: dateCgv.ticketingPercent,
    });
  });

  return newDataList;
};
