import { SelectBox } from 'components/common/Filter/SelectBox';

import { ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType;
}

const Selectbox = ({ data }: Prop) => {
  return (
    <div className="flex flex-col gap-16">
      <p className="text-18">{data.title}</p>
      <SelectBox
        title={data.placeholder || ''}
        options={data.options || []}
        handleOptionChange={data.setState}
      />
    </div>
  );
};

export default Selectbox;
