import { customedAxios } from 'hooks/axiosApis/customedAxios';
import { getCookie } from 'hooks/cookie';

const useGetApi = async (url: string, isRequiredHeader = false) => {
  const header = getCookie('loginToken');
  const response = await customedAxios.get(url, {
    headers: {
      Authorization: `Bearer ${header}`,
    },
  });

  return response.data;
};

export default useGetApi;
