import { customedAxios } from 'hooks/axiosApis/customedAxios';

import { NewReportBody } from 'types/Buzz/BuzzType';

export const useFetchNewReport = async (body: NewReportBody) => {
  if (!body.reportTitle) {
    alert('보고서 제목을 입력해주세요');
  } else if (confirm('신규 보고서를 생성하시겠습니까?')) {
    try {
      await customedAxios.post('/report', body);
      alert('보고서 저장에 성공했습니다');
      return true;
    } catch (e) {
      alert('보고서 저장에 실패했습니다.');

      console.error(e);
    }
  }

  return false;
};
