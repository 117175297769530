export const ChartLabel = () => {
  return (
    <div className="flex items-center">
      <div className="h-[6px] w-[46px] mb-4 ml-38 bg-portal"></div>
      <span className="ml-8 text-14">포털</span>
      <div className="h-[6px] w-[46px] mb-4 ml-38 bg-sns"></div>
      <span className="ml-8 text-14">SNS</span>
      <div className="h-[6px] w-[46px] mb-4 ml-38 bg-community"></div>
      <span className="ml-8 text-14">커뮤니티</span>
      <div className="h-[6px] w-[46px] mb-4 ml-38 bg-news"></div>
      <span className="ml-8 text-14">NEWS</span>
    </div>
  );
};
