import { ForwardedRef, forwardRef } from 'react';

import CheckCircle from 'assets/svg/CheckCircle.svg';

interface ModalProps {
  close: () => void;
}

export const CompleteModal = forwardRef(
  ({ close }: ModalProps, ref: ForwardedRef<HTMLDialogElement>) => {
    return (
      <dialog
        ref={ref}
        className="w-[1058px] h-[330px] p-0 rounded-12 backdrop:bg-black backdrop:bg-opacity-60"
      >
        <div className="w-full h-[232px] bg-white flex flex-col gap-24 items-center justify-center">
          <img src={CheckCircle} alt="check"></img>
          <span className="font-bold text-28 mobile:text-20">보고서 수정이 완료되었습니다.</span>
        </div>
        <div className="w-full h-[98px] bg-light-gray flex items-center justify-center gap-8 mobile:px-15">
          <button
            className="w-[460px] h-[58px] text-centerm leading-58 bg-main text-white shadow-button rounded-12"
            onClick={() => close()}
          >
            확인
          </button>
        </div>
      </dialog>
    );
  },
);
