import { ReactElement, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { ReactComponent as Chart } from "../../assets/svg/Chart.svg";
import { ReactComponent as ArrowDown } from "../../assets/svg/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "../../assets/svg/ArrowUp.svg";
import { ReactComponent as Information } from "../../assets/svg/Information.svg";
import { useMovieIdxContext } from "contexts/dashboard/MovieIdxContext";
import CustomToolTip from "./CustomToolTip";

interface DisplayProps {
    icon: ReactElement,
    title: string,
    value: string
}

const AIPrediction = () => {
    const { movieIdx } = useMovieIdxContext();

    const [ url, setUrl ] = useState<string>(movieIdx ? `https://web-movieboard.com/api/movie-top-ten/${movieIdx}/chat-gpt/predictive` : "");

    const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

    const getDatas = async () => {
        try {
            if (url) {
                const res = await fetch(url, options);
            
                if (!res.ok) {
                    const errorResponse = await res?.json();
                    throw new Error(errorResponse.message);
                }
            
                const data = await res?.json();
                return data;
            }
            return {
                idx: 0,
                movie: "",
                lowestWatchedCount: 0,
                middleWatchedCount: 0,
                highestWatchedCount: 0,
                predictiveAnswer: "",
                createdAt: ""
            };
        } catch (error) {
            console.error(error);
            return {
                idx: 0,
                movie: "",
                lowestWatchedCount: 0,
                middleWatchedCount: 0,
                highestWatchedCount: 0,
                predictiveAnswer: "",
                createdAt: ""
            };
        }
    };

    const { data, refetch, isLoading } = useQuery({
        queryKey: ["AIPrediction"],
        queryFn: getDatas,
    });

    const displayList : DisplayProps[] = [
        {   
            icon: <ArrowDown className="mb-14"/>,
            title: "최저 흥행 예상",
            value: 'lowestWatchedCount'
        },
        {   
            icon: <Chart className="mb-14"/>,
            title: "평균 흥행 예상",
            value: 'middleWatchedCount'
        },
        {   
            icon: <ArrowUp className="mb-14"/>,
            title: "최고 흥행 예상",
            value: 'highestWatchedCount'
        }
    ];

    useEffect(() => {
        if (movieIdx) {
            setUrl(`https://web-movieboard.com/api/movie-top-ten/${movieIdx}/chat-gpt/predictive`);
            refetch();
        }
    }, [ movieIdx, data, url ]);

    return (
        <div className="flex flex-col items-stretch w-full h-full gap-14">
            <span className="flex gap-6 text-[#323842] text-[20px] font-bold">
               {data?.movie} AI예상관객량
               <CustomToolTip 
                    toolTipId="ai-tooltip"
                    toolTipPosition="right"
                    message="<div>
                    <span><b>AI 예상 관객량은</b> 감독, 배우, 배급사, 현재 흥행 등 영화 정보를 바탕으로 측정됩니다.<br/>
                    개봉 기간과 바이럴 영향에 따라 최저, 평균, 최고 흥행 예상 수치가 매일 업데이트됩니다.</span></div>"
                    arrowPosition
                >
                    <Information/>
               </CustomToolTip>
            </span>
            <div className="flex w-full gap-14 [@media(max-width:850px)]:flex-col">
                {
                    displayList?.map((item, index) => {

                        return (
                            <div 
                                key={index} 
                                className="flex justify-center flex-col w-full h-full min-h-[172px] p-24 box-border border-1 border-[#E8E8E8] rounded-14 bg-white shadow-lg"
                            >
                                {item?.icon}
                                <span className="mb-20 text-[15px] font-semibold">{item?.title}</span>
                                <span className="text-[24px] font-bold">{Number(data ? data[item?.value]! : "").toLocaleString()}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AIPrediction;