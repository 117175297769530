import { Kor2eng, MenuMapping } from 'types/Nav/menu';

const menuMapping: MenuMapping = {
  대시보드: { content: 'dashboard', path: '/' },
  '전체 데이터 통계': { content: 'data', path: 'data' },
  '영화 비교 분석': { content: 'comparison', path: 'comparison' },
  '영화 매체 분석': { content: 'medium', path: 'medium' },
  '정기 구독 관리': {
    content: 'subscription',
    subMenu: [
      { title: '내 영화 등록', path: 'regis-movie' },
      { title: '결제관리', path: 'payment' },
    ],
  },
  '리포트 센터': { content: 'report', path: 'report' },
  '관리 센터': {
    content: 'management',
    subMenu: [
      { title: '플랫폼 현황', path: 'platform' },
      { title: '전체 데이터 현황', path: 'total-data' },
      { title: '계정 현황', path: 'account' },
    ],
  },
};

export const kor2eng = (content: string): Kor2eng | undefined => {
  return menuMapping[content];
};
