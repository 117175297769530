import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import moment from 'moment';
import { DateRange } from 'rsuite/esm/DateRangePicker';

import PillBtn from 'components/common/Button/PillBtn';
import { Calender } from 'components/common/Filter/Calender';
import { SelectBox } from 'components/common/Filter/SelectBox';
import { SelectTitle } from 'components/common/Filter/SelectTitle';
import { VerticalLine } from 'components/common/VerticalLine';

import { customedAxios } from 'hooks/axiosApis/customedAxios';
import useCpDropdown from 'hooks/dropdown/useCpDropdown';

import { btnProps } from 'types/Buzz/BuzzType';

const field = ['포털', '커뮤니티', 'SNS', 'NEWS'];

interface FilterContainerProps {
  setStateActions: {
    setSelectedCp: Dispatch<SetStateAction<string>>;
    setSelectedMovie: Dispatch<SetStateAction<string>>;
    setDateRange: Dispatch<SetStateAction<{ startDate: string; endDate: string } | undefined>>;
  };
  pillBtnProps: btnProps;
  isFilterDisabled: boolean;
  values: {
    selectedCP: string;
    selectedMovie: string;
    dateRangeLimit: { startDate: string; endDate: string } | undefined;
  };
}

export const FilterContainer = ({
  setStateActions,
  pillBtnProps,
  isFilterDisabled,
  values,
}: FilterContainerProps) => {
  const [cpMenus, setCpMenus] = useState<string[]>([]);
  const [movieOptions, setMovieOptions] = useState<string[]>([]);

  const handleCpOptionChange = async (cp: string) => {
    const { data } = await customedAxios.get('/movie/title?cp=' + cp);
    setMovieOptions(data ? [...data] : []);
    setStateActions.setSelectedCp(cp);
  };

  const handleDaterangeChange = (value: DateRange | null) => {
    if (value) {
      const startDate = moment(value[0]).format('YYYY-MM-DD');
      const endDate = moment(value[1]).format('YYYY-MM-DD');

      setStateActions.setDateRange({ startDate, endDate });
    }
  };

  useEffect(() => {
    useCpDropdown({ setState: setCpMenus });
  }, []);

  return (
    <div className="flex items-center min-w-[1600px] bg-white rounded-24 px-30 mobile:flex-col mobile:min-w-[343px]">
      <div className="flex flex-col w-full">
        <div className="flex pt-30 mobile:flex-col mobile:justify-center mobile:items-center mobile:gap-30">
          <div className="flex items-center justify-center w-1/3 gap-14 mobile:min-w-[303px]">
            <SelectTitle title="CP사"></SelectTitle>
            <SelectBox
              title={'CP사 선택'}
              options={cpMenus}
              handleOptionChange={handleCpOptionChange}
              readonly={isFilterDisabled}
              value={values.selectedCP}
            />
          </div>
          <div className="mobile:hidden">
            <VerticalLine height={72} />
          </div>
          <div className="flex items-center justify-center w-1/3 gap-14 mobile:min-w-[303px]">
            <SelectTitle title="영화"></SelectTitle>
            <SelectBox
              title={'영화 선택'}
              options={movieOptions}
              handleOptionChange={(movie) => setStateActions.setSelectedMovie(movie)}
              readonly={isFilterDisabled}
              value={values.selectedMovie}
            />
          </div>
          <div className="mobile:hidden">
            <VerticalLine height={72} />
          </div>
          <div className="flex items-center justify-center w-1/3 gap-14 mobile:min-w-[303px]">
            <SelectTitle title="날짜"></SelectTitle>
            <div>
              <Calender
                handleChange={handleDaterangeChange}
                dateRange={values.dateRangeLimit}
                disabled={isFilterDisabled}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center my-30">
          <PillBtn pillBtnProps={pillBtnProps} />
        </div>
      </div>
    </div>
  );
};
