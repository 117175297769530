import { useEffect, useState } from 'react';

import { customedAxios } from 'hooks/axiosApis/customedAxios';

import { TopPicksCardProps } from 'types/Subscription/TopPicksCard';

import TopPicksCard from './TopPicksCard';

const TopPicks = () => {
  const [topPicks, setTopPicks] = useState<TopPicksCardProps[] | null>(null);

  useEffect(() => {
    const fetchTopten = async () => {
      try {
        const response = await customedAxios.get('/movie-top-ten/detail');
        setTopPicks(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchTopten();
  }, []);

  return (
    <div>
      <h1 className="font-bold text-20 mt-37">최신 인기작 TOP7</h1>
      <div className="grid grid-cols-7 px-40 py-20 mt-20 bg-white gap-30">
        {topPicks &&
          topPicks.slice(0, 7).map((data, index) => (
            <div key={index}>
              <TopPicksCard data={data} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TopPicks;
