import { FunctionComponent, useContext, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { initializeNavBarState } from 'utilities/Nav/initializeNavBarState';
import { kor2eng } from 'utilities/Nav/kor2eng';

import SubMenu from 'components/common/Layout/Navbar/SubMenu';

import { PageStateContext } from 'contexts/NavbarContext';

import { ReactComponent as Caret } from 'assets/svg/Caret.svg';

import './Menu.css';

interface Props {
  content: string;
  Icon: FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const MenuButton = ({ content, Icon }: Props) => {
  const { pageState, setPageState, setSubPageState } = useContext(PageStateContext);
  const navigate = useNavigate();
  const mappedPageState = kor2eng(content);
  const clickedMenu = mappedPageState?.content === pageState;
  const hasClickedMenu = clickedMenu && mappedPageState.subMenu && true;
  const currentRoute = useLocation().pathname;

  useEffect(() => {
    if (pageState !== 'medium' && pageState !== 'subscription' && pageState !== 'management') {
      setSubPageState(undefined);
    }
  }, [pageState]);

  useEffect(() => {
    initializeNavBarState(setPageState, setSubPageState);
  }, [currentRoute]);

  return (
    <div className={`${clickedMenu && 'bg-[#ffffff0d]'} py-7 rounded-6`}>
      <button
        className={`${clickedMenu ? 'h-auto text-white' : 'text-white/70'} ${
          hasClickedMenu && 'mb-10'
        } group flex items-center justify-between w-full px-10 text-15 leading-22 font-[600]  hover:bg-white/05`}
        onClick={() => {
          setPageState(mappedPageState!.content);
          mappedPageState?.path && navigate(mappedPageState?.path);
        }}
      >
        <div className="flex items-center gap-4">
          <Icon
            className={`${
              mappedPageState?.content === pageState
                ? 'fill-logo stroke-logo'
                : 'fill-white stroke-white'
            }`}
          />
          <span>{content}</span>
        </div>
        {mappedPageState?.subMenu && (
          <Caret
            className={`${
              hasClickedMenu
                ? 'rotate-0 transition-rotate duration-500'
                : '-rotate-180 transition-rotate duration-500'
            }`}
          />
        )}
      </button>
      {<SubMenu page={mappedPageState!} hasClickMenu={hasClickedMenu!} />}
    </div>
  );
};

export default MenuButton;
