import { TotalChartProp } from 'components/Data/BuzzReport/TotalChart';

import { useAddCountData } from 'hooks/Buzz/useAddCountData';
import { useAddDateAndColor } from 'hooks/Buzz/useAddDateAndColor';

import { ParsingForm, PlatformDateCountsMap } from 'types/Buzz/BuzzType';

export const useParseTotalchartData = (datas: PlatformDateCountsMap): TotalChartProp[] => {
  let result: ParsingForm = {};

  const dayOfDates = Object.keys(Object.values(datas)[0] as object);

  result = useAddDateAndColor(dayOfDates, result);
  result = useAddCountData(datas, result);

  return Object.values(result);
};
