import EmotionImage from 'components/common/CommentAnalytics/EmotionImage';

interface Prop {
  text: 'POSITIVE' | 'NEGATIVE' | 'NEUTRAL' | '';
}

interface Converting {
  POSITIVE: 'positive';
  NEGATIVE: 'negative';
  NEUTRAL: 'neutral';
}

const converting: Converting = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

const CustomSentiment = ({ text }: Prop) => {
  return (
    <div className="flex items-center justify-center w-full h-52">
      {text !== '' && <EmotionImage type={converting[text]} />}
    </div>
  );
};

export default CustomSentiment;
