import { Fragment } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { getCookie } from 'hooks/cookie';

import { ReactComponent as FreeStar } from '../../assets/svg/freeStar.svg';
import { ReactComponent as OneMonthStar } from '../../assets/svg/oneMonthStar.svg';
import { ReactComponent as OneYearStar } from '../../assets/svg/oneYearStar.svg';
import { ReactComponent as Information } from "../../assets/svg/Information.svg";
import CustomToolTip from './CustomToolTip';

const SubscriptionManage = () => {
  const cookie = getCookie('loginToken');

  const planList = [
    {
      icon: <FreeStar />,
      planName: '무료버전',
    },
    {
      icon: <OneMonthStar />,
      planName: '1개월 구독',
      information: '내 영화 5편',
    },
    {
      icon: <OneYearStar />,
      planName: '1년 구독',
      information: '내 영화 10편',
    },
  ];

  const client = axios.create({ baseURL: 'https://web-movieboard.com/api' });

  const handleplan = (reqPlan: string) => {
    switch (reqPlan) {
      case '무료':
        return '무료버전';

      case '1개월':
        return '1개월 구독';

      case '1년':
        return '1년 구독';

      default:
        return '무료버전';
    }
  };

  const request = ({ ...options }) => {
    client.defaults.headers.common.Authorization = `Bearer ${cookie}`;

    return client(options);
  };

  const getMyMovies = () =>
    request({
      url: '/member/subscriptions',
    });

  const getDatas = async () => {
    const res = await getMyMovies();

    const data = await res.data;

    return data;
  };

  const { data } = useQuery({
    queryKey: ['SubscriptionManage'],
    queryFn: getDatas,
  });

  const handleClick = () => {
    alert('무료 기간입니다.');
  };

  return (
    <div className="flex flex-col items-stretch w-full h-full gap-14">
      <span className="flex gap-6 text-[#323842] text-[20px] font-bold">
        구독관리
        <CustomToolTip 
          toolTipId="subscription-tooltip"
          toolTipPosition="right"
          message="<div>
          <span><b>무료버전: </b>메일링 서비스와 무비진을 통해 영화의 기본적인 정보와 통계를 확인</span><br/>
          <span><b>1개월 구독: </b>영화 TOP10 데이터 확인 및 최대 5편의 영화에 대한 데이터 분석</span><br/>
          <span><b>1년 구독: </b>최대 10편의 영화에 대한 데이터 분석</span>
          </div>"
        >
          <Information/>
        </CustomToolTip>
      </span>
      <div className="flex w-full min-h-[172px] gap-16 p-30 box-border border-1 border-[#EAEAEA] rounded-14 bg-white shadow-lg [@media(max-width:1048px)]:flex-col">
        {planList.map((item, index) => {
          const activatedStyle =
            data && item.planName === handleplan(data) ? ' border-[#EE8B1D] text-[#EE8B1D]' : '';

          return (
            <Fragment key={index}>
              <div className="flex flex-col justify-between w-full h-full min-h-[112px] [@media(max-width:1048px)]:justify-normal [@media(max-width:1048px)]:gap-14 [@media(max-width:1048px)]:min-h-0">
                <div className="flex flex-col w-full h-full gap-10 mobile:flex-row">
                  {item?.icon}
                  <div className="flex items-center w-full h-full gap-6 [@media(max-width:1750px)]:flex-col [@media(max-width:1750px)]:items-start">
                    <span className="text-[17px] font-semibold">{item?.planName}</span>
                    {item.information && (
                      <div className="justify-center items-center h-full px-10 py-4 box-border rounded-46 bg-[#EE8B1D]/10 text-[13px] text-[#EE8B1D]">
                        <span>{item.information}</span>
                      </div>
                    )}
                  </div>
                </div>

                <button
                  className={
                    'flex justify-content items-center h-36 px-40 py-9 box-border border-1 rounded-4 bg-white [@media(max-width:1750px)]:px-0' +
                    activatedStyle
                  }
                  onClick={handleClick}
                >
                  {item.planName === handleplan(data) ? (
                    <span className="w-full h-full">이용중</span>
                  ) : (
                    <span className="w-full h-full">변경</span>
                  )}
                </button>
              </div>
              {index !== planList.length - 1 && <div className="w-1 mx-30 bg-[#EBEBEB] [@media(max-width:1048px)]:h-1 [@media(max-width:1048px)]:w-full [@media(max-width:1048px)]:mx-0 [@media(max-width:1048px)]:my-16" />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionManage;
