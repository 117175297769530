import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { MovieDateProps } from "types/Comparison/MovieDateProps";

interface CompareMovieNames {
    movieDate1 : MovieDateProps,
    movieDate2 : MovieDateProps,
    setMovieDate1: Dispatch<SetStateAction<MovieDateProps>>,
    setMovieDate2: Dispatch<SetStateAction<MovieDateProps>>
};

const MovieDataContext = createContext<CompareMovieNames>({
    movieDate1: {
        title: "",
        openDate: "",
        startDate: "",
        endDate: ""
    },
    movieDate2: {
        title: "",
        openDate: "",
        startDate: "",
        endDate: ""
    },
    setMovieDate1: () => {
        return;
    },
    setMovieDate2: () => {
        return;
    },
});

export const MovieDataProvider = ({ children } : PropsWithChildren) => {
    const [movieDate1, setMovieDate1] = useState<MovieDateProps>({
        title: "",
        openDate: "",
        startDate: "",
        endDate: ""
    });
    const [movieDate2, setMovieDate2] = useState<MovieDateProps>({
        title: "",
        openDate: "",
        startDate: "",
        endDate: ""
    });

    const values = {
        movieDate1,
        setMovieDate1,
        movieDate2,
        setMovieDate2
    }

    return <MovieDataContext.Provider value={values}>{children}</MovieDataContext.Provider>;
}

export const useMovieDataContext = () => {
    return useContext(MovieDataContext);
}