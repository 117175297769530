import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';

interface Props {
  data: any;
}

const GraphContainer = ({ data }: Props) => {
  const colums: LabeledColumnProps[] = [
    {
      key: 'idx',
      label: 'no.',
      flexGrow: 1,
    },
    {
      key: 'movie',
      label: '영화제목',
      flexGrow: 1,
    },
    {
      key: 'ticketingPercent',
      label: '예매율',
      flexGrow: 1,
    },
    {
      key: 'eggPercent',
      label: '에그지수',
      flexGrow: 1,
    },
    {
      key: 'watchedCount',
      label: '실관람객 수',
      flexGrow: 1,
    },
    {
      key: 'date',
      label: '날짜',
      flexGrow: 1,
    },
  ];

  return (
    <div className="w-full h-auto bg-white rounded-14 shadow-medium">
      <DataTable datas={data} columns={colums} rowKey={'idx'} />
    </div>
  );
};

export default GraphContainer;
