import { CountPercentage } from 'components/common/Report/CountPercentage';
import { FieldImage } from 'components/common/Report/FieldImage';
import { addComma } from 'components/common/addAndRemoveComma';

import { TotalAnalyticsData } from 'types/Buzz/BuzzType';

interface TotalAnalyticsListProps {
  data: TotalAnalyticsData;
}

export const TotalAnalyticsList = ({ data }: TotalAnalyticsListProps) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center gap-24 basis-[275px] shrink-0">
        <FieldImage style={`h-78 w-78 ${data?.background}`} src={data?.svg} />
        <span>{data?.title}</span>
      </div>
      <div className="flex justify-between basis-full shrink">
        <span className={` font-bold text-18 ${data.title !== 'Total Count' && 'mt-6'}`}>
          {data.count !== 0 && data.count ? addComma(data.count) : '-'}
        </span>
        <div>
          {data.title !== 'Total Count' && data.percentage && (
            <CountPercentage count={data.percentage ? data.percentage : '0'} height="h-[32px]" />
          )}
        </div>
      </div>
    </div>
  );
};
