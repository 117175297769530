import { Calender } from 'components/common/Filter/Calender';
import { SelectBox } from 'components/common/Filter/SelectBox';

import { SeveralTypes } from 'types/Management/mangementType';

import { ReactComponent as DatePicker } from 'assets/svg/DatePicker.svg';

interface SelectsProp {
  several: SeveralTypes[];
}

const Several = ({ several }: SelectsProp) => {
  return (
    <div className="flex flex-col gap-12 pr-35 pl-30 ">
      {several.map((el, idx) => (
        <div key={idx}>
          {el['severalType'] === 'select' && (
            <SelectBox
              title={el.placeholder || ''}
              options={el.options || []}
              handleOptionChange={(target) => el.setState(target)}
              value={el.value}
              isSearch={el.isSearch || false}
              label={el.label}
            />
          )}
          {el['severalType'] === 'calendar' && (
            <div className="flex items-center justify-center gap-17">
              <label className="flex text-14 font-bold text-[#6D7683] w-90">
                날짜선택 &nbsp;
                <div>
                  <DatePicker />
                </div>
              </label>
              <Calender handleChange={(target) => el.setState(target)} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Several;
