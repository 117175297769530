// 천단위 콤마찍기 (숫자 하나)
export const addComma = (number: number | string) => {
  if (typeof number === 'number') {
    return number.toLocaleString('ko-KR');
  }

  return Number(number).toLocaleString('ko-KR');
};

// 문자열에서 콤마 지우고 숫자로 반환
export const removeComma = (numStr: string) => {
  return Number(numStr.replace(/,/g, ''));
};
