interface CustomContentTitlteProps {
  text: string;
}

const CustomContentTitle = ({ text }: CustomContentTitlteProps) => {
  return (
    <div className="overflow-hidden underline cursor-pointer overflow-ellipsis mobile:w-full">
      {text}
    </div>
  );
};

export default CustomContentTitle;
