import { DashboardCardProps } from 'types/Dashboard/DashboardCardProps';
import { MyMovieProps } from 'types/Dashboard/MyMovieProps';

const MyMovieToCard = ( myMovie : MyMovieProps ) => {
    let cardData : DashboardCardProps = {
        grade: 0,
        cp_id: "-",
        imgPath: "-",
        movieTitle: "-",
        openDate: "-"
    }

    cardData.grade = 0;
    cardData.cp_id = myMovie.cpId;
    cardData.imgPath = myMovie.poster;
    cardData.movieTitle = myMovie.contentTitle;
    cardData.openDate = myMovie.openDate;

    return cardData;
}

export default MyMovieToCard;