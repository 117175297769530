import { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import dataToBuzzChartData from 'utilities/Comparison/DataToBuzzChartData';
import { useComparisonDataContext } from 'contexts/comparison/ComparisonDataContext';
import { useSearchDataContext } from 'contexts/comparison/SearchDataContext';
import { useMovieDataContext } from 'contexts/comparison/MovieDataContext';

const BuzzChart = () => {
    const { movieDate1, movieDate2 } = useMovieDataContext();
    const { movieData1, movieData2 } = useComparisonDataContext();
    const { option, startDate, endDate } = useSearchDataContext();

    useLayoutEffect(() => {
      let root = am5.Root.new("chartdiv");
  
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      root.interfaceColors.set("text", am5.color(0x6d7683));
  
      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          focusable: true,
          wheelX: "panX",
          wheelY: "zoomX",
          panX: true,
          panY: true,
          pinchZoomX: true,
          layout: root.verticalLayout
        })
      );

      let movieChartData1 = dataToBuzzChartData(startDate, endDate, movieData1.dateBuzzCountsMap, option);
      let movieChartData2 = dataToBuzzChartData(startDate, endDate, movieData2.dateBuzzCountsMap, option);
  
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );

      yAxis.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));

      var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      
      // 캘린더 검색을 했을 경우에 사용하는 xAxis
      let CalendarxAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          tooltipDateFormat: "yyyy-MM-dd",
          renderer: am5xy.AxisRendererX.new(root, {})
        })
      ); 

      CalendarxAxis.set("tooltip", am5.Tooltip.new(root, {
        themeTags: ["axis"]
      }));

      // 개봉일 검색을 했을 경우에 사용하는 xAxis
      var openDatexAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: 'date',
          renderer: xRenderer,
        }),
      );
      
      if (option === "openDate") {
        const movieSeries1 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: movieDate1.title,
            xAxis: openDatexAxis,
            yAxis: yAxis,
            categoryXField: "date",
            valueYField: "value"
          })
        );
  
        movieSeries1.bullets.push(function () {
          return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Circle.new(root, {
                  radius: 3,
                  fill: movieSeries1.get("fill")!
              })
          })
        });
  
        movieSeries1.data.setAll(movieChartData1);
    
        let movieSeries2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: movieDate2.title,
            xAxis: openDatexAxis,
            yAxis: yAxis,
            categoryXField: "date",
            valueYField: "value"
          })
        );
  
        movieSeries2.bullets.push(function () {
          return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Circle.new(root, {
                  radius: 3,
                  fill: movieSeries2.get("fill")!
              })
          })
        })
  
        movieSeries2.data.setAll(movieChartData2);
  
        openDatexAxis.data.setAll(movieChartData2);
        openDatexAxis.data.setAll(movieChartData1);
    
        chart.set("cursor", am5xy.XYCursor.new(root, {
          xAxis: openDatexAxis
        }));
        
        movieSeries1.appear(1000, 100);
        movieSeries2.appear(1000, 100);
        chart.appear(1000, 100);
      }
      else {
        const movieSeries1 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: movieDate1.title,
            xAxis: CalendarxAxis,
            yAxis: yAxis,
            valueXField: "date",
            valueYField: "value"
          })
        );
  
        movieSeries1.bullets.push(function () {
          return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Circle.new(root, {
                  radius: 3,
                  fill: movieSeries1.get("fill")!
              })
          })
        });
  
        movieSeries1.data.setAll(movieChartData1);
    
        let movieSeries2 = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: movieDate2.title,
            xAxis: CalendarxAxis,
            yAxis: yAxis,
            valueXField: "date",
            valueYField: "value"
          })
        );
  
        movieSeries2.bullets.push(function () {
          return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Circle.new(root, {
                  radius: 3,
                  fill: movieSeries2.get("fill")!
              })
          })
        })
  
        movieSeries2.data.setAll(movieChartData2);
  
        CalendarxAxis.data.setAll(movieChartData2);
        CalendarxAxis.data.setAll(movieChartData1);
    
        chart.set("cursor", am5xy.XYCursor.new(root, {
          xAxis: CalendarxAxis
        }));
        
        movieSeries1.appear(1000, 100);
        movieSeries2.appear(1000, 100);
        chart.appear(1000, 100);
      }

      let legend = chart.children.push(am5.Legend.new(root, {}));
      legend.data.setAll(chart.series.values);
      
      return () => {
        root.dispose();
      };
    }, [movieData1, movieData2]);
  
    return (
      <div id="chartdiv" style={{ width: "auto", height: "400px" }}></div>
    );
}

export default BuzzChart;