interface Props {
  content: string;
  value: number | string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const MediaCount = ({ content, value, Icon }: Props) => {
  return (
    <div className="flex flex-col flex-grow items-center justify-center">
      <Icon />
      <p className="text-15 font-semibold leading-18 mt-4 mb-15">{content}</p>
      <p className="text-24 font-bold leading-36">{value}</p>
    </div>
  );
};
