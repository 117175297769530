interface Props2 {
  text?: string;
}

const CustomCellComponent = ({ text }: Props2) => {
  return (
    <>
      {text && text === '미작동' ? (
        <div className="flex p-[6px_8px] justify-center items-center rounded-[4px] bg-[rgba(255,78,72,0.1)] h-[30px] w-[55px]">
          <p className="text-[#FF4E48] text-center font-pretendard text-[15px] font-semibold">
            미작동
          </p>
        </div>
      ) : (
        <div className="flex p-[6px] justify-center items-center rounded-[4px] bg-[rgba(0,193,124,0.1)] h-[30px] w-[42px]">
          <p className="text-[#00C17C] text-center font-pretendard text-[15px] font-semibold">
            정상
          </p>
        </div>
      )}
    </>
  );
};

export default CustomCellComponent;
