import CommentCount from 'components/common/CommentAnalytics/CommentCount';
import EmotionImage from 'components/common/CommentAnalytics/EmotionImage';
import EmotionText from 'components/common/CommentAnalytics/EmotionText';

interface SentimentsProp {
  type: 'positive' | 'neutral' | 'negative';
  counts: number;
  handleClick?: () => void;
}

const Sentiments = ({ type, counts, handleClick }: SentimentsProp) => {
  return (
    <div className="flex items-center justify-between w-full cursor-pointer" onClick={handleClick}>
      <div className="flex items-center gap-10 text-center">
        <EmotionImage type={type} />
        <EmotionText type={type} />
      </div>
      <div>
        <CommentCount count={counts} />
      </div>
    </div>
  );
};

export default Sentiments;
