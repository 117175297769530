const dateFormatter = ( date : Date ) => {
    let dateStr = "";

    if (date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        dateStr = `${year}-${month}-${day}`;
    }

    return dateStr;
}

export default dateFormatter;