import { useAddEtcSum } from 'hooks/Buzz/useAddEtcSum';
import { useAddFieldTotalCount } from 'hooks/Buzz/useAddFieldTotalCount';

import { FieldBuzzData, PlatformOspCountsMap } from 'types/Buzz/BuzzType';

export const useParseFieldCount = (
  datas: PlatformOspCountsMap,
  FieldBuzzDatas: FieldBuzzData[],
) => {
  for (const [key, value] of Object.entries(datas)) {
    let entries: [string, number][] = Object.entries(value);

    if (entries.length > 4) {
      entries = useAddEtcSum(entries);
    }
    FieldBuzzDatas = useAddFieldTotalCount(key, entries, FieldBuzzDatas);
  }

  return FieldBuzzDatas;
};
