import { PageState } from 'types/Nav/menu';

import { ReactComponent as Comparison } from 'assets/svg/Comparison.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/Dashboard.svg';
import { ReactComponent as Data } from 'assets/svg/DataPage.svg';
import { ReactComponent as Management } from 'assets/svg/Management.svg';
import { ReactComponent as Medium } from 'assets/svg/Medium.svg';
import { ReactComponent as Subscription } from 'assets/svg/Subscription.svg';

interface MappingMenu {
  [key: PageState]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const mappingMenu: MappingMenu = {
  대시보드: DashboardIcon,
  '전체 데이터 통계': Data,
  '영화 비교 분석': Comparison,
  '영화 매체 분석': Medium,
  '정기 구독 관리': Subscription,
  '리포트 센터': Data,
  '관리 센터': Management,
};

export const menu2icon = (menu: PageState) => {
  return mappingMenu[menu];
};
