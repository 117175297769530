import { Dispatch, SetStateAction, useState } from 'react';

interface Prop {
  options: string[];
  state: string[];
  handleChange: Dispatch<SetStateAction<string[]>>;
}

const CategoryBtn = ({ options, state, handleChange }: Prop) => {
  const [isClick, setIsClick] = useState<string[]>([]);
  const handleClick = (id: string) => {
    if (id === '전체') {
      setIsClick([id]);
      handleChange([id]);
    } else {
      setIsClick((prev) => prev.filter((item) => item !== '전체'));
      !isClick.includes(id) && setIsClick((prev) => [...prev, id]);
      isClick.includes(id) && setIsClick(isClick.filter((item) => id !== item));
      handleChange((prev) => prev.filter((item) => item !== '전체'));
      !state.includes(id) && handleChange((prev) => [...prev, id]);
      state.includes(id) && handleChange(state.filter((item) => id !== item));
    }
  };

  return (
    <div className="flex flex-wrap ml-32 gap-8 w-[17.1vw]">
      {options.map((el, idx) => (
        <button
          key={idx}
          className={`${
            isClick.includes(el)
              ? 'bg-main text-white border-0'
              : 'border-1 border-graph-gray text-gray2'
          } flex items-center justify-center w-[4.02vw] h-38 rounded-8 text-[0.54vw]`}
          onClick={() => {
            handleClick(el);
          }}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export default CategoryBtn;
