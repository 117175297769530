import { Dispatch, SetStateAction } from 'react';

import useGetApi from 'hooks/axiosApis/useGetApi';

interface Props {
  target: string;
  setState: Dispatch<SetStateAction<string[]>>;
}

const useOspDropdown = ({ target, setState }: Props) => {
  const URL = `/osp-list/osp?platform=${target}`;
  const ospMenu = useGetApi(URL).then((data) => setState(['OSP선택'].concat(data)));

  return ospMenu;
};

export default useOspDropdown;
