import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

import Table, { RowDataType } from 'rsuite/Table';

import './DataTable.less';

export interface LabeledColumnProps {
  key: string;
  label: string;
  isCustomCell?: boolean;
  handleClick?: (id: string) => void;
  align?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
  flexGrow?: number;
}

export interface ColumnSettingProps {
  align?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
  flexGrow?: number;
}

export interface DataTableProps extends PropsWithChildren {
  datas: { [key: string]: string | string[] | number | undefined | null }[];
  columns: LabeledColumnProps[];
  rowKey: string;
}

const { Column, HeaderCell, Cell } = Table;
const HEADER_HEIGHT = 52;
const ROW_HEIGHT = 52;

const tableSettings = {
  autoHeight: true,
  hover: false,
  rowHeight: ROW_HEIGHT,
  headerHeight: HEADER_HEIGHT,
};

const getCustomCell = (children: ReactNode, key: string) => {
  return React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.key === `.\$${key}`,
  );
};

const getCloneElement = (customCell: ReactElement, text: string, id: string) => {
  return React.cloneElement(customCell, { text, id });
};

const getClickOptions = (handleClick: ((id: string) => void) | undefined, rowKey: string) => {
  return handleClick ? { onClick: () => handleClick(rowKey), className: 'cursor-pointer' } : {};
};

export const DataTable = ({ datas, columns, children, rowKey }: DataTableProps) => {
  return (
    <div className="mobile:text-8 mobile:w-full">
      <Table data={datas} rowKey={rowKey} {...tableSettings}>
        {columns.map((column) => {
          const { key, label, isCustomCell, handleClick, ...rest } = column;

          const columnSettings: ColumnSettingProps = {
            verticalAlign: 'middle',
            align: rest.align ? rest.align : 'center',
            ...rest,
          };

          return (
            <Column key={key} {...columnSettings}>
              <HeaderCell>{label}</HeaderCell>
              <Cell dataKey={key}>
                {(rowData: RowDataType) => {
                  const clickOptions = getClickOptions(handleClick, rowData[rowKey]);

                  return isCustomCell ? (
                    <div {...clickOptions}>
                      {getCloneElement(
                        getCustomCell(children, key) as ReactElement,
                        rowData[key],
                        rowData[rowKey],
                      )}
                    </div>
                  ) : (
                    <div className="truncate" {...clickOptions}>
                      {rowData[key] ?? '-'}
                    </div>
                  );
                }}
              </Cell>
            </Column>
          );
        })}
      </Table>
    </div>
  );
};
