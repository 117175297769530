import { Dispatch, SetStateAction } from 'react';

import useGetApi from 'hooks/axiosApis/useGetApi';

interface Props {
  target?: string;
  setState: Dispatch<SetStateAction<string[]>>;
}

interface Movies {
  title: string;
  openDate: string;
}

const useMovieDropdown = ({ target, setState }: Props) => {
  const url = `/movie/title?cp=${target}`;
  const movieMenu = useGetApi(url).then((data) => {
    if (!target) {
      return data.map((el: Movies) => {
        setState((prev) => [...prev, el.title]);
      });
    }
    return setState(['영화 제목'].concat(data));
  });

  return movieMenu;
};

export default useMovieDropdown;
