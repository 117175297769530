import { useEffect, useRef, useState } from 'react';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';

import { ChartLabel } from 'components/Data/BuzzReport/ChartLabel';
import { FieldChart } from 'components/Data/BuzzReport/FieldChart';
import { FieldCountContainer } from 'components/Data/BuzzReport/FieldCountContainer';
import { FilterContainer } from 'components/Data/BuzzReport/FilterContainer';
import { MovieInfoContainer } from 'components/Data/BuzzReport/MovieInfoContainer';
import { TotalChart, TotalChartProp } from 'components/Data/BuzzReport/TotalChart';
import { SelectTitle } from 'components/common/Filter/SelectTitle';
import Loading from 'components/common/Loading';
import { CompleteModal } from 'components/common/Modal/CompleteModal';
import { ConfirmModal } from 'components/common/Modal/ConfirmModal';
import { TotalAnalytics } from 'components/common/Report/TotalAnalytics';

import {
  initFieldBuzzDatas,
  initFieldChartDatas,
  initTotalAnalyticsDatas,
} from 'pages/Report/ReportBase';

import { useFetchNewReport } from 'hooks/Buzz/useFetchNewReport';
import { useGetIdFromUrl } from 'hooks/Buzz/useGetIdFromUrl';
import { useGetNewReportBody } from 'hooks/Buzz/useGetNewReportBody';
import { useParseChartCount } from 'hooks/Buzz/useParseChartCount';
import { useParseFieldCountData } from 'hooks/Buzz/useParseFieldCountData';
import { useParseTotalAnalyticsDatas } from 'hooks/Buzz/useParseTotalAnalyticsDatas';
import { useParseTotalchartData } from 'hooks/Buzz/useParseTotalchartData';
import { customedAxios } from 'hooks/axiosApis/customedAxios';
import { useGetCp } from 'hooks/axiosApis/useGetCp';

import {
  BuzzReportDatas,
  FieldBuzzData,
  FieldChartData,
  MovieInfo,
  TotalAnalyticsData,
} from 'types/Buzz/BuzzType';

export const ReportDetail = () => {
  const params = new URLSearchParams(location.search);

  const reportId = params.get('id');

  const [cp, setCp] = useState<string>('');
  const [isNewReport, setIsNewReport] = useState(!useGetIdFromUrl(window.location.href));
  const [canSave, setCanSave] = useState(reportId ? false : true);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const title = useRef<HTMLInputElement | null>(null);
  const info = useRef<HTMLInputElement | null>(null);
  const memo = useRef<HTMLTextAreaElement | null>(null);
  const printPdfRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fieldBuzzDatas, setFieldBuzzDatas] = useState<FieldBuzzData[]>(initFieldBuzzDatas);
  const [totalAnalyticsDatas, setTotalAnalyticsDatas] =
    useState<TotalAnalyticsData[]>(initTotalAnalyticsDatas);
  const [fieldChartDatas, setFieldChartDatas] = useState<FieldChartData[]>(initFieldChartDatas);
  const [totalChartProps, setTotalChartProps] = useState<TotalChartProp[]>();
  const [movieData, setMovieData] = useState<BuzzReportDatas>();
  const [movieInfo, setMovieInfo] = useState<MovieInfo>();
  const [selectedCP, setSelectedCp] = useState('');
  const [selectedMovie, setSelectedMovie] = useState('');
  const [dateRange, setDateRange] = useState<
    { startDate: string; endDate: string; dateRange?: string } | undefined
  >(undefined);
  const [dateRangeLimit, setDateRangeLimit] = useState<
    { startDate: string; endDate: string; dateRange?: string } | undefined
  >(undefined);
  const setStateActions = {
    setSelectedCp,
    setSelectedMovie,
    setDateRange,
  };
  const inputRef = { title, info };
  const confirmDialogRef = useRef<HTMLDialogElement | null>(null);
  const completeDialogRef = useRef<HTMLDialogElement | null>(null);

  const addBtnProps = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: 'drop-shadow-logo',
    },
    content: '데이터 생성',
    color: {
      text: 'text-white',
      bg: 'bg-black',
    },
    handleClick: async () => {
      if (!selectedCP || !selectedMovie || !dateRange) {
        alert('모든 옵션을 선택해주세요.');
        return;
      } else {
        fetchMovieDatas(selectedMovie);
        fetchDetailMovieDatas(selectedMovie, dateRange?.endDate);
        fetchReportDatas();
      }
    },
  };
  const saveBtnProps = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '저장',
    color: {
      text: 'text-white',
      bg: 'bg-black',
    },
    // svg: <FloppyDisk />,
    handleClick: async () => {
      const reportInput = {
        cpId: selectedCP,
        movieTitle: selectedMovie,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      };
      const timeStamp = Date.now();
      const body = useGetNewReportBody(
        reportInput,
        inputRef,
        memo,
        movieData,
        movieInfo,
        timeStamp,
        cp,
      );

      const isSaveOk = await useFetchNewReport(body);

      if (isSaveOk) await saveReportPdf(timeStamp);
    },
  };
  const editBtnProps = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '수정',
    color: {
      text: 'text-white',
      bg: 'bg-black',
    },
    handleClick: () => {
      openConfirmDialog();
    },
  };

  const saveReportPdf = async (timeStamp: number) => {
    setIsLoading(true);
    try {
      if (printPdfRef.current) {
        const element = printPdfRef.current;
        const canvas = await html2canvas(element, {
          allowTaint: true,
          useCORS: true,
        });
        const imgData = canvas.toDataURL('image/png');

        const imgWidth = 190;
        const pageHeight = imgWidth * 1.414;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const margin = 10;
        const pdf = new jsPDF('p', 'mm');

        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 20) {
          position = heightLeft - imgHeight;
          position = position - 20;

          pdf.addPage();
          pdf.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        const blob = pdf.output('blob');
        const formData = new FormData();

        const fileName = `${title.current?.value}_${timeStamp}.pdf` ?? 'buzzReport.pdf';
        formData.append('folder', 'buzz_report');
        formData.append('fileList', blob, fileName);

        await customedAxios.post('/s3/upload', formData);

        setIsLoading(false);
        alert('pdf 저장에 성공했습니다.');
      }
    } catch (e) {
      alert('pdf 저장에 실패했습니다.');
    }

    location.href = '/report';
  };

  const fetchMovieDatas = async (movieTitle: string) => {
    try {
      const { data } = await customedAxios.get(`/movie/${movieTitle}`);

      const date = moment(data.createdAt).format('YYYY-MM-DD');

      const parsedMovieInfo = {
        image: data.poster,
        startDate: data.startDate,
        endDate: data.endDate,
        reportTitle: title.current ? title.current.value : '',
        title: data.title,
        company: `${data.director} / ${data.producer} `,
        description: `${data.genre} / 개봉 ${date} / ${data.runningTime} / ${data.grade}세이용가`,
        info: inputRef.info.current ? inputRef.info.current.value : '',
      };
      setMovieInfo(parsedMovieInfo);
    } catch (e) {}
  };

  const fetchDetailMovieDatas = async (movieTitle: string, endDate: string | undefined) => {
    try {
      const { data } = await customedAxios.get(`/movie/${movieTitle}`);
      // const { data: detailData } = await customedAxios.get(
      //   `/movie-info?movieTitle=${movieTitle}&endDate=${endDate}`,
      // );

      const date = moment(data.createdAt).format('YYYY-MM-DD');

      const parsedMovieInfo = {
        image: data.poster,
        startDate: data.startDate,
        endDate: data.endDate,
        reportTitle: title.current ? title.current.value : '',
        title: data.title,
        company: `${data.director} / ${data.producer} `,
        description: `${data.genre} / 개봉 ${date} / ${data.runningTime} / ${data.grade}세이용가`,
        info: inputRef.info.current ? inputRef.info.current.value : '',
      };
      setMovieInfo(parsedMovieInfo);
    } catch (e) {}
  };

  const fetchReportDatas = async () => {
    try {
      const { data } = await customedAxios.get(
        `/movie-buzz-data?cpId=${selectedCP}&movieTitle=${selectedMovie}&startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`,
      );

      if (
        Object.keys(data.platformOspCountsMap).length !== 0 &&
        Object.keys(data.platformDateCountsMap).length !== 0
      ) {
        setIsFilterDisabled(true);
        setIsNewReport(false);
        setMovieData(data);

        let parsedFieldBuzzDatas = fieldBuzzDatas;
        let parsedTotalAnalyticsDatas = totalAnalyticsDatas;
        let parsedTotalChartProps = totalChartProps;
        let parsedFieldChartDatas = fieldChartDatas;

        ({ parsedFieldBuzzDatas, parsedTotalAnalyticsDatas } = useParseTotalAnalyticsDatas(
          data.platformOspCountsMap,
          parsedFieldBuzzDatas,
          parsedTotalAnalyticsDatas,
        ));

        parsedTotalChartProps = useParseTotalchartData(data.platformDateCountsMap);
        parsedFieldBuzzDatas = useParseFieldCountData(
          data.platformOspCountsMap,
          data.platformDateCountsMap,
          parsedFieldBuzzDatas,
          parsedFieldChartDatas,
        );

        parsedFieldBuzzDatas = useParseFieldCountData(
          data.platformOspCountsMap,
          data.platformDateCountsMap,
          parsedFieldBuzzDatas,
          parsedFieldChartDatas,
        );
        parsedFieldChartDatas = useParseChartCount(data.platformDateCountsMap, fieldChartDatas);
        setTotalAnalyticsDatas(parsedTotalAnalyticsDatas);
        setTotalChartProps(parsedTotalChartProps);
        setFieldBuzzDatas(parsedFieldBuzzDatas);
        setFieldChartDatas(parsedFieldChartDatas);
      } else {
        alert('범위 내에 데이터가 없습니다.');
      }
    } catch (e) {
      alert('보고서 데이터를 불러오는데에 실패했습니다.');
      console.error(e);
    }
  };

  const fetchModifyReportDatas = async () => {
    try {
      const { data } = await customedAxios.get(`/report/${reportId}`);

      const endDate = data.dateRange.split(' ~ ')[1];

      fetchMovieDatas(data.movieTitle);
      fetchDetailMovieDatas(data.movieTitle, endDate);
      setSelectedCp(data?.cpId);
      setSelectedMovie(data?.movieTitle);

      if (title.current) {
        title.current.value = data.reportTitle;
      }

      if (info.current) {
        info.current.value = data.movieOtherInformation;
      }

      if (memo.current) {
        memo.current.value = data.reportMemo;
      }

      const parsedData = JSON.parse(data?.reportProcessedData ?? '{}');

      let parsedFieldBuzzDatas = fieldBuzzDatas;
      let parsedTotalAnalyticsDatas = totalAnalyticsDatas;
      let parsedTotalChartProps = totalChartProps;
      let parsedFieldChartDatas = fieldChartDatas;

      ({ parsedFieldBuzzDatas, parsedTotalAnalyticsDatas } = useParseTotalAnalyticsDatas(
        parsedData.movieData.platformOspCountsMap,
        parsedFieldBuzzDatas,
        parsedTotalAnalyticsDatas,
      ));
      parsedTotalChartProps = useParseTotalchartData(parsedData.movieData.platformDateCountsMap);
      parsedFieldBuzzDatas = useParseFieldCountData(
        parsedData.movieData.platformOspCountsMap,
        parsedData.movieData.platformDateCountsMap,
        parsedFieldBuzzDatas,
        parsedFieldChartDatas,
      );
      parsedFieldChartDatas = useParseChartCount(
        parsedData.movieData.platformDateCountsMap,
        parsedFieldChartDatas,
      );

      setTotalAnalyticsDatas(parsedTotalAnalyticsDatas);
      setTotalChartProps(parsedTotalChartProps);
      setFieldBuzzDatas(parsedFieldBuzzDatas);
      setFieldChartDatas(parsedFieldChartDatas);
    } catch (e) {
      alert('보고서 데이터를 불러오는데에 실패했습니다.');
      console.error(e);
    }
  };

  const modifyReportDatas = async () => {
    const titleValue = title.current?.value;
    const infoValue = info.current?.value;
    const memoValue = memo.current?.value;

    if (!title) alert('보고서 제목을 입력해주세요');
    else {
      try {
        const body = {
          reportIdx: reportId,
          newTitle: titleValue,
          newMovieOtherInformation: infoValue || null,
          newMemo: memoValue || null,
        };

        await customedAxios.patch('/report', body);
      } catch (e) {
        alert('보고서 수정에 실패했습니다.');
        console.error(e);
      }
    }
  };

  const editDateRangeLimit = async () => {
    if (selectedMovie) {
      const { data } = await customedAxios.get(`/movie/${selectedMovie}`);
      const startDate = moment(data.startDate).format('YYYY-MM-DD');
      const endDate = moment(data.endDate).format('YYYY-MM-DD');

      setDateRangeLimit({ startDate, endDate });
    }
  };

  const openConfirmDialog = () => {
    confirmDialogRef.current && confirmDialogRef.current.showModal();
  };

  const openCompleteDialog = () => {
    completeDialogRef.current && completeDialogRef.current.showModal();
  };

  const closeConfirmDialog = () => {
    confirmDialogRef.current && confirmDialogRef.current.close();
  };

  const closeCompleteDialog = () => {
    completeDialogRef.current && completeDialogRef.current.close();
  };

  useEffect(() => {
    useGetCp({ setState: setCp });
  }, []);

  useEffect(() => {
    editDateRangeLimit();
  }, [selectedMovie]);

  useEffect(() => {
    if (reportId) {
      setIsFilterDisabled(true);
      fetchModifyReportDatas();
    }
  }, [reportId]);

  return (
    <>
      {isLoading && <Loading type="spokes" color="#FF7B31" />}
      <div ref={printPdfRef}>
        <FilterContainer
          setStateActions={setStateActions}
          pillBtnProps={isNewReport ? addBtnProps : canSave ? saveBtnProps : editBtnProps}
          isFilterDisabled={isFilterDisabled}
          values={{ selectedCP, selectedMovie, dateRangeLimit }}
        />
        <div className="w-full mt-24 overflow-x-auto bg-white p-28 rounded-24">
          <MovieInfoContainer data={movieInfo} inputRef={inputRef} />
          <div className="flex flex-col mt-72 w-[2170px]">
            <SelectTitle title="전체 버즈량 분석" />
            <div className="relative flex mt-20">
              <TotalAnalytics datas={totalAnalyticsDatas} />
              <div>
                <ChartLabel />
                <TotalChart datas={totalChartProps ? totalChartProps : []} />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-72 w-[2170px]">
            <SelectTitle title="분야별 통계 분석" />
            <div className="flex flex-wrap">
              <FieldCountContainer datas={fieldBuzzDatas} />
            </div>
          </div>
          <div className="-mt-42">
            {fieldChartDatas.map((data, index) => (
              <div key={index}>
                <FieldChart data={data} />
              </div>
            ))}
          </div>
          <textarea
            ref={memo}
            className="w-full h-[208px] pt-20 pl-12 mt-24 outline-none resize-none border-light-gray2 border-1 text-18 font-noto"
            placeholder="이슈메모 들어가는 칸입니다 아무거나 적을 수 있음 박스 밖으로 글자 나갈만큼 메모 많을 경우 스크롤 해주세요"
          ></textarea>
        </div>

        <ConfirmModal
          ref={confirmDialogRef}
          close={() => closeConfirmDialog()}
          open={() => openCompleteDialog()}
          postFunc={() => modifyReportDatas()}
        />

        <CompleteModal ref={completeDialogRef} close={() => closeCompleteDialog()} />
      </div>
    </>
  );
};

export default ReportDetail;
