import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
import { Poster} from "types/Medium/medium";

interface MediumContext {
    posters : Poster[];
    setPosters: Dispatch<SetStateAction<Poster[]>>;
}

const MediumContext = createContext<MediumContext>({
    posters: [],
    setPosters: () => {
        return;
    }
})

export function MediumContextProvider({ children }: PropsWithChildren) {
    const [posters, setPosters] = useState<Poster[]>([]);

    const values = {
        posters,
        setPosters
    }

    return <MediumContext.Provider value={values}>{children}</MediumContext.Provider>
}

export function useMediumContext() {
    return useContext(MediumContext);
  }