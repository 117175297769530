import { useEffect, useRef, useState } from 'react';

import PosterCard from 'components/Medium/PosterCard';
import { IMAGE_API_URL } from 'hooks/apis/apiUrl';
import useGetApi from 'hooks/apis/useGetApi';
import {ReactComponent as More} from '../../assets/svg/MoreButton.svg';

import { Poster } from 'types/Medium/medium';
import { useMediumContext } from 'contexts/Medium/MediumContext';

const MoviePosterCardContainer = () => {
  const { data : posterData } = useGetApi('movieList', '/movie/dashboard');
  const [count, setCount] = useState<number>(handleCount());
  const [incCount, setIncCount] = useState<number>(handleCount());
  const [total, setTotal] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const { posters, setPosters } = useMediumContext();

  const showMore = () => {
    setCount((prev) => {
      if (total >= prev + incCount) {
        return prev + incCount;
      }
      else {
        return total;
      }
    });
  }

  function handleCount() {
    if (window.innerWidth <= 768) {
      if (Math.floor((window.innerWidth - 100) / 280) * 2 === 0) {
        return 1;
      }
      else {
        return Math.floor((window.innerWidth - 100) / 280) * 2;
      }
    }
    else {
      return Math.floor((window.innerWidth - 360) / 280) * 2
    } 
  }

  const handleScroll = () => {
    if (window.scrollY >= containerRef?.current?.offsetTop! + containerRef?.current?.offsetHeight! - window.innerHeight) {
      showMore();
    }
  }

  const handleResize = () => {
    const rowCount = handleCount() >= 1 ? handleCount() : 1;

    if (count % rowCount !== 0) {
      if ((Math.floor(count / rowCount) + 1) * rowCount >= total) {
        setCount(total);
      }
      else {
        setCount((Math.floor(count / rowCount) + 1) * rowCount);
      }
    }

    setIncCount(rowCount * 2);
  }

  useEffect(() => {
    if(count === 0) {
      setCount(1);
      setIncCount(1);
    }
    if(posterData && posterData.posterList) {
      setPosters(posterData?.posterList);
      setTotal(posterData?.posterList?.length);
    }
  }, [posterData, count]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    }
  });

  return (
    <div ref={containerRef} className="relative grid grid-cols-influencer justify-center gap-20 bg-white rounded-14 w-full h-auto shadow-medium py-40 px-30">
      {posters?.map(({ contentTitle, openDate, poster }: Poster, index) => {
        const resolvedPoster = poster.includes('http') ? poster : `${IMAGE_API_URL}${poster}`;
        
        if (index < count) {
          return (
            <PosterCard
              key={contentTitle}
              img={resolvedPoster}
              content={contentTitle}
              date={openDate}
            />
          );
        }
        else {
          return;
        }
      })}
      {total > count &&

          <More
            className='absolute justify-center h-55 left-[calc(50%-35px)] bottom-[-20px] cursor-pointer animate-wiggle'
            onClick={() => showMore()}
          />
      }
    </div>
  );
};

export default MoviePosterCardContainer;
