import { Dispatch, FunctionComponent, SetStateAction, SVGProps, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { eng2kor } from 'utilities/Header/eng2kor';

import { removeCookie } from 'hooks/cookie';

import { ReactComponent as Comparison } from 'assets/svg/Comparison.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/Dashboard.svg';
import { ReactComponent as Data } from 'assets/svg/DataPage.svg';
import { ReactComponent as Management } from 'assets/svg/ManagementIcon.svg';
import { ReactComponent as Medium } from 'assets/svg/Medium.svg';
import { ReactComponent as Subscription } from 'assets/svg/Subscription.svg';
import { ReactComponent as LogoSM } from 'assets/svg/Logo_sm.svg';
import { ReactComponent as Logout } from 'assets/svg/Logout.svg';
import { ReactComponent as Menubar } from 'assets/svg/Menubar.svg';
import { ReactComponent as Star } from 'assets/svg/Star.svg';
import defaultImage from "../../../../assets/image/defaultProfileImage.png";

interface Props {
  name: string;
  profileImage: string;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ name, profileImage, setOpenMenu }: Props) => {
  const [header, setHeader] = useState('/');
  const pathname = useLocation().pathname;
  const headerName = eng2kor(location.pathname);
  const [icon, setIcon] = useState<SVGProps<SVGSVGElement>>(<DashboardIcon className="w-36 h-36 stroke-logo fill-logo" />);

  const rmCookie = () => {
    removeCookie('loginToken');
    removeCookie('name');
    removeCookie('profileImage');

    window.location.href = '/';
  };

  const handleIcon = () => {
    switch (true) {
      case pathname.includes('/dashboard'):
        setIcon(<DashboardIcon className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      case pathname.includes('/comparison'):
        setIcon(<Comparison className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      case pathname.includes('/data'):
        setIcon(<Data className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      case pathname.includes('/management'):
        setIcon(<Management className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      case pathname.includes('/medium'):
        setIcon(<Medium className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      case pathname.includes('/subscription'):
        setIcon(<Subscription className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      case pathname.includes('/report'):
        setIcon(<Data className="w-36 h-36 stroke-logo fill-logo" />);
        break;

      default:
        setIcon(<DashboardIcon className="w-36 h-36 stroke-logo fill-logo" />);
        break;

    }
  }

  useEffect(() => {
    setHeader(headerName!);
    handleIcon();
  }, [pathname]);

  return (
    <div className="w-full bg-white h-74 px-30 border-b-1 border-[#EAEAEA] mobile:bg-[#0E1927]">
      <div className="flex items-center justify-between w-full h-full mobile:hidden">
        <div className="flex items-center">
          {/* <Icon className="w-36 h-36 stroke-logo fill-logo" /> */}
          <>{icon}</>
          <p className="font-[700] text-24 leading-28">{header}</p>
        </div>
        <div className="flex items-center gap-10">
          <div className="relative w-44 h-44 bg-[#D9CBF6] rounded-22">
            <img className="rounded-50" src={profileImage !== "null" ? profileImage : defaultImage} alt="ProfileExample" />
            <div className="absolute top-25 -right-5 flex justify-center items-center w-20 h-20 rounded-50 bg-[#FF850099]">
              <Star className="w-12 h-12 " />
            </div>
          </div>
          <p className="text-14">
            <span className="font-[700]">{name}</span> 님
          </p>
          <button
            onClick={() => rmCookie()}
            className="flex items-center justify-center gap-2 w-78 h-30 border-1 text-[#656565] border-[#E2E2E2] rounded-4 text-12 font-[500] leading-14"
          >
            로그아웃
            <Logout />
          </button>
        </div>
      </div>
      <div className="items-center justify-between hidden w-full h-full mobile:flex">
        <LogoSM />
        <Menubar className="cursor-pointer" onClick={() => setOpenMenu((prev) => !prev)} />
      </div>
    </div>
  );
};

export default Header;
