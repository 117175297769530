import { useAddTotalCount } from 'hooks/Buzz/useAddTotalCount';
import { useSetTotalCount } from 'hooks/Buzz/useSetTotalCount';

import { FieldBuzzData, PlatformOspCountsMap, TotalAnalyticsData } from 'types/Buzz/BuzzType';

import { useParseDatas } from './useParseDatas';

export const useParseTotalAnalyticsDatas = (
  datas: PlatformOspCountsMap,
  fieldBuzzDatas: FieldBuzzData[],
  totalAnalyticsDatas: TotalAnalyticsData[],
) => {
  const inputKeys = ['포털', 'SNS', '커뮤니티', 'NEWS'];
  let totalCount = 0;

  totalCount = useAddTotalCount(datas, inputKeys, totalCount);
  if (totalAnalyticsDatas[0]) {
    totalAnalyticsDatas[0] = useSetTotalCount(totalAnalyticsDatas[0], totalCount);
  }
  if (fieldBuzzDatas[0]) {
    fieldBuzzDatas[0] = useSetTotalCount(fieldBuzzDatas[0], totalCount);
  }

  ({ fieldBuzzDatas, totalAnalyticsDatas } = useParseDatas(
    datas,
    inputKeys,
    totalCount,
    fieldBuzzDatas,
    totalAnalyticsDatas,
  ));

  return { parsedFieldBuzzDatas: fieldBuzzDatas, parsedTotalAnalyticsDatas: totalAnalyticsDatas };
};
