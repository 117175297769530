import { ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType;
}

const Keyword = ({ data }: Prop) => {
  const { content, sentiments } = data;

  return (
    <div className="flex w-full gap-12 mt-18 mobile:flex-col">
      <div className="flex flex-col gap-12">
        <div className="text-dark-gray text-14">키워드</div>
        <div
          className={`flex items-center pl-12 w-[630px] h-42 bg-light-gray rounded-12 mobile:w-full mobile:text-14`}
        >
          {content}
        </div>
      </div>

      <div className="flex flex-col gap-12 mobile:mt-5">
        <div className="text-14 text-dark-gray ">키워드 분류</div>
        <div className="flex bg-light-gray w-[360px] h-42 rounded-12 mobile:w-full mobile:text-14">
          <div
            className={`flex items-center justify-center w-full ${
              sentiments === 'POSITIVE'
                ? 'text-white bg-very-dark-gray rounded-l-12'
                : 'text-very-dark-gray'
            }`}
          >
            긍정
          </div>
          <div
            className={`flex items-center justify-center w-full ${
              sentiments === 'NEUTRAL' ? 'text-white bg-very-dark-gray' : 'text-very-dark-gray'
            }`}
          >
            중립
          </div>
          <div
            className={`flex items-center justify-center w-full ${
              sentiments === 'NEGATIVE'
                ? 'text-white bg-very-dark-gray rounded-r-12'
                : 'text-very-dark-gray'
            }`}
          >
            부정
          </div>
        </div>
      </div>
    </div>
  );
};

export default Keyword;
