import { Dispatch, SetStateAction } from 'react';

type Prop = {
  target: string;
  setSentiment: Dispatch<SetStateAction<string | undefined>>;
};

const useConvertSentiment = ({ target, setSentiment }: Prop) => {
  switch (target) {
    case '긍정글':
      setSentiment('POSITIVE');
      return;
    case '중립글':
      setSentiment('NEUTRAL');
      return;
    case '부정글':
      setSentiment('NEGATIVE');
      return;
  }
};

export default useConvertSentiment;
