import TableContainer from 'components/Management/TotalData/container/TableContainer';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { TotalAmountContextProvider } from 'contexts/Management/TotalAmountContext';

import { ReactComponent as ManagementIcon } from 'assets/svg/ManagementIcon.svg';

const TotalData = () => {
  return (
    <TotalAmountContextProvider>
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="관리센터"
          path2="전체데이터 현황"
          Icon={ManagementIcon}
          title="전체데이터 현황"
        />
      </div>
      <div className="flex flex-col overflow-auto">
        {/* <FilterContainer /> */}
        <TableContainer />
      </div>
    </TotalAmountContextProvider>
  );
};

export default TotalData;
