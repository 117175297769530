import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useMovieDetailContext } from "contexts/dashboard/MovieDetailContext";
import { useMovieIdxContext } from "contexts/dashboard/MovieIdxContext";

const Top10Table = () => {
    const url = "https://web-movieboard.com/api/movie-top-ten/detail";
    const { movieIdx, setMovieIdx } = useMovieIdxContext();
    const { movieDetailList, setMovieDetailList } = useMovieDetailContext();

    const navigate = useNavigate();

    const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      };
    
      const getDatas = async () => {
        try {
          const res = await fetch(url, options);
          if (!res.ok) {
            const errorResponse = await res.json();
            throw new Error(errorResponse.message);
          }
    
          const data = await res.json();
          return data;
        } catch (error) {
          console.error(error);
        }
      };
    
      const { data, isLoading} = useQuery({
        queryKey: ['Top10Table'],
        queryFn: getDatas,
      });
    
      useEffect(() => {
        setMovieDetailList(data);
      }, [data, movieDetailList]);

    return (
        <div className="flex flex-col w-full gap-16 box-border border-1 border-[#EAEAEA] rounded-14 bg-white shadow-lg overflow-x-auto">
        <table className="min-w-[1040px]">
          <thead className="h-50 text-15 text-center font-bold">
            <tr className="h-50 [&>th]:h-50">
              <th>영화명</th>
              <th>노출도 합</th>
              <th>수집일</th>
              <th>노출평균</th>
              <th>누적 관객수</th>
              <th>예매율</th>
              <th></th>
            </tr>
          </thead>
          {
            !isLoading &&
            <tbody>
                {movieDetailList?.map((item) => {
                let activeStyleString =
                    movieIdx! === item?.idx
                    ? ' [&>td>div>p]:text-[#EE8B1D] bg-[#FFFCF2] [&>td>span]:bg-[#FF8B00] [&>td>span]:text-white'
                    : ' hover:bg-[#FFFCF2] [&:hover>td>div>p]:text-[#EE8B1D] [&:hover>td>span]:bg-[#FF8B00] [&:hover>td>span]:text-white';
                let gradeStyleString = Number(item?.grade) <= 3 ? ' bg-[#EE8B1D]' : ' bg-[#8A8A8A]';
                let titleStyleString = Number(item?.grade) <= 3 ? ' font-bold' : ' font-normal';

                return (
                    <tr
                      key={item?.grade}
                      className={'h-50 border-t-1 border-[#E3E3E3] text-[#616161]' + activeStyleString}
                    >
                    <td
                        className="flex items-center h-50 gap-20 pl-20 box-border [@media(max-width:1048px)]:justify-between"
                        width={"15%"}
                    >
                        <div className='flex justify-between items-center gap-10'>
                          <div
                              className={
                                'flex justify-center items-center min-w-24 min-h-24 rounded-10 text-15 text-white font-semibold' +
                                gradeStyleString
                              }
                          >
                              <span className="text-13 font-normal">{item.grade}</span>
                          </div>
                          <p
                              className={'max-w-[200px] text-start text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer' + titleStyleString}
                              onClick={() => {
                                setMovieIdx(item?.idx);
                              }}
                          >{item?.title}</p>
                        </div>
                    </td>
                    <td className="text-center" width={"15%"}>{item?.impressionTotalCount?.toLocaleString()!}</td>
                    <td className="text-center" width={"15%"}>{item?.crawlingCount?.toLocaleString()!}</td>
                    <td className="text-center" width={"15%"}>{item?.impressionAverage?.toLocaleString()!}</td>
                    <td className="text-center" width={"15%"}>{item?.watchedTotalCount?.toLocaleString()!}</td>
                    <td className="text-center" width={"15%"}>{item?.ticketingPercent!}</td>
                    <td className="text-center" width={"10%"}>
                        <span
                          className='h-30 min-w-75 px-8 py-6 box-border border-1 border-solid border-[#FF8B00] rounded-4 text-15 font-semibold text-[#FF8B00] text-center cursor-pointer'
                          onClick={() => {
                              navigate('/data', {state : {cp : item?.cp_id, movie: item?.title}});
                          }}
                        >
                          상세보기
                        </span>
                    </td>
                    </tr>
                );
                })}
            </tbody>
        }
        </table>
        {
            isLoading &&
            <div className="flex justify-center items-center w-full py-30">
                Loading...
            </div>
        }
      </div>
    )
}

export default Top10Table;