import ReactLoading, { LoadingType } from 'react-loading';

interface Props {
  type: LoadingType;
  color: string;
}

const Loading = ({ type, color }: Props) => {
  return (
    <section className="absolute top-0 left-0 flex items-center justify-center w-full h-screen">
      <ReactLoading type={type} color={color} height={100} width={100} />
    </section>
  );
};

export default Loading;
