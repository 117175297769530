import BuzzChart from "./BuzzChart";

const BuzzSection = () => {

  return (
    <div className="flex flex-col w-full gap-14">
        <span className='w-full text-[#222] text-20 font-bold leading-[30px]'>
          전체 버즈량 그래프
        </span>

        <div className='flex flex-col w-full p-24 border-1 border-solid border-[#F1F1F1] rounded-14 bg-[#fff] shadow-lg'>
          <BuzzChart />
        </div>
    </div>
  )
}

export default BuzzSection;