import { useState } from 'react';

import BuzzSection from 'components/Comparison/BuzzSection';
import EmotionSection from 'components/Comparison/EmotionSection';
import MovieCompareSection from 'components/Comparison/MovieCompareSection';
import Versus from 'components/Comparison/Versus';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { ComparisonDataProvider } from 'contexts/comparison/ComparisonDataContext';
import { MovieDataProvider } from 'contexts/comparison/MovieDataContext';
import { SearchDataProvider } from 'contexts/comparison/SearchDataContext';
import { SelectDataProvider } from 'contexts/comparison/SelectDataContext';

import { ReactComponent as ComparisonIcon } from 'assets/svg/ComparisonIcon.svg';

const Comparison = () => {
  const [searched, setSearched] = useState<boolean>(false);

  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle path1="영화 비교 분석" Icon={ComparisonIcon} title="영화 비교 분석" />
      </div>
      <div className="flex flex-col w-full h-full gap-50 overflow-x-auto">
        <ComparisonDataProvider>
          <MovieDataProvider>
            <SearchDataProvider>
              <SelectDataProvider>
                <Versus setSearched={setSearched} />
                {!searched ? (
                  <div className="flex justify-center items-center w-full min-h-450">
                    <img src="/searchHand.png" alt="searchHand" draggable={false} />
                  </div>
                ) : (
                  <div className="flex flex-col w-full gap-50">
                    <BuzzSection />
                    <MovieCompareSection />
                    <EmotionSection />
                  </div>
                )}
              </SelectDataProvider>
            </SearchDataProvider>
          </MovieDataProvider>
        </ComparisonDataProvider>
      </div>
    </>
  );
};

export default Comparison;
