import useGetApi from 'hooks/apis/useGetApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  img: string;
  content: string;
  date: string;
  dataNumArray?: number[];
}

const PosterCard = ({ img, content, date }: Props) => {
  const navigate = useNavigate();

  const { data : fbData } = useGetApi(`fb-${encodeURI(content)}`, `/movie-medium-analysis/facebook/${encodeURI(content)}`);
  const { data : cgvData } = useGetApi(`cgv-${encodeURI(content)}`, `/movie-medium-analysis/cgv/${encodeURI(content)}`);
  const { data : naverData } = useGetApi(`naver-${encodeURI(content)}`, `/movie-medium-analysis/naver/${encodeURI(content)}`);

  const countDatas = [fbData && fbData?.totalCount!, cgvData && cgvData?.totalCount!, naverData && naverData?.totalCount!];

  const handleClick = (type: string) => {
    navigate(`/medium/${type}/${encodeURI(content)}`);
  };

  const snsList = [
    {
      label: 'facebook',
      button: '페이스북'
    }, 
    {
      label: 'cgv',
      button: 'CGV'
    }, 
    {
      label: 'naver',
      button: '네이버'
    }, 
  ];

  useEffect(() => {

  }, [fbData, cgvData, naverData]);

  return (
    <div className="relative w-[292px] h-[420px] border-1 border-[#eaeaea] rounded-10">
      <img
        src={img}
        alt="movie poster"
        className="absolute top-0 left-0 object-cover w-full h-full rounded-10"
      />
      <div className="absolute bottom-0 left-0 w-full h-[128px] p-28 bg-black/60 backdrop-blur-sm">
        <p className="overflow-hidden font-bold text-center text-white text-20 leading-24 text-ellipsis whitespace-nowrap">
          {content}
        </p>
        <p className="mt-6 font-bold text-center text-white text-13">
          개봉일 <span className="font-normal">{date}</span>
        </p>
        <div className="flex justify-center gap-6 mt-14">
          {snsList.map((item, index) => {
            
            return (
              <button
                key={index}
                onClick={() => {
                  handleClick(item.label);
                }}
                className={countDatas[index] === 0 || !countDatas[index] ? 
                  "flex items-center justify-center font-bold text-white text-12 leading-20 w-58 h-28 bg-black/80 rounded-14"
                  : "flex items-center justify-center font-bold text-white text-12 leading-20 w-58 h-28 bg-[#EE8B1D] rounded-14"
                }
                disabled={countDatas[index] === 0 || !countDatas[index]}
              >
                {item.button}
            </button>
          )
          })}
        </div>
      </div>
    </div>
  );
};

export default PosterCard;
