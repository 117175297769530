import { FieldChartData, PlatformDateCountsMap } from 'types/Buzz/BuzzType';

export const useParseChartCount = (
  datas: PlatformDateCountsMap,
  fieldChartDatas: FieldChartData[],
) => {
  fieldChartDatas.map((item) =>
    item.values.map((datas) => {
      datas.data = [];
    }),
  );

  for (const [key, value] of Object.entries(datas)) {
    for (const [keyOfValue, valueOfValue] of Object.entries(value)) {
      fieldChartDatas.map((item) => {
        if (item.title === key) {
          const date = `${keyOfValue.split('-')[1]}.${keyOfValue.split('-')[2]}`;
          item.values[0] &&
            item.values[0].data.push({
              date,
              value: valueOfValue.positiveCounts,
            });
          item.values[1] &&
            item.values[1].data.push({
              date,
              value: valueOfValue.negativeCounts,
            });
          item.values[2] &&
            item.values[2].data.push({
              date,
              value: valueOfValue.neutralityCounts,
            });
        }
      });
    }
  }

  return fieldChartDatas;
};
