import { useState } from 'react';

import axios from 'axios';

import PillBtn from 'components/common/Button/PillBtn';
import { FilterTitle } from 'components/common/Filter/FilterTitle';

import { MovieProps } from 'types/Subscription/KMDbMovies';

import SearchResults from './SearchResults';

const KMDbFilter = () => {
  const [movieTitle, setMovieTitle] = useState('');
  const [searchResults, setSearchResults] = useState<MovieProps[]>([]);
  const [isResultsVisible, setIsResultsVisible] = useState(false);
  const [searchContent, setSearchContent] = useState('');

  const handleSearch = async () => {
    if (movieTitle.trim() === '') {
      alert('영화 제목을 입력해 주세요.');
      return;
    }

    const URL = `https://api.koreafilm.or.kr/openapi-data2/wisenut/search_api/search_json2.jsp?collection=kmdb_new2&ServiceKey=83AO88919W7CWRQ70092&listCount=100&title=${movieTitle}`;

    try {
      const response = await axios.get(URL);
      const results = response.data.Data[0]?.Result || [];
      const content = response.data.KMAQuery;

      setSearchContent(content);

      const movies = results
        .map((item: MovieProps) => {
          const title = item.title
            .replace(/!HS|!HE/g, '')
            .replace(/\s+/g, ' ')
            .trim()
            .replace(/(\S) (\S)/g, '$1$2');
          const repRlsDate = item.repRlsDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
          const posters = item.posters.split('|')[0];

          return {
            title,
            repRlsDate,
            posters,
          };
        })

        .filter((movie: MovieProps) => movie.repRlsDate !== '')
        .sort(
          (a: MovieProps, b: MovieProps) =>
            new Date(b.repRlsDate).getTime() - new Date(a.repRlsDate).getTime(),
        );

      setSearchResults(movies);
      setIsResultsVisible(true);
    } catch (error) {
      console.error(error);
    }
  };

  const pdfBtnProps = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: '',
    },
    content: '검색',
    color: {
      text: 'text-white',
      bg: 'bg-[#FF9016]',
    },
    handleClick: () => handleSearch(),
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (movieTitle.trim() === '') {
        alert('영화 제목을 입력해 주세요.');
      } else {
        handleSearch();
      }
    }
  };

  return (
    <div>
      <div className="w-full bg-white rounded-14 py-30 shadow-box mt-50 mb-50">
        <FilterTitle title="검색" />
        <div className="flex pl-30 mt-25 gap-14">
          <span className="flex items-center text-17">영화제목</span>
          <input
            className="border-1 border-[#eaeaea] rounded-4 text-14 w-[450px] py-16 pl-14 focus:outline-[#FF9016]"
            placeholder="영화제목 입력"
            type="text"
            value={movieTitle}
            onChange={(e) => setMovieTitle(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <PillBtn pillBtnProps={pdfBtnProps} />
        </div>
      </div>

      {/* 검색 결과 영역 */}
      {isResultsVisible && (
        <>
          {searchResults.length > 0 ? (
            <SearchResults searchContent={searchContent} searchResults={searchResults} />
          ) : (
            <div className="mt-10 text-18">검색 결과가 없습니다.</div>
          )}
        </>
      )}
    </div>
  );
};

export default KMDbFilter;
