import { DateRange } from 'rsuite/esm/DateRangePicker';

interface Props {
  start: DateRange;
  end: DateRange;
}

const useConvertDate = ({ start, end }: Props) => {
  const startDate = `${String(start).split(' ')[3]}-${convertMonth(
    String(start).split(' ')[1] || '',
  )}-${String(start).split(' ')[2]}T00:00:00`;
  const endDate = `${String(end).split(' ')[3]}-${convertMonth(String(end).split(' ')[1] || '')}-${
    String(end).split(' ')[2]
  }T23:59:00`;

  return { startDate: startDate, endDate: endDate };
};

export const convertMonth = (month: string) => {
  switch (month) {
    case 'Jan':
      return '01';
    case 'Feb':
      return '02';
    case 'Mar':
      return '03';
    case 'Apr':
      return '04';
    case 'May':
      return '05';
    case 'Jun':
      return '06';
    case 'Jul':
      return '07';
    case 'Aug':
      return '08';
    case 'Sep':
      return '09';
    case 'Oct':
      return '10';
    case 'Nov':
      return '11';
    case 'Dec':
      return '12';
  }

  return;
};

export default useConvertDate;
