import KMDbFilter from 'components/Subscription/KMDbFilter';
import NonSubscriberBg from 'components/Subscription/NonSubscriberBg';
import TopPicks from 'components/Subscription/TopPicks';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { ReactComponent as SubscriptionIcon } from 'assets/svg/TicketIcon.svg';

const RegisMovie = () => {
  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="정기구독 관리"
          path2="내 영화 등록"
          Icon={SubscriptionIcon}
          title="내 영화 등록"
        />
      </div>
      <NonSubscriberBg />
      <TopPicks />
      <KMDbFilter />
    </>
  );
};

export default RegisMovie;
