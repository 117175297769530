import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { BuzzDatasProps } from 'types/BuzzAmount/buzzAmountType';

const DatasContext = createContext<BuzzDatasProps>({
  totalData: 0,
  setTotalData: 0,
  positive: 0,
  setPositive: 0,
  neutral: 0,
  setNeutral: 0,
  negative: 0,
  setNegative: 0,
});

export function DatasProvider({ children }: PropsWithChildren) {
  const [totalData, setTotalData] = useState<number>(0);
  const [positive, setPositive] = useState<number>(0);
  const [neutral, setNeutral] = useState<number>(0);
  const [negative, setNegative] = useState<number>(0);

  const value = {
    totalData,
    setTotalData,
    positive,
    setPositive,
    neutral,
    setNeutral,
    negative,
    setNegative,
  };

  return <DatasContext.Provider value={value}>{children}</DatasContext.Provider>;
}

export function useDatasContext() {
  return useContext(DatasContext);
}
