import { DateMediaList } from 'types/Medium/naver';

export const reformDataForGraph = (dateCgvList: DateMediaList[]) => {
  let newDataList: any = [];

  dateCgvList?.map((facebook) => {
    newDataList.push({
      idx: facebook.idx,
      movie: facebook.movie,
      date: facebook.createdAt.replace('T', ' '),
      likeCount: facebook.likeCount.toLocaleString(),
      netizenScore: facebook.netizenScore.toLocaleString(),
      watchedScore: facebook.watchedScore.toLocaleString(),
    });
  });

  return newDataList;
};
