import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

export interface SearchDateProps {
    startDate : Date,
    endDate : Date,
    option : "openDate" | "useCalendar",
    setStartDate : Dispatch<SetStateAction<Date>>,
    setEndDate : Dispatch<SetStateAction<Date>>,
    setOption : Dispatch<SetStateAction<"openDate" | "useCalendar">>,
};

const SearchDataContext = createContext<SearchDateProps>({
    startDate: new Date,
    endDate: new Date,
    option: "openDate",
    setStartDate : () => {
        return;
    },
    setEndDate : () => {
        return;
    },
    setOption : () => {
        return;
    },
});

export const SearchDataProvider = ({ children } : PropsWithChildren ) => {
    const [startDate, setStartDate] = useState<Date>(new Date);
    const [endDate, setEndDate] = useState<Date>(new Date);
    const [option, setOption] = useState<"openDate" | "useCalendar">("openDate");

    const values = {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        option,
        setOption
    }

    return <SearchDataContext.Provider value={values}>{children}</SearchDataContext.Provider>
};

export const useSearchDataContext = () => {
    return useContext(SearchDataContext);
};
