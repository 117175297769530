const emotionText = {
  positive: '긍정글',
  negative: '부정글',
  neutral: '중립글',
};

interface EmotionTextProps {
  type: keyof typeof emotionText;
  style?: string;
}

export default function EmotionText({ type, style }: EmotionTextProps) {
  return <span className={`text-16 font-bold ${style} mobile:text-15`}>{emotionText[type]}</span>;
}
