import Sentiments from 'components/Data/Sentiments';

import { useBuzzAmountFilterContext } from 'contexts/buzzAmount/BuzzAmountContext';
import { useDatasContext } from 'contexts/buzzAmount/DatasContext';

import useGetData from 'hooks/BuzzTotalData/useGetData';
import { convertMonth } from 'hooks/useConvertDate';
import useConvertDateWithoutHours from 'hooks/useConvertDateWithoutHours';

const TotalDataContainer = () => {
  const { positive, neutral, negative, setTotalData, setPositive, setNeutral, setNegative } =
    useDatasContext();
  const { date, page, setParsedDatas, setTotalElements } = useBuzzAmountFilterContext();

  const startDate = date && useConvertDateWithoutHours({ start: date[0], end: date[1] }).startDate;
  const endDate = date && useConvertDateWithoutHours({ start: date[0], end: date[1] }).endDate;
  const TODAY = `${String(new Date()).split(' ')[3]}-${convertMonth(
    String(new Date()).split(' ')[1] || '',
  )}-${String(new Date()).split(' ')[2]}`;

  const filteredSentiment = (sentiment: string) => {
    const url = `/movie-buzz-total-data?pageSize=10&page=${page}&startDate=${
      startDate || '2000-01-01'
    }&endDate=${endDate || TODAY}&sentiment=${sentiment}`;

    useGetData({
      url,
      page,
      setParsedDatas,
      setTotalElements,
      setTotalData,
      setPositive,
      setNeutral,
      setNegative,
    });
  };

  return (
    <div className="flex justify-between w-full mb-20 gap-[30px] mobile:flex-col mobile:gap-12">
      <div className="flex w-1/3 py-16 bg-white px-30 shadow-box rounded-14 mobile:w-full mobile:mt-30">
        <Sentiments
          type="positive"
          counts={positive}
          handleClick={() => {
            filteredSentiment('POSITIVE');
          }}
        />
      </div>
      <div className="flex w-1/3 py-16 bg-white px-30 shadow-box rounded-14 mobile:w-full">
        <Sentiments
          type="neutral"
          counts={neutral}
          handleClick={() => {
            filteredSentiment('NEUTRAL');
          }}
        />
      </div>
      <div className="flex w-1/3 py-16 bg-white px-30 shadow-box rounded-14 mobile:w-full">
        <Sentiments
          type="negative"
          counts={negative}
          handleClick={() => {
            filteredSentiment('NEGATIVE');
          }}
        />
      </div>
    </div>
  );
};

export default TotalDataContainer;
