import { useEffect, useState } from 'react';

import { reformDataForChart } from 'utilities/Medium/Naver/reformDataForChart';
import { reformDataForGraph } from 'utilities/Medium/Naver/reformDataForGraph';
import { decodeKor } from 'utilities/Medium/decodeKor';

import { MediaCount } from 'components/Medium/MediaCount';
import MediaInfoBox from 'components/Medium/MediaInfoBox';
import GraphContainer from 'components/Medium/Naver/GraphContainer';
import NaverChart from 'components/Medium/Naver/NaverChart';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import useGetApi from 'hooks/apis/useGetApi';

import { NaverCount } from 'types/Medium/naver';

import { ReactComponent as Hearts } from 'assets/svg/Hearts.svg';
import { ReactComponent as NaverSVG } from 'assets/svg/Naver.svg';
import { ReactComponent as Stars1 } from 'assets/svg/Stars1.svg';
import { ReactComponent as Stars2 } from 'assets/svg/Stars2.svg';
import { ReactComponent as MediumIcon } from 'assets/svg/TVIcon.svg';

const Naver = () => {
  const [count, setCount] = useState<NaverCount>();
  const movie = decodeKor();
  const { refetch, data } = useGetApi('naver', `/movie-medium-analysis/naver/${movie}`);
  const mediaData = data && data.totalCount;
  const dateNaverList = data && data.dateNaverList;
  const chartData = reformDataForChart(dateNaverList);
  const graphData = reformDataForGraph(dateNaverList);

  useEffect(() => {
    refetch();

    if (data && dateNaverList) {
      setCount({
        watchedScore: dateNaverList[0] ? dateNaverList[0].watchedScore.toLocaleString() : '-',
        netizenScore: dateNaverList[0] ? dateNaverList[0].netizenScore.toLocaleString() : '-',
        likeCount: dateNaverList[0] ? dateNaverList[0].likeCount.toLocaleString() : '-',
      });
    }
  }, [movie, data]);

  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="영화 매체 분석"
          path2="CGV"
          Icon={MediumIcon}
          title="영화 매체 분석"
        />
      </div>
      <section className="flex flex-col gap-50">
        <div className="flex gap-14 mobile:flex-col">
          <MediaInfoBox Icon={NaverSVG} content="네이버" isTodayData="no" mediaData={mediaData} />
          <div className="flex items-center w-full max-w-[460px] h-[166px] bg-white rounded-14 shadow-medium">
            <MediaCount content="실관람객 평점" value={count?.watchedScore!} Icon={Stars1} />
            <MediaCount content="네티즌 평점" value={count?.netizenScore!} Icon={Stars2} />
            <MediaCount content="좋아요" value={count?.likeCount!} Icon={Hearts} />
          </div>
        </div>
        <div className="flex flex-col gap-20 w-full h-[400px] bg-white rounded-14 shadow-medium p-24">
          <p className="text-15 text-[#222222] font-semibold leading-18">시간별 접속자 수</p>
          <NaverChart data={chartData} />
        </div>
        <GraphContainer data={graphData} />
      </section>
    </>
  );
};

export default Naver;
