import { useEffect, useRef, useState } from 'react';

import ArrowIcon from 'assets/svg/SelectArrow.svg';

interface SelectBoxProps {
  title: string;
  options: string[];
  handleOptionChange?: (option: string) => void;
  value?: string | undefined;
  isSearch?: boolean;
  readonly?: boolean;
  label?: string | undefined;
}

export const SelectBox = ({
  title,
  options,
  handleOptionChange,
  value,
  isSearch = false,
  readonly,
  label,
}: SelectBoxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filterdOption, setfilterdOption] = useState(options);

  const toggleOpen = () => !readonly && setIsOpen((prev) => !prev);

  const selectOption = (option: string) => {
    setIsOpen(false);
    handleOptionChange && handleOptionChange(option);
  };

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setfilterdOption(options);
  }, [options]);

  useEffect(() => {
    if (isSearch) {
      const filter = options.filter((option) => option.includes(searchKeyword));
      setfilterdOption(filter);
    }
  }, [searchKeyword]);

  return (
    <div className="flex">
      {label && (
        <label className="flex items-center text-14 w-90 text-[#6D7683] font-bold">{label}</label>
      )}
      <div ref={ref} className="relative cursor-pointer w-[350px] text-16 mobile:w-[228px]">
        <div
          onClick={toggleOpen}
          className={`flex items-center justify-between px-12 align-middle bg-white h-[38px] border-light-gray2 z-100 ${
            isOpen && options.length !== 0
              ? 'border-t-1 border-x-1 rounded-t-4'
              : 'border-1 rounded-4'
          } ${value ? '' : 'text-[#A4ADBB] text-14 font-normal'}`}
        >
          <span>{value || title}</span>
          <img src={ArrowIcon} className={`${isOpen ? 'rotate-180' : ''}`} alt="" />
        </div>
        {isOpen && (
          <div
            className={`absolute z-10 w-full bg-white rounded-b-4 border-light-gray2 ${
              options.length !== 0 && 'border-x-1 border-b-1'
            }`}
          >
            {isSearch && options.length !== 0 && (
              <input
                className="w-full flex items-center pl-12 h-44"
                type="text"
                onChange={(e) => setSearchKeyword(e.currentTarget.value)}
                autoFocus
              />
            )}
            {filterdOption.map((option, index) => (
              <div
                key={index}
                onClick={() => selectOption(option)}
                className="flex items-center pl-12 h-[38px] hover:bg-light-gray"
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
