import { CompareTableProps } from "types/Comparison/ChartData";
import { MovieComparisonData } from "types/Comparison/Comparison";
import dateFormatter from "./DateFormatter";
import { MovieDateProps } from "types/Comparison/MovieDateProps";

interface Props {
    data1: MovieComparisonData, 
    data2: MovieComparisonData,
    movieDate1: MovieDateProps,
    movieDate2: MovieDateProps,
    option : "openDate" | "useCalendar", 
    endDate : Date, 
    startDate : Date
}

const dataToCompareData = ({data1, data2, movieDate1, movieDate2, option, endDate, startDate} : Props, dataOption : "관객수" | "버즈량" ) => {
    let dataArray : CompareTableProps[] = [];
    
    const handleDay = (day : number) => {
        if(day === 0) {
            return "-DAY";
        }
        else if (day > 0) {
            return `+${day}`;
        }
        else {
            return `${day}`;
        }
    }

    const calcIncrease = (prev : number, next : number) => {
        let increase = 0;

        if (prev !== 0 && next !== 0 && prev && next) {
            increase = (next - prev) / prev * 100;
        }

        return increase;
    }

    const getDateDiff = (d1 : Date, d2 : Date) => {
        const date1 = new Date(d1);
        const date2 = new Date(d2);
        
        const diffDate = date1.getTime() - date2.getTime();
        
        return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
    }

    if (option === "openDate") {
        const openDate1 = new Date(movieDate1.openDate);
        const openDate2 = new Date(movieDate2.openDate);
        let daycount = 0;
        let nextBuf1 = 0;
        let nextBuf2 = 0;
        let maxBuf1 : any = 0;
        let maxBuf2 : any = 0;

        openDate1.setDate(openDate1.getDate() - 1);
        openDate2.setDate(openDate2.getDate() - 1);

        if (dataOption === "관객수") {
            for (let i = 0; i <= 28; i++) {
                const calcDate1 = new Date(openDate1.setDate(openDate1.getDate() + 1));
                const calcDate2 = new Date(openDate2.setDate(openDate2.getDate() + 1));

                const dataBuf1 = data1.dateWatchedCountsMap[dateFormatter(calcDate1)];
                const dataBuf2 = data2.dateWatchedCountsMap[dateFormatter(calcDate2)];

                if (maxBuf1 < dataBuf1) {
                    maxBuf1 = dataBuf1;
                }
                if (maxBuf2 < dataBuf2) {
                    maxBuf2 = dataBuf2;
                }

                if (daycount % 7 === 0) {
                    dataArray.unshift({
                        date: `D${handleDay(daycount)}`,
                        firstMovie: maxBuf1 ? maxBuf1 : 0,
                        secondMovie: maxBuf2 ? maxBuf2 : 0,
                        increaseRate1: calcIncrease(nextBuf1, maxBuf1),
                        increaseRate2: calcIncrease(nextBuf2, maxBuf2)
                    });

                    nextBuf1 = maxBuf1;
                    nextBuf2 = maxBuf2;

                    maxBuf1 = 0;
                    maxBuf2 = 0;
                }

                daycount += 1;
            }
        }
        else {
            let totalBuzz1 = 0;
            let totalBuzz2 = 0;

            for (let i = 0; i <= 28; i++) {
                const calcDate1 = new Date(openDate1.setDate(openDate1.getDate() + 1));
                const calcDate2 = new Date(openDate2.setDate(openDate2.getDate() + 1));

                totalBuzz1 += data1.dateBuzzCountsMap[dateFormatter(calcDate1)];
                totalBuzz2 += data2.dateBuzzCountsMap[dateFormatter(calcDate2)];

                if (daycount % 7 === 0) {
                    dataArray.unshift({
                        date: `D${handleDay(daycount)}`,
                        firstMovie: totalBuzz1 ? totalBuzz1?.toLocaleString() : "0",
                        secondMovie: totalBuzz2 ? totalBuzz2?.toLocaleString() : "0",
                        increaseRate1: calcIncrease(nextBuf1, totalBuzz1),
                        increaseRate2: calcIncrease(nextBuf2, totalBuzz2)
                    });

                    nextBuf1 = totalBuzz1;
                    nextBuf2 = totalBuzz2;

                    totalBuzz1 = 0;
                    totalBuzz2 = 0;
                }

                daycount += 1;
            }
        }
    }
    else {
        const searchEndDate = new Date(endDate);

        let nextBuf1 = 0;
        let nextBuf2 = 0;

        const diff = getDateDiff(endDate, startDate) + 1 < 9 ? getDateDiff(endDate, startDate) + 1 : 9;
        searchEndDate.setDate(searchEndDate.getDate() - diff);
        
        if (dataOption === "관객수") {
            for (let i = 0; i < diff; i++) {
                let calcDate = new Date(searchEndDate.setDate(searchEndDate.getDate() + 1));

                const dataBuf1 = data1.dateWatchedCountsMap[dateFormatter(calcDate)];
                const dataBuf2 = data2.dateWatchedCountsMap[dateFormatter(calcDate)];

                dataArray.unshift({
                    date: dateFormatter(calcDate),
                    firstMovie: data1.dateWatchedCountsMap[dateFormatter(calcDate)],
                    secondMovie: data2.dateWatchedCountsMap[dateFormatter(calcDate)],
                    increaseRate1: calcIncrease(nextBuf1, dataBuf1),
                    increaseRate2: calcIncrease(nextBuf2, dataBuf2)
                });

                nextBuf1 = dataBuf1;
                nextBuf2 = dataBuf2;
            }
        }
        else {
            let totalBuzz1 = 0;
            let totalBuzz2 = 0;

            for (let i = 0; i < diff; i++) {
                let calcDate = new Date(searchEndDate.setDate(searchEndDate.getDate() + 1));

                totalBuzz1 += data1.dateBuzzCountsMap[dateFormatter(calcDate)];
                totalBuzz2 += data2.dateBuzzCountsMap[dateFormatter(calcDate)];

                dataArray.unshift({
                    date: dateFormatter(calcDate),
                    firstMovie: totalBuzz1 ? totalBuzz1.toLocaleString() : "0",
                    secondMovie: totalBuzz2 ? totalBuzz2.toLocaleString() : "0",
                    increaseRate1: calcIncrease(nextBuf1, totalBuzz1),
                    increaseRate2: calcIncrease(nextBuf2, totalBuzz2)
                });

                nextBuf1 = totalBuzz1;
                nextBuf2 = totalBuzz2;
            }
        }
    }

    return dataArray;
}

export default dataToCompareData;