import { Link } from 'react-router-dom';

import { useRemoveTFromDate } from 'hooks/useRemoveTFromDate';

import { ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType;
}

const Normal = ({ data }: Prop) => {
  const { title, content, isDate } = data;

  return (
    <>
      <div className="flex flex-col gap-12 mt-18">
        <h6 className="text-14 text-dark-gray">{title}</h6>

        {title !== '게시글 URL' && (
          <div
            className={`overflow-hidden flex items-center w-full h-42 bg-light-gray  rounded-12 pl-12 mobile:text-14`}
          >
            {title !== '게시글 URL' && isDate === 'not' && <>{content}</>}
            {isDate === 'date' && useRemoveTFromDate(content)}
          </div>
        )}

        {title === '게시글 URL' && (
          <Link
            className="w-full px-12 py-12 underline break-all outline-none h-fit bg-light-gray rounded-12 text-total"
            to={content || ''}
            target="_blank"
          >
            {content}
          </Link>
        )}
      </div>
    </>
  );
};

export default Normal;
