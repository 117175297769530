import { PropsWithChildren, createContext, useContext, useState } from 'react';

import { DateRange } from 'rsuite/esm/DateRangePicker';

import { BuzzAmountContext, GetDatas } from 'types/Buzz/BuzzType';

const FilterContext = createContext<BuzzAmountContext>({
  page: 1,
  setPage: () => {
    return;
  },
  totalElements: 0,
  setTotalElements: () => {
    return;
  },
  cp: '',
  setCp: () => {
    return;
  },
  movie: '',
  setMovie: () => {
    return;
  },
  keyword: '',
  setKeyword: () => {
    return;
  },
  platform: '',
  setPlatform: () => {
    return;
  },
  osp: '',
  setOsp: () => {
    return;
  },
  sentiment: '',
  setSentiment: () => {
    return;
  },
  date: [new Date(), new Date()],
  setDate: () => {
    return;
  },
  parsedDatas: [
    {
      num: '',
      ospType: '',
      movieTitle: '',
      keyword: '',
      contentTitle: '',
      sentimentType: '',
      createdAt: '',
    },
  ],
  setParsedDatas: () => {
    return;
  },
});

export function BuzzAmountFilterProvider({ children }: PropsWithChildren) {
  const urlSearch = new URLSearchParams(location.search);
  const URLCp = urlSearch.get('cp');
  const URLMovie = urlSearch.get('movie');
  const currentCp = URLCp ? URLCp : '';
  const currentMovie = URLMovie ? URLMovie : '';

  const [page, setPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [cp, setCp] = useState<string>(currentCp);
  const [movie, setMovie] = useState<string>(currentMovie);
  const [keyword, setKeyword] = useState<string>();
  const [platform, setPlatform] = useState<string>();
  const [osp, setOsp] = useState<string>();
  const [sentiment, setSentiment] = useState<string>();
  const [date, setDate] = useState<DateRange | null>();
  const [parsedDatas, setParsedDatas] = useState<GetDatas[]>([]);

  const value = {
    page,
    setPage,
    totalElements,
    setTotalElements,
    cp,
    setCp,
    movie,
    setMovie,
    keyword,
    setKeyword,
    platform,
    setPlatform,
    osp,
    setOsp,
    sentiment,
    setSentiment,
    date,
    setDate,
    parsedDatas,
    setParsedDatas,
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
}

export function useBuzzAmountFilterContext() {
  return useContext(FilterContext);
}
