import moment from 'moment';
import CustomProvider from 'rsuite/CustomProvider';
import DateRangePicker, { DateRange, RangeType } from 'rsuite/DateRangePicker';
import koKR from 'rsuite/locales/ko_KR';

import './Calender.less';

const today = moment();
const yesterday = moment().subtract(1, 'days');
const week = moment().subtract(1, 'weeks');
const monthAgo = moment().subtract(1, 'months');

export interface CalenderProps {
  handleChange?: (value: DateRange | null) => void;
  dateRange?: { startDate: string; endDate: string } | undefined;
  large?: boolean;
  disabled?: boolean;
  label?: boolean;
}

const locale = {
  DateRangePicker: {
    ...koKR.DateRangePicker,
    ok: '확인',
  },
};

const predefinedRanges: RangeType[] = [
  {
    label: '어제',
    value: [yesterday.toDate(), yesterday.toDate()],
  },
  {
    label: '지난 7일',
    value: [week.toDate(), today.toDate()],
  },
  {
    label: '최근 1달',
    value: [monthAgo.toDate(), today.toDate()],
  },
];

const settings = {
  character: ' - ',
  format: 'yyyy.MM.dd',
  placeholder: `${monthAgo.format('yyyy-MM-DD')} ~ ${today.format('yyyy-MM-DD')}`,
  ranges: predefinedRanges,
  block: true,
};

export const Calender = ({ handleChange, dateRange, disabled, label = false }: CalenderProps) => {
  const disabledDate = (date: Date) => {
    if (dateRange) {
      const today = new Date();
      const disabledStartDate = new Date(dateRange.startDate);
      let disabledEndDate = new Date(dateRange.endDate);

      if (disabledEndDate > today) disabledEndDate = today;

      return date < disabledStartDate || date > disabledEndDate;
    }

    return false;
  };

  return (
    <CustomProvider locale={locale}>
      <div className="flex items-center w-full">
        <div className="w-[350px] mobile:w-[228px] mobile:ml-5">
          {handleChange ? (
            <DateRangePicker
              {...settings}
              shouldDisableDate={disabledDate}
              disabled={disabled ?? false}
              onChange={handleChange}
            />
          ) : (
            <DateRangePicker
              {...settings}
              shouldDisableDate={disabledDate}
              disabled={disabled ?? false}
            />
          )}
        </div>
      </div>
    </CustomProvider>
  );
};
