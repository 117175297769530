import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { ReactComponent as SubscriptionIcon } from 'assets/svg/TicketIcon.svg';
import { ReactComponent as FreeStar } from "../../assets/svg/freeStar.svg"
import { ReactComponent as OneMonthStar } from '../../assets/svg/oneMonthStar.svg';
import { ReactComponent as OneYearStar } from '../../assets/svg/oneYearStar.svg';
import useGetApi from 'hooks/axiosApis/useGetApi';
import { SVGProps, useEffect, useRef, useState } from 'react';
import { Paging } from 'components/common/Table/Paging';
import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';
import mockData from "../../components/Payment/data/tableMockData.json";
import PaymentModal from 'components/Payment/modal/PaymentModal';
import useScrollLock from 'utilities/Subscription/useScrollLock';
import StatusTag from 'components/Payment/StatusTag';
import CancelCell from 'components/Payment/CancelCell';

interface PaymentTableProps {
  [key: string]: string;
}

const Payment = () => {
  const [subscription, setSubscription] = useState<string>("무료버전");
  const [description, setDescription] = useState<string>("내 영화 5편");
  const [svg, setSvg] = useState<SVGProps<SVGSVGElement>>(<FreeStar className='mb-10'/>);
  const [page, setPage] = useState<number>(1);
  const [datas, setDatas] = useState<PaymentTableProps[]>([{
    payDate: "",
    planName: "",
    subDate:"",
    price: "",
    status: ""
  }]);

  const payModalRef = useRef<HTMLDialogElement>(null);

  const {lockScroll, releaseScroll} = useScrollLock();

  const handleSubscription = () => {
    return useGetApi("/member/subscriptions").then((plan) =>  {
      switch (plan) {
        case '무료':
          setSubscription("무료버전");
          setDescription("");
          setSvg(<FreeStar className='mb-10'/>);
          break;
  
        case '1개월':
          setSubscription("1개월 구독");
          setDescription("내 영화 5편");
          setSvg(<OneMonthStar className='mb-10'/>);
          break;
  
        case '1년':
          setSubscription("1년 구독");
          setDescription("내 영화 10편");
          setSvg(<OneYearStar className='mb-10'/>);
          break;
  
        default:
          setSubscription("무료버전");
          setDescription("");
          setSvg(<FreeStar className='mb-10'/>);
      }
    })
  }

  const columns: LabeledColumnProps[] = [
    {
      key: 'payDate',
      label: '결제일시',
      flexGrow: 1,
      isCustomCell: true
    },
    {
      key: 'planName',
      label: '상품명',
      flexGrow: 1.2,
      isCustomCell: true
    },
    {
      key: 'subDate',
      label: '구독기간',
      flexGrow: 1,
      isCustomCell: true
    },
    {
      key: 'price',
      label: '금액',
      flexGrow: 0.8,
      isCustomCell: true
    },
    {
      key: 'status',
      label: '상태',
      flexGrow: 0.8,
      isCustomCell: true
    }
  ];

  useEffect(() => {
    handleSubscription().then(() => {
      return mockData.map((item) => {
        return {
          payDate: item.payDate,
          planName: item.planName,
          subDate: (!item?.startDate || !item?.endDate) ? '-' : `${item.startDate} ~ ${item.endDate}`,
          price: item.price,
          status: item.status
        }
      })
    }).then((datas) => {
      setDatas(datas);
    })
  }, []);

  return (
    <>
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="정기구독 관리"
          path2="결제 관리"
          Icon={SubscriptionIcon}
          title="결제 관리"
        />
      </div>

      <div className="flex flex-col gap-50">
        <div className='flex justify-between w-full px-40 py-30 box-border border-1 border-solid border-[#EAEAEA] rounded-14 bg-[#fff] shadow-lg mobile:flex-col mobile:gap-20'>
          <div className='flex flex-col'>
            <>{svg}</>
            <span className='flex items-center mb-27 gap-6 text-[#222] text-17 font-semibold'>
              {subscription}
              {subscription !== "무료버전" &&
                <span className='px-10 py-4 box-border rounded-46 text-[#FF9016] text-13 font-medium bg-[rgba(255,144,22,0.10)]'>
                  {description}
                </span>
              }
            </span>
            <span className='text-15 font-normal'>
              2024년 10월 16일 - 2024년 11월 16일
            </span>
          </div>

          <div className='flex items-end'>
            <button
              className='w-150 h-46 p-14 rounded-4 bg-[#FF9016] text-[#FFF] text-15 font-bold mobile:w-full'
              onClick={() => {
                payModalRef?.current?.showModal();
                lockScroll();
              }}
            >
                구독하기
            </button>
          </div>
        </div>

        <div className='flex flex-col'>
            <span className='mb-14 text-20 font-bold'>결제 내역</span>

            <div className='mb-20'>
              <DataTable
                datas={datas}
                columns={columns}
                rowKey={"status"}
              >
                <CancelCell key="payDate"/>
                <CancelCell key="planName"/>
                <CancelCell key="subDate"/>
                <CancelCell key="price"/>
                <StatusTag key="status"/>
              </DataTable>
            </div>

            <Paging
              total={1}
              limit={1}
              activePage={1}
              onChangePage={setPage}
            />
        </div>
      </div>
      <PaymentModal 
        close={() => {
          payModalRef?.current?.close();
          releaseScroll();
        }}
        ref={payModalRef}
      />
    </>
  );
};

export default Payment;
