import { ColumnProps } from "types/Comparison/ChartData";

interface Props {
    columns? : ColumnProps[],
    data? : any[],
    showPercent? : boolean
}

const ComparisonTable = ( { columns, data } : Props ) => {
    const rowWidth : number = columns ? 100.0 / columns?.length! : 33.3;

    return (
        <div className="flex justify-center items-center">
            {  data?.length !== 0 &&
            <table className="w-full max-w-[640px] border-t-1 border-solid border-[#EAEAEA]">
                <thead className="h-46 bg-[#F5F6FA] text-15 font-bold text-center">
                    <tr className="h-46 [&>th]:h-46">
                        {columns?.map((item, index) => {
                            return (
                                <th key={index}>
                                    {item?.label}
                                </th>
                            )
                        })}
                    </tr>                 
                </thead>
                    <tbody className="text-[#222] text-15 text-center">
                        {
                            data?.map((dataItem, index) => {

                                return (
                                    <tr className="h-46 border-t-1 border-solid border-[#EAEAEA]" key={index}>
                                        {
                                            columns?.map((columnItem, index) => {
                                                const showIncrease = columnItem?.showPercent ? columnItem.showPercent : false;
                                                return (
                                                    <td width={`${rowWidth}%`} key={index}>
                                                        {dataItem[columnItem?.key!] ? dataItem[columnItem?.key!].toLocaleString() : 0}

                                                        {(columnItem.key === "firstMovie") 
                                                        && showIncrease 
                                                        && dataItem?.increaseRate1 !== 0
                                                        && (dataItem?.increaseRate1 > 0 ? 
                                                        <span className="text-[#FF4E48]"> ({dataItem?.increaseRate1?.toFixed(1)}%↑)</span> : <span className="text-[#1D8AEE]"> ({dataItem?.increaseRate1?.toFixed(1)}%↓)</span>)}

                                                        {(columnItem.key === "secondMovie") 
                                                        && showIncrease 
                                                        && dataItem?.increaseRate2 !== 0
                                                        && (dataItem?.increaseRate2 > 0 ? 
                                                        <span className="text-[#FF4E48]"> ({dataItem?.increaseRate2?.toFixed(1)}%↑)</span> : <span className="text-[#1D8AEE]"> ({dataItem?.increaseRate2?.toFixed(1)}%↓)</span>)} 
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                
            </table>
            }
            {
                data?.length === 0 &&
                <span className="flex justify-center items-center w-full">
                    No Data Found
                </span>    
            }
        </div>
    )
}

export default ComparisonTable;