import { Dispatch, SetStateAction } from 'react';

import useGetApi from 'hooks/axiosApis/useGetApi';

interface Props {
  target: string;
  setState: Dispatch<SetStateAction<string[]>>;
}

const useKeywordDropdown = ({ target, setState }: Props) => {
  const URL = `/keyword/${target}`;
  const keywordMenu = useGetApi(URL).then((data) => setState(['필수 키워드'].concat(data)));

  return keywordMenu;
};

export default useKeywordDropdown;
