import { TotalAnalyticsList } from 'components/common/Report/TotalAnalyticsList';

import { TotalAnalyticsData } from 'types/Buzz/BuzzType';

interface TotalAnalyticsProps {
  datas: TotalAnalyticsData[];
}

export const TotalAnalytics = ({ datas }: TotalAnalyticsProps) => {
  return (
    <div className="flex flex-col gap-12 w-[515px] mt-8 mr-32 pr-32 border-r-light-gray border-r-1">
      {datas?.map((data, index) => (
        <div key={index}>
          <TotalAnalyticsList data={data} />
        </div>
      ))}
    </div>
  );
};
