import { Dispatch, SetStateAction } from 'react';

import useGetApi from 'hooks/axiosApis/useGetApi';

interface Prop {
  setState: Dispatch<SetStateAction<string>>;
}

export const useGetCp = ({ setState }: Prop) => {
  const URL = '/member/title';
  const cp = useGetApi(URL).then((data) => {
    console.log('Fetched data:', data);
    setState(data.length > 1 ? '유니온콘텐츠' : data[0]);
  });

  return cp;
};
