import { Dispatch, SetStateAction } from 'react'
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';

import {ReactComponent as PlanCheck} from "../../../assets/svg/PlanCheck.svg";
import {ReactComponent as PlanCheckActive} from "../../../assets/svg/PlanCheckActive.svg";

import { OptionLabelProps, PlanData } from 'types/Subscription/planData';

interface ModalPlanCard {
    data: PlanData,
    setSelected?: Dispatch<SetStateAction<string>>,
    selected?: string
}

const ModalPlanCard = ({ data, setSelected, selected } : ModalPlanCard) => {
    const permission = Object.values(data?.options);

    const optionLabel : OptionLabelProps[] = [
        {
            key : "Mailing",
            label : "메일링"
        }, 
        {
            key : "MovieZine",
            label : "무비진"
        },
        {
            key : "MovieTop10",
            label : "영화 Top 10 데이터"
        },
        {
            key : "AnalyzeBookmarkData",
            label : "즐겨찾기 10편 데이터 분석"
        }
    ];

    const handleHeaderColor = () => {
        switch(data?.planName) {
            case "무료 회원" :
                return " bg-[#454545]"

            case "1개월 구독" :
                return " bg-[linear-gradient(279deg,_#FB6714_9.27%,_#D68F41_95.19%)]"

            case "1년 구독" :
                return " bg-[linear-gradient(279deg,_#F31449_9.27%,_#FB6714_95.19%)]"

            default: 
                return " bg-[#454545]"
        }
    }

    return (
        <div className="flex flex-col w-[400px] h-[570px] box-border rounded-16 shadow-md overflow-hidden">
                <div className={"flex w-full h-1/5 gap-8 p-[50px_40px_30px] box-border" + handleHeaderColor()}>
                    <div className='flex items-center gap-8'>
                        <>{data?.svg}</>
                        <span className='text-white text-20 font-semibold'>{data?.planName}</span>
                        {   
                            data?.movieNum &&
                            <span className='flex justify-center items-center px-10 py-4 border-1 rounded-46 border-solid border-[#fff] text-[#fff] text-13 font-medium'>{"내 영화 " + data?.movieNum + "편"}</span>
                        }
                    </div>
                </div>
                <div className="flex flex-col items-center w-full h-[460px] gap-50 p-40 box-border">
                    <div className="flex w-full justify-between items-center">
                        <div className='flex h-full items-center gap-10'>
                            <span className='text-[#111] text-24 font-bold'>{data?.price}</span>
                            <span className='flex items-center h-full px-10 py-2 box-border rounded-46 bg-[#F2F2F2] text-[#414141] text-15 font-bold'>{data?.description}</span>
                        </div>
                        {data?.payEveryMonth && <span className='text-[#BCC1CA] text-15 font-medium'>매월결제</span>}
                    </div>
                <div className="w-full h-1 bg-[#DEE1E6]"/>
                <div className="flex flex-col w-full gap-20 [&>span]:text-[#6B7280] [&>span]:text-17 [&>span]:font-bold">
                    {permission.map((item, key) => {
                        return (
                                <div className="flex justify-between" key={key}>
                                    <span>{optionLabel[key]?.label}</span>
                                    {item ? <CheckRoundIcon/> : <WarningRoundIcon className="text-[#D9D9D9]"/>}
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                    className="flex justify-center items-center w-44 h-44 cursor-pointer" 
                    onClick={() => {
                        if (setSelected) {
                            setSelected(data?.planName);
                        }
                    }}
                >
                    {selected === data?.planName ? <PlanCheckActive/> : <PlanCheck/>}
                </div>
            </div>
        </div>
    )
}

export default ModalPlanCard