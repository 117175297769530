interface CellProps {
    text?: string;
    mode: "edit" | "delete",
    handleClick?: () =>  void
}
  
const CustomCellComponent = ({ text, mode, handleClick }: CellProps) => {
  const handleBackground = () => {
    return mode == "delete" ? " bg-[rgba(255,78,72,0.10)]" : " bg-[rgba(255,139,0,0.10)]";
  }

  const handleTextColor = () => {
    return mode == "delete" ? " text-[#FF4E48]" : " text-[#FF8B00]";
  }

  return (
    <button 
      className={"flex min-w-80 p-[6px_8px] justify-center items-center gap-[15px] flex-[1_0_0] rounded-[4px] h-[30px] w-[55px]" + handleBackground()}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      <p className={"text-center font-pretendard text-[15px] font-semibold leading-normal" + handleTextColor()}>
        {mode === "delete" ? "삭제" : "수정"}
      </p>
    </button>
  );
};

export default CustomCellComponent;