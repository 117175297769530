import { CompareChartData } from "types/Comparison/ChartData";
import { MovieComparisonData } from "types/Comparison/Comparison";
import { MovieDateProps } from "types/Comparison/MovieDateProps";

interface Props {
    movieDate1: MovieDateProps,
    movieDate2: MovieDateProps,
    movieData1: MovieComparisonData, 
    movieData2: MovieComparisonData
}

const dataToCompareChartData = ({movieDate1, movieDate2, movieData1, movieData2} : Props , option : "NEWS" | "SNS" | "커뮤니티" | "포털") => {

    let movieCompareChartData1 : CompareChartData = {
        movieName : "", 
        value: 0
    }

    let movieCompareChartData2 : CompareChartData = {
        movieName : "", 
        value: 0
    };

    if (movieData1?.platformBuzzCountsMap[option] && movieData2?.platformBuzzCountsMap[option]) {
        movieCompareChartData1.movieName = movieDate1.title;
        movieCompareChartData1.value = movieData1.platformBuzzCountsMap[option].totalCounts;

        movieCompareChartData2.movieName = movieDate2.title;
        movieCompareChartData2.value = movieData2.platformBuzzCountsMap[option].totalCounts;
    }

    return { movieCompareChartData1, movieCompareChartData2 };
}

export default dataToCompareChartData;