import CalendarInput from 'components/common/Modal/CalendarInput';
import Checkbox from 'components/common/Modal/Checkbox';
import Cp from 'components/common/Modal/Cp';
import Input from 'components/common/Modal/Input';
import Keyword from 'components/common/Modal/Keyword';
import Normal from 'components/common/Modal/Normal';
import Selectbox from 'components/common/Modal/Selectbox';
import Severals from 'components/common/Modal/Severals';
import Textarea from 'components/common/Modal/Textarea';

import { ModalListType } from 'types/Management/mangementType';

interface ModalOspDetailProps {
  data: ModalListType;
}

// 수집날짜 & 키워드 분류만 수정 가능
const ModalOspDetail = ({ data }: ModalOspDetailProps) => {
  const { type } = data;

  return (
    <>
      {data && <>{type === 'cp' && <Cp data={data} />}</>}
      {data && <>{type === 'keyword' && <Keyword data={data} />}</>}
      {data && <>{type === 'normal' && <Normal data={data} />}</>}
      {data && <>{type === 'textarea' && <Textarea data={data} />}</>}
      {data && <>{type === 'input' && <Input data={data} />} </>}
      {data && <>{type === 'several' && <Severals data={data} />} </>}
      {data && <>{type === 'checkbox' && <Checkbox data={data} />} </>}
      {data && <>{type === 'calendar' && <CalendarInput data={data} />} </>}
      {data && <>{type === 'selectbox' && <Selectbox data={data} />}</>}
    </>
  );
};

export default ModalOspDetail;
