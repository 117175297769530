import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import BuzzTableContainer from 'components/Data/container/BuzzTableContainer';
import ExcelContainer from 'components/Data/container/ExcelContainer';
import FilterContainer from 'components/Data/container/FilterContainer';
import TotalDataContainer from 'components/Data/container/TotalDataContainer';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import {
  BuzzAmountFilterProvider,
  useBuzzAmountFilterContext,
} from 'contexts/buzzAmount/BuzzAmountContext';
import { DatasProvider } from 'contexts/buzzAmount/DatasContext';

import { ReactComponent as DataIcon } from 'assets/svg/DataIcon.svg';

const Data = () => {
  const location = useLocation();
  const cardDatas = { ...location.state };

  const { setCp, setMovie } = useBuzzAmountFilterContext();

  useEffect(() => {
    if (cardDatas?.cp && cardDatas?.movie) {
      setCp(cardDatas?.cp);
      setMovie(cardDatas?.movie);
    }
  }, []);

  return (
    <section className="flex flex-col overflow-x-auto">
      <div className="hidden mobile:block">
        <MobilePageTitle path1="전체데이터 통계" Icon={DataIcon} title="전체데이터 통계" />
      </div>
      <BuzzAmountFilterProvider>
        <DatasProvider>
          <div className="w-full">
            <FilterContainer cardCp={cardDatas?.cp!} cardMovie={cardDatas?.movie!} />
          </div>
          <ExcelContainer />
          <TotalDataContainer />
          <BuzzTableContainer />
        </DatasProvider>
      </BuzzAmountFilterProvider>
    </section>
  );
};

export default Data;
