import { ModalListType } from 'types/Management/mangementType';

interface Prop {
  data: ModalListType;
}

const Textarea = ({ data }: Prop) => {
  const { title, content } = data;

  return (
    <>
      <div className="flex flex-col gap-12 mt-18">
        <h6 className="text-14 text-dark-gray">{title}</h6>
        <textarea
          readOnly
          defaultValue={content}
          className="outline-none w-full h-[216px] rounded-12 bg-light-gray p-12 leading-25 mobile:text-14 resize-none"
        ></textarea>
      </div>
    </>
  );
};

export default Textarea;
