import React, { MouseEventHandler } from 'react';

interface PillBtnProps {
  pillBtnProps: Components;
}
interface Components {
  shadow: ShadowColor;
  content: string;
  color: Color;
  border?: string;
  width?: string;
  height?: string;
  svg?: React.ReactNode;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
}

interface ShadowColor {
  isShadow: boolean;
  color: string;
}

interface Color {
  text: string;
  bg: string;
}

export default function PillBtn({ pillBtnProps }: PillBtnProps) {
  return (
    <>
      <button
        onClick={pillBtnProps.handleClick}
        className={`outline-none flex items-center justify-center overflow-hidden rounded-4 ${
          pillBtnProps.shadow.isShadow ? pillBtnProps.shadow.color : ''
        }  ${pillBtnProps.color.bg} ${pillBtnProps.border} ${pillBtnProps.height} ${
          pillBtnProps.width
        }`}
      >
        {pillBtnProps.svg && <div className="mr-10">{pillBtnProps.svg}</div>}
        <div className={`flex justify-center items-center text-15 ${pillBtnProps.color.text}`}>
          {pillBtnProps.content}
        </div>
      </button>
    </>
  );
}
