import TableContainer from 'components/Management/Platform/TableContainer';
import MobilePageTitle from 'components/common/Text/MobilePageTitle';

import { ReactComponent as ManagementIcon } from 'assets/svg/ManagementIcon.svg';
import { ReactComponent as TotalSearch } from 'assets/svg/totalSearch.svg';
import { ReactComponent as Reset } from 'assets/svg/Reset.svg';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from 'components/common/Filter/SelectBox';
import useGetApi from 'hooks/apis/useGetApi';

const Platform = () => {
  const [buttonVal, setButtonVal] = useState<string>("");
  const [platformVal, setPlatformVal] = useState<string>("");
  const [buttonBuf, setButtonBuf] = useState<string>("");
  const [platformBuf, setPlatformBuf] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const limit = 10;

  const buttonOptions = [
    { 
      value: '포털', 
      label: '포털' 
    },
    { 
      value: '커뮤니티',
      label: '커뮤니티' 
    },
    { 
      value: 'sns', 
      label: 'SNS' 
    },
    { 
      value: 'news', 
      label: 'NEWS' 
    }
  ];

  const { data, isLoading, refetch } = useGetApi(
    'platform-for-table-data',
    `/osp-list?${buttonVal !== "" ? "platform=" + buttonVal + "&" : ""}page=${currentPage}&pageSize=${limit}`,
  );


  const handleVal = (value : string) => {
    if (value === buttonBuf) {
      return " text-[#fff] bg-[#FF8B00]"
    }
    return " text-[#777] border-1 border-[#777]";
  }

  const handleReset = async () => {
    setButtonVal("");
    setPlatformVal("");
    setButtonBuf("");
    setPlatformBuf("");
    setCurrentPage(1);
  }

  const handleSearch = async () => {
    setButtonVal(buttonBuf);
    setPlatformVal(platformBuf);
    setCurrentPage(1);
  }

  useEffect(() => {
    refetch();
  }, [currentPage, data, buttonVal])

  return (
    <div className="flex flex-col gap-50">
      <div className="hidden mobile:block">
        <MobilePageTitle
          path1="관리센터"
          path2="플랫폼 현황"
          Icon={ManagementIcon}
          title="플랫폼 현황"
        />
      </div>

      <div className="flex flex-col w-full gap-16 p-30 box-border border-1 border-[#EAEAEA] rounded-14 bg-[#fff] shadow-lg">
        <div className='flex justify-start items-center gap-6 text-20 font-bold'>
          <TotalSearch/>
          검색
        </div>

        <div className='flex w-full items-center'>
          <div className='flex items-center gap-14 pr-25 border-r-1 border-[#EAEAEA] mr-30'>
            <span className='min-w-40 text-[#222] text-17 font-semibold'>구분</span>
            <div className='flex gap-10'>
              {
                buttonOptions.map((item, index) => {
                  return (
                    <button
                      className={'justify-center items-center min-w-[110px] py-9 box-border rounded-4 text-15 font-semibold' + handleVal(item?.value)}
                      key={index}
                      onClick={() => {
                        setButtonBuf(item?.value);
                      }}
                    >
                      {item.label}
                    </button>
                  )
                })
              }
            </div>
          </div>

          <div className='flex items-center gap-14 pr-40'>
            <span className='min-w-80 text-[#222] text-17 font-semibold'>플랫폼 검색</span>
            <SelectBox
              title={platformVal === "" ? "플랫폼 선택" : platformVal}
              options={["포털", "SNS", "커뮤니티", "NEWS"]}
              handleOptionChange={(target) => { 
                setPlatformBuf(target);
              }}
              value={platformVal}
            />
          </div>

          <div className='flex max-h-46 gap-10'>
              <button 
                className='flex min-w-150 justify-center items-center gap-4 p-14 box-border rounded-4 bg-[#222] text-[#fff] text-15 font-bold'
                onClick={async () => {
                  await handleReset().then(() =>
                    refetch()
                  )
                }}
              >
                <Reset/>
                초기화
              </button>

              <button 
                className='min-w-150 justify-center items-center p-14 box-border rounded-4 bg-[#FF9016] text-[#fff] text-15 font-bold'
                onClick={async () => {
                  await handleSearch().then(() => 
                    refetch()
                  )
                }}
              >
                검색
              </button>
          </div>
        </div>
      </div>
      <TableContainer onChangePage={setCurrentPage} currentPage={currentPage} data={data} isLoading={isLoading}/>
    </div>
  );
};

export default Platform;
