import {
  BuzzDataParams,
  BuzzReportDatas,
  MovieInfo,
  NewReportBody,
  ReportInputRef,
} from 'types/Buzz/BuzzType';

export const useGetNewReportBody = (
  reportInput: BuzzDataParams | undefined,
  inputRef: ReportInputRef,
  memo: React.MutableRefObject<HTMLTextAreaElement | null>,
  movieData: BuzzReportDatas | undefined,
  movieInfo: MovieInfo | undefined,
  timeStamp: number,
  cpId: string,
): NewReportBody => {
  return {
    reportType: 'buzz_report',
    reportTitle: inputRef.title.current ? inputRef.title.current.value : '',
    cpId: reportInput ? reportInput.cpId : '',
    reportManager: cpId,
    movieTitle: reportInput ? reportInput.movieTitle : '',
    dateRange: `${reportInput?.startDate} ~ ${reportInput?.endDate}`,
    reportPdfName: inputRef.title.current
      ? `${inputRef.title.current.value}_${timeStamp}.pdf`
      : 'buzzReport.pdf',
    reportMovieOtherInformation: inputRef.info.current ? inputRef.info.current.value : '',
    reportMemo: memo.current ? memo.current.value : '',
    reportProcessedData: JSON.stringify({ movieData, movieInfo }),
  };
};
