import { useEffect, useState } from 'react';

import PillBtn from 'components/common/Button/PillBtn';
import { FilterTitle } from 'components/common/Filter/FilterTitle';
import Filters from 'components/common/Filter/Filters';

import { useBuzzAmountFilterContext } from 'contexts/buzzAmount/BuzzAmountContext';
import { useDatasContext } from 'contexts/buzzAmount/DatasContext';

import useGetData from 'hooks/BuzzTotalData/useGetData';
import useCpDropdown from 'hooks/dropdown/useCpDropdown';
import useKeywordDropdown from 'hooks/dropdown/useKeywordDropdown';
import useMovieDropdown from 'hooks/dropdown/useMovieDropdown';
import useOspDropdown from 'hooks/dropdown/useOspDropdown';
import { convertMonth } from 'hooks/useConvertDate';
import useConvertDateWithoutHours from 'hooks/useConvertDateWithoutHours';
import useConvertSentiment from 'hooks/useConvertSentiment';

import { FilterList } from 'types/Management/mangementType';

import { ReactComponent as Reset } from 'assets/svg/Reset.svg';

interface Props {
  cardCp?: string;
  cardMovie?: string;
}

const FilterContainer = ({ cardCp, cardMovie }: Props) => {
  const [cpMenus, setCpMenus] = useState<string[]>([]);
  const [movieMenus, setMovieMenus] = useState<string[]>([]);
  const [keywordMenus, setKeywordMenus] = useState<string[]>([]);
  const [typeMenus, setTypeMenus] = useState<string[]>([]);
  const { page, cp, movie, keyword, platform, osp, sentiment, date } =
    useBuzzAmountFilterContext();
  const {
    setCp,
    setMovie,
    setKeyword,
    setPlatform,
    setOsp,
    setSentiment,
    setParsedDatas,
    setTotalElements,
    setDate,
  } = useBuzzAmountFilterContext();

  const { totalData, setTotalData, setPositive, setNeutral, setNegative } = useDatasContext();

  const filterList: FilterList = {
    1: [
      {
        selectTitle: '저작권사',
        type: 'several',
        several: [
          {
            severalType: 'select',
            placeholder: '선택',
            options: cpMenus,
            value: cp || '',
            label: 'CP사',
            setState: (target: string) => {
              if (target === 'CP사') {
                setCp('');
                setMovie('');
                setKeyword('');
                setMovieMenus([]);
                setKeywordMenus([]);
                return;
              }
              setCp(target);
              setMovie('');
              setKeyword('');
              setMovieMenus([]);
              setKeywordMenus([]);
              useMovieDropdown({ target: target, setState: setMovieMenus });
              console.log(cp);
            },
          },
          {
            severalType: 'select',
            placeholder: '선택',
            options: movieMenus,
            value: movie || '',
            label: '영화 제목',
            setState: (target: string) => {
              if (target === '영화 제목') {
                setMovie('');
                setKeyword('');
                setKeywordMenus([]);
                return;
              }
              setMovie(target);
              setKeyword('');
              setKeywordMenus([]);
              useKeywordDropdown({ target: target, setState: setKeywordMenus });
            },
          },
          {
            severalType: 'select',
            placeholder: '선택',
            label: '필수 키워드',
            options: keywordMenus,
            value: keyword || '',
            setState: (target: string) => {
              if (target === '필수 키워드') {
                setKeyword('');
                return;
              }
              setKeyword(target);
            },
          },
        ],
      },
    ],
    2: [
      {
        selectTitle: '플랫폼',
        type: 'several',
        several: [
          {
            severalType: 'select',
            placeholder: '선택',
            options: ['플랫폼', '포털', 'SNS', '커뮤니티', 'NEWS'],
            value: platform || '',
            label: '플랫폼',
            setState: (target: string) => {
              if (target === '플랫폼') {
                setPlatform('');
                setOsp('');
                setTypeMenus([]);
                return;
              }
              setPlatform(target);
              setTypeMenus([]);
              useOspDropdown({ target: target, setState: setTypeMenus });
            },
          },
          {
            severalType: 'select',
            placeholder: '선택',
            options: typeMenus,
            value: osp || '',
            label: '종류',
            setState: (target: string) => {
              if (target === 'OSP선택') {
                setOsp('');
                return;
              }
              setOsp(target);
            },
          },
        ],
      },
    ],
    3: [
      {
        selectTitle: '필터링',
        type: 'several',
        label: '날짜선택',
        several: [
          {
            severalType: 'calendar',
            setState: setDate,
            label: '분류',
          },
          {
            severalType: 'select',
            placeholder: '선택',
            options: ['전체 데이터', '긍정글', '중립글', '부정글'],
            value: sentiment || '',
            label: '분류',
            setState: (target: string) => {
              if (target === '전체 데이터') {
                setSentiment();
                return;
              }
              useConvertSentiment({ target, setSentiment });
            },
          },
        ],
      },
    ],
  };

  const styles = {
    SECTION_WIDTH: ['w-30%', 'w-30%', 'w-30%'],
    FONTSIZE: 'text-18',
  };

  const startDate = date && useConvertDateWithoutHours({ start: date[0], end: date[1] }).startDate;
  const endDate = date && useConvertDateWithoutHours({ start: date[0], end: date[1] }).endDate;
  const TODAY = `${String(new Date()).split(' ')[3]}-${convertMonth(
    String(new Date()).split(' ')[1] || '',
  )}-${String(new Date()).split(' ')[2]}`;

  const url = `/movie-buzz-total-data?pageSize=10&page=${page}&${
    date ? `&startDate=${startDate}` : '&startDate=2000-01-01'
  }${date ? `&endDate=${endDate}` : `&endDate=${TODAY}`}${cp ? `&cp=${cp}` : ''}${
    movie ? `&movie=${movie}` : ''
  }${keyword ? `&keyword=${keyword}` : ''}${platform ? `&platform=${platform}` : ''}${
    osp ? `&osp=${osp}` : ''
  }${sentiment ? `&sentiment=${sentiment}` : ''}`;

  const searchBtn = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: 'drop-shadow-pink',
    },
    content: '검색',
    color: {
      text: 'text-white',
      bg: 'bg-[#FF9016]',
    },
    handleClick: () => {
      useGetData({
        url,
        page,
        setParsedDatas,
        setTotalElements,
        setTotalData,
        setPositive,
        setNeutral,
        setNegative,
      });
    },
  };

  const resetDataFetch = () => {
    const url = `/movie-buzz-total-data?pageSize=10&page=1&startDate=2000-01-01&endDate=${TODAY}`;
    useGetData({
      url,
      page: 1,
      setParsedDatas,
      setTotalElements,
      setTotalData,
      setPositive,
      setNeutral,
      setNegative,
    });
  };

  const resetFilters = () => {
    setCp('');
    setMovie('');
    setKeyword('');
    setPlatform('');
    setOsp('');
    setSentiment('');
    setDate([null, null]);
    setMovieMenus([]);
    setKeywordMenus([]);
    setTypeMenus([]);

    resetDataFetch();
  };

  const resetBtn = {
    width: 'w-150',
    height: 'h-46',
    shadow: {
      isShadow: false,
      color: 'drop-shadow-excel',
    },
    content: '초기화',
    color: {
      text: 'text-white',
      bg: 'bg-black',
    },
    svg: <Reset />,
    handleClick: () => {
      resetFilters();
    },
  };

  useEffect(() => {
    useCpDropdown({ setState: setCpMenus, withCompare: true });

    if (cardCp !== '' && cardMovie !== '') {
      setCp(cardCp);
      setMovie(cardMovie);
    }

    useGetData({
      url,
      page,
      setParsedDatas,
      setTotalElements,
      setTotalData,
      setPositive,
      setNeutral,
      setNegative,
    });
  }, [page, totalData]);

  return (
    <div className=" bg-white rounded-24 py-30 shadow-box min-w-[1600px] mobile:min-w-[343px]">
      <FilterTitle title="검색" />
      <div className="flex flex-col items-center justify-center">
        <Filters filterList={filterList} styles={styles} />
        <div className="flex items-center justify-center gap-10 mt-20 mobile:border-t-1 mobile:border-[#EAEAEA] mobile:pt-16 mobile:w-[303px]">
          <PillBtn pillBtnProps={resetBtn} />
          <PillBtn pillBtnProps={searchBtn} />
        </div>
      </div>
    </div>
  );
};

export default FilterContainer;
