import { CircleProgressChart } from 'components/Data/BuzzReport/CircleProgressChart';
import { FieldCountList } from 'components/Data/BuzzReport/FieldCountList';
import FieldImgAndTotal from 'components/common/Report/FieldImgAndTotal';

import { useParseCommentPercetage } from 'hooks/Buzz/useParseCommentPercetage';

import { FieldBuzzData } from 'types/Buzz/BuzzType';

interface FieldCountProps {
  data: FieldBuzzData;
}

export const FieldCount = ({ data: list }: FieldCountProps) => {
  const byField = {
    style: `h-100 w-100 ${list.background}`,
    src: list.src,
    totalValue: list.totalCount,
    title: list.title,
  };

  const { positive, neutral, negative } = useParseCommentPercetage(list);

  return (
    <div className="flex items-center justify-between w-[1005px]">
      <div className="flex items-center">
        <FieldImgAndTotal byField={byField} />
      </div>

      <div className="flex flex-col gap-12">
        {list.datas?.map((data, index) => (
          <div key={index}>
            <FieldCountList
              name={data[0] as string}
              count={(data[1] as number) || 0}
              percentage={(data[2] as string) || '0'}
            />
          </div>
        ))}
      </div>
      <div className="flex gap-24">
        <CircleProgressChart
          data={[{ id: '긍정', data: [{ x: '', y: positive }] }]}
          color="#25BB3D"
        />
        <CircleProgressChart
          data={[{ id: '중립', data: [{ x: '', y: neutral }] }]}
          color="#FFB21D"
        />
        <CircleProgressChart
          data={[{ id: '부정', data: [{ x: '', y: negative }] }]}
          color="#FF464B"
        />
      </div>
    </div>
  );
};
