import { useState } from 'react';

import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import Header from 'components/common/Layout/Header/Header';
import Navbar from 'components/common/Layout/Navbar/Navbar';
import Login from 'components/common/Login/Login';

import { getCookie } from 'hooks/cookie';
import SignIn from 'pages/SignUp/SignUp';

export default function Root() {
  const loginToken = getCookie('loginToken');
  const name = getCookie('name');
  const profileImage = getCookie('profileImage');
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(true);

  return (
    <div>
      <div>
        {!loginToken && isLogin &&
          <Login setForm={setIsLogin}/>
        }
        {!loginToken && !isLogin &&
          <SignIn setForm={setIsLogin}/>
        }
        {loginToken && (
          <div className="flex w-full">
            <Navbar
              name={name}
              profileImage={profileImage}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
            />
            <div
              className="ml-[260px] w-full min-w-[calc(100%_-_260px)] bg-[#F5F6FA] mobile:w-full mobile:ml-0"
              id="test"
            >
              <Header name={name} profileImage={profileImage} setOpenMenu={setOpenMenu} />
              <div className="min-h-[calc(100vh_-_74px)] p-30 mobile:p-16">
                <Outlet></Outlet>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
