import { useEffect, useState } from 'react';

import { DataTable, LabeledColumnProps } from 'components/common/Table/DataTable';
import { Paging } from 'components/common/Table/Paging';

import { useTotalAmountFilterContext } from 'contexts/Management/TotalAmountContext';

import { parseTotalData } from 'hooks/Management/parseTotalData';
import useGetApi from 'hooks/apis/useGetApi';

import CustomCellComponent from '../CustomCellComponent';
import CustomDeleteCell from '../CustomDeleteCell';
import FilterContainer from './FilterContainer';

const TableContainer = () => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const { cp, movie, keyword, platform, osp, sentiment, date, page, setPage } =
    useTotalAmountFilterContext();
  const { data, refetch } = useGetApi(
    'moviebuzztotaldata',
    `/movie-buzz-total-data?pageSize=10&page=${page}${cp ? `&cp=${cp}` : ''}${
      movie ? `&movie=${movie}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}${platform ? `&platform=${platform}` : ''}${
      osp ? `&osp=${osp}` : ''
    }${sentiment ? `&sentiment=${sentiment}` : ''}`,
  );

  const columns: LabeledColumnProps[] = [
    { key: 'no', label: 'no.', flexGrow: 1 },
    { key: 'platform', label: '플랫폼', flexGrow: 1 },
    { key: 'keyword', label: '검색어', flexGrow: 1.5 },
    { key: 'title', label: '제목', flexGrow: 2.5 },
    { key: 'content', label: '내용', flexGrow: 2.5 },
    { key: 'type', label: '분류', flexGrow: 1, isCustomCell: true },
    { key: 'date', label: '날짜', flexGrow: 2 },
    { key: 'delete', label: '삭제', flexGrow: 1, isCustomCell: true },
  ];

  const parsedData = parseTotalData(data, page);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data) {
      setTotalCount(data.totalCount);
      setTotalElements(data.filteredList.totalElements);
    }
  }, [data]);

  return (
    <>
      <FilterContainer refetch={refetch} />
      <div className="flex flex-col gap-20 mt-50">
        <p className="text-[20px] text-[#222222] font-bold">
          전체데이터 <span className="text-[#797372]">{totalCount.toLocaleString()}건</span>
        </p>
        <div className="w-full min-w-[1600px]">
          <DataTable columns={columns} datas={parsedData} rowKey="no">
            <CustomCellComponent key="type" text="" />
            <CustomDeleteCell key="delete" text="" />
          </DataTable>
        </div>
        <Paging total={totalElements} limit={10} activePage={page} onChangePage={setPage} />
      </div>
    </>
  );
};

export default TableContainer;
