import { BarChart } from 'components/Data/BuzzReport/BarChart';

interface TotalChartProps {
  datas: TotalChartProp[] | [];
}

export interface TotalChartProp {
  [key: string]: number | string;
}

export const TotalChart = ({ datas }: TotalChartProps) => {
  return (
    <div className="w-[1501px] h-[438px]">
      <BarChart
        data={datas ? datas : []}
        keys={['포털', 'SNS', '커뮤니티', 'NEWS']}
        indexBy="date"
      />
    </div>
  );
};
