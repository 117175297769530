import { DateMediaList } from 'types/Medium/facebook';

export const reformDataForGraph = (dateFacebookList: DateMediaList[]) => {
  let newDataList: any = [];

  dateFacebookList?.map((facebook) => {
    newDataList.push({
      idx: facebook.idx,
      movie: facebook.movie,
      date: facebook.createdAt.replace('T', ' '),
      viewCount: facebook.viewCount.toLocaleString(),
      commentCount: facebook.commentCount.toLocaleString(),
      likeCount: facebook.likeCount.toLocaleString(),
    });
  });

  return newDataList;
};
